<template>
  <div class="owl-mobile-picker-wrap" >
    <div
      @click.stop="showDatePicker" 
      :class="['owl-npt', !_value && 'empty']" 
    >
      <span>{{_value || placeholder}}</span>
      <i class="el-icon-arrow-down arrow" :style="{
        'transform': active?'rotate(180deg)': 'rotate(0)'
      }"></i>
    </div>
    <div ref="date-wrap" :class="['owl-mobile-picker', active && 'active']" @click.stop="cancel">
      <div class="owl-picker-wrap" @click.stop>
        <div class="tit-wrap">
          <span class="cancel" @click="cancel">{{t('common.cancel')}}</span>
          <span class="tit">{{placeholder}}</span>
          <span class="confirm" @click="confirm">{{t('common.confirm')}}</span>
        </div>
        <div class="picker-wrap">
          <div class="top-cover"></div>
          <div class="bottom-cover"></div>
          <div 
            class="pickers"
            :style="{
              transform: `translate3d(0, ${-(yearTranslateY + yearScrollingY)}px, 0)`
            }"
            @mousedown="e=>scrollStart(e,'year')"
            @touchstart="e=>scrollStart(e,'year')"
            >
            <div v-for="item of years" :key="item" :class="['pcker', year == item && 'active']"
            >{{item}}</div>
          </div>
          <div
            class="pickers"
            :style="{
              transform: `translate3d(0, ${-(monthTranslateY + monthScrollingY)}px, 0)`
            }"
            @mousedown="e=>scrollStart(e,'month')"
            @touchstart="e=>scrollStart(e,'month')"
          >
            <div v-for="item of months" :key="item" :class="['pcker', month == item && 'active']">{{item}}</div>
          </div>
          <div 
            class="pickers"
            :style="{
              transform: `translate3d(0, ${-(dateTranslateY + dateScrollingY)}px, 0)`
            }"
            @mousedown="e=>scrollStart(e,'date')"
            @touchstart="e=>scrollStart(e,'date')"
          >
            <div v-for="item of dates" :key="item" :class="['pcker', date == item && 'active']">{{item}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import i18n from "@/locale/index";
export default {
  mixins: [i18n],
  model:{
    prop: "value",
    event: "change"
  },
  props:{
    placeholder: {
      type: String,
      default: 'Please select date'
    },
    type: {
      type: String,
      default: 'date'
    },
    size: {
      default: 'small'
    },
    value: {
      type: String,
    },
  },
  data(){
    return {
      years: (()=>{
        let min = 1980, max = (new Date()).getFullYear()+20;
        let arr = [];
        while(min <= max) {
          arr.push(min++)
        }
        return arr;
      })(),
      months: [1,2,3,4,5,6,7,8,9,10,11,12],
      dates: [],
      active: false,
      
      yearTranslateY: 0,
      monthTranslateY: 0,
      dateTranslateY: 0,

      yearScrollingY: 0,
      monthScrollingY: 0,
      dateScrollingY: 0,

      year: (new Date()).getFullYear(),
      month: (new Date()).getMonth()+1,
      date: (new Date()).getDate()
    }
  },
  computed:{
    _value:{
      get(){
        return this.value
      },
      set(val){
        this.$emit('change',val)
      }
    },
    isMobile(){
      return this.$isMobile();
    }
  },
  // watch:{
  //   active(val){
  //     document.body.style.overflow = val? 'hidden': ''
  //   }
  // },
  mounted(){
    document.body.appendChild(this.$refs['date-wrap'])
  },
  beforeDestroy(){
    document.body.removeChild(this.$refs['date-wrap'])
  },
  methods:{
    setDatesByParam(year, month){
      let lastDate = new Date()
      lastDate.setDate(1)
      lastDate.setHours(0,0,0,0)
      lastDate.setFullYear(month == 11 ? year + 1: year)
      lastDate.setMonth((month == 11? 0: month + 1)) // 1月 is 0
      lastDate.setTime(lastDate.getTime()-3600000)
      let date = lastDate.getDate()
      let dates = [];
      while(date > 0){
        dates.unshift(date)
        date--;
      }
      this.dates = dates;
      if(this.date > dates.length){
        this.date = dates.length;
        this.dateTranslateY = (this.date - 1) * 40 - 110;
      }
      return dates.length
    },
    showDatePicker(){
      let valueDate = this._value? new Date(this._value): new Date();
      this.year = valueDate.getFullYear();
      this.month = valueDate.getMonth()+1;
      this.date = valueDate.getDate();
      
      this.setDatesByParam(this.year, this.month-1);
      const yearIndex = this.years.findIndex(item => item == this.year);
      this.yearTranslateY = yearIndex * 40 - 110;
      this.monthTranslateY = (this.month - 1) * 40 - 110;
      this.dateTranslateY = (this.date - 1) * 40 - 110;

      this.$nextTick(()=>this.active = true)
    },
    scrollStart(e, type){
      const startY = e.pageY || (e.touches[0] && e.touches[0].pageY);
      let onScroll = (e) => {
        let scrollingY = e.pageY || (e.touches[0] && e.touches[0].pageY);
        if(type == 'year') {
          this.yearScrollingY = startY - scrollingY
        } else if(type == 'month'){
          this.monthScrollingY = startY - scrollingY
        } else {
          this.dateScrollingY = startY - scrollingY
        }
        
      }
      let onScrollStop = (e) => {
        if(type == 'year'){
          this.yearTranslateY += this.yearScrollingY;
          this.yearScrollingY = 0;
          this.scrollYToItem('year')
        } else if(type =='month') {
          this.monthTranslateY += this.monthScrollingY;
          this.monthScrollingY = 0;
          this.scrollYToItem('month')
        } else {
          this.dateTranslateY += this.dateScrollingY;
          this.dateScrollingY = 0;
          this.scrollYToItem('date')
        }
        document.body.removeEventListener(this.isMobile? 'touchmove': 'mousemove', onScroll)
        document.body.removeEventListener(this.isMobile? 'touchend': 'mouseup', onScrollStop)
        !this.isMobile && document.body.removeEventListener('mouseleave', onScrollStop)
        onScroll = null;
        onScrollStop = null;
      }
      document.body.addEventListener(this.isMobile? 'touchmove': 'mousemove', onScroll)
      document.body.addEventListener(this.isMobile? 'touchend' : 'mouseup', onScrollStop)
      !this.isMobile && document.body.addEventListener( 'mouseleave', onScrollStop)
    },
    async scrollYToItem(type){
      switch(type){
        case 'year': {
          let index = Math.round((this.yearTranslateY + 110) / 40)
          if(index > this.years.length - 1){
            index = this.years.length - 1;
          }
          if(index < 0) index = 0;
          const yTranslate = index * 40 - 110;
          await this.autoScrollYear(yTranslate, 'yearTranslateY')
          this.year = this.years[index];
          this.setDatesByParam(this.year, this.month-1)
          return;
        }
        case 'month': {
          let index = Math.round((this.monthTranslateY + 110) / 40)
          if(index > this.months.length - 1){
            index = this.months.length - 1;
          }
          if(index < 0) index = 0;
          const yTranslate = index * 40 - 110;
          await this.autoScrollYear(yTranslate, 'monthTranslateY')
          this.month = this.months[index];
          this.setDatesByParam(this.year, this.month-1)
          return;
        }
        case 'date': {
          let index = Math.round((this.dateTranslateY + 110) / 40)
          if(index > this.dates.length - 1){
            index = this.dates.length - 1;
          }
          if(index < 0) index = 0;
          const yTranslate = index * 40 - 110;
          await this.autoScrollYear(yTranslate, 'dateTranslateY')
          this.date = this.dates[index];
          return;
        }
      }
    },
    autoScrollYear(yTranslate, tranlateY){
      return new Promise((res)=>{
        let add = (yTranslate - this[tranlateY]) / 10;
        let type = yTranslate > this[tranlateY]? 'add': yTranslate < this[tranlateY]? 'less': 'equal';
        if(type == 'equal') {
          res()
          return;
        }
        let addAuto =  ()=>{
          if(type == 'add'){
            if((this[tranlateY] + add) < yTranslate ){
              this[tranlateY] += add
              requestAnimationFrame(addAuto)
            } else {
              this[tranlateY] = yTranslate;
              res()
              addAuto = null;
            }
          } else {
            if((this[tranlateY] + add) > yTranslate){
              this[tranlateY] += add
              requestAnimationFrame(addAuto)
            } else {
              this[tranlateY] = yTranslate;
              res()
              addAuto = null;
            }
          }
        }
        addAuto();
      })
    },
    cancel(){
      this.active = false;
    },
    confirm(){
      this._value = `${this.year}-${this.month<10?'0'+this.month:this.month}-${this.date<10?'0'+this.date:this.date}`
      this.active = false;
    }
  }
}
</script>
<style lang="scss" >
 
.owl-mobile-picker{
  .owl-picker-wrap{
    height: 300px;
  }
}
</style>