

export default {
  toolState: {
    completed: [], // 已完成
    UnCompleted: [], // 未启动 与 未完成
    uploadFiles: [],
  },
  toolMutations: {
    addCompleted(state, payload) {
      if (payload) {
        const index = state.completed.findIndex((item) => item === payload);
        if (index < 0) {
          state.completed.push(payload);
        }
      }
    },
    subCompleted(state, payload) {
      if (payload) {
        const index = state.completed.findIndex((item) => item === payload);
        if (index > -1) {
          state.completed.splice(index, 1);
        }
      }
    },
    addUncompleted(state, payload) {
      if (payload) {
        const index = state.UnCompleted.findIndex((item) => item === payload);
        if (index < 0) {
          state.UnCompleted.push(payload);
        }
      }
    },
    subUncompleted(state, payload) {
      if (payload) {
        const index = state.UnCompleted.findIndex((item) => item === payload);
        if (index > -1) {
          state.UnCompleted.splice(index, 1);
        }
      }
    },
    addUploadedFile(state, payload) {
      if (payload.type === 'init') {
        state.uploadFiles = payload.files
      } else {
        state.uploadFiles.push(...payload.files)
      }
    },
    deleteUploadedFile(state, payload) {
      const index = state.uploadFiles.findIndex((item) => item.fileGuid === payload)
      index > -1 && state.uploadFiles.splice(index, 1)
    },
    clearState(state) {
      state.completed = []
      state.UnCompleted = []
      state.uploadFiles = []
    }
  },
}

