<template>
  <div class="tip-content">
    <span v-if="tipsData.showType === 1 && showTips">
      <el-tooltip
        class="item"
        effect="light"
        placement="top"
        @click="handleHelpTip"
      >
        <span slot="content" v-html="tipsData.description"></span>
        <span class="help-tip">?</span>
      </el-tooltip>
    </span>
    <div v-else>
      <span class="help-tip" @click="handleHelpTip">?</span>
    </div>
  </div>
</template>

<script>
import i18n from "@/locale/index";
import { getHelpInfoApi } from "@/api/common";
export default {
  mixins: [i18n],
  props: {
    keyCode: {
      type: String,
    },
  },
  data() {
    return {
      showTips: false,
      tipsData: {},
      visible: true
    };
  },
  mounted() {
    this.getHelpInfo();
  },
  methods: {
    handleHelpTip() {
      this.$emit("click");
      if (this.tipsData.showType === 2) {
        this.$alrt(this.tipsData.description, this.tipsData.title, {
          dangerouslyUseHTMLString: true,
          confirmButtonText: this.t("common.confirm"),
        });
      }
    },
    async getHelpInfo() {
      try {
        const { data } = await getHelpInfoApi(this.keyCode);
        this.tipsData = data.data;
      } catch {}
    },
  },
};
</script>

<style lang="scss" scoped>
.tip-content {
  display: inline-block;
}
.help-tip {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: #00B696;
  background-color: var(--form-color-success);
  color: #fff !important;
  border-radius: 50%;
  line-height: 16px;
  text-align: center;
  padding: 0 !important;
  margin-left: 6px;
  cursor: pointer;
}
</style>