<template>
  <div class="export-form" style="padding: 0 12px;max-width:920px;margin: 0 auto; width: 100%; font-family: Arial;">
    <h1 style="text-align: center; font-family: Arial;font-size: 32px;margin: 32px 0;">Student Information Form</h1>
    <p style="line-height: 20px;margin-bottom:24px; font-size: 14px">To ensure the smooth processing of the application, please provide the following information and make sure that it is genuine and correct. We guarantee that the information will not be disclosed to any unrelated third party without your consent. Please provide as many details as possible. Thank you for your cooperation.</p>
    <table cellspacing="0">
      <tr>
        <td colspan="4" style="font-weight:bold; background: #e1e1e1; color: #ff9700;">Information of Student Recruitment Agency</td>
      </tr>
      <tr>
        <td style="background: #f1f1f1">★Agency Name</td>
        <td colspan="3"></td>
      </tr>
      <tr>
        <td style="background: #f1f1f1">★Counselor Name</td>
        <td colspan="3"></td>
      </tr>
      <tr>
        <td style="background: #f1f1f1">★Counselor's<br/> Email</td>
        <td colspan="3">{{studentInfoDto.followUpEmail1}}</td>
      </tr>
      <tr>
        <td style="background: #f1f1f1">Counselor's phone</td>
        <td colspan="3">{{studentInfoDto.followUpPhoneCode1? '+'+studentInfoDto.followUpPhoneCode1+ ' ' +studentInfoDto.followUpPhoneNumber1: ''}}</td>
      </tr>
      <tr>
        <td colspan="4" style="font-weight: bold; background: #e1e1e1; color: #ff9700;">Applicant's Personal Information</td>
      </tr>
      <tr>
        <td style="width:25%;background: #f1f1f1">★Name</td>
        <td style="width:25%;">{{studentInfoDto.nameZh||''}} / {{(studentInfoDto.firstName || '')+' '+(studentInfoDto.lastName || '')}}</td>
        <td style="width:25%;background: #f1f1f1">★Date of Birth</td>
        <td style="width:25%;">{{studentInfoDto.birthday? dateToForm(studentInfoDto.birthday): ''}}</td>
      </tr>
      <tr>
        <td style="width:25%;background: #f1f1f1">★Gender</td>
        <td style="width:25%;">{{studentInfoDto.gender==1? 'Male': 'Female'}}</td>
        <td style="width:25%;background: #f1f1f1">★Phone</td>
        <td style="width:25%;">{{studentInfoDto.phoneRegion? '+'+studentInfoDto.phoneRegion +' '+ studentInfoDto.phoneNumber: ''}}</td>
      </tr>
      <tr>
        <td style="width:25%;background: #f1f1f1">★Nationality</td>
        <td style="width:25%;">{{studentInfoDto.fkStudentAppCountryName}}</td>
        <td style="width:25%;background: #f1f1f1">★Email</td>
        <td style="width:25%;">{{studentInfoDto.email}}</td>
      </tr>
      <tr>
        <td style="width:25%;background: #f1f1f1">★Home Address &amp; Post Code</td>
        <td colspan="3">
          <span v-if="studentInfoDto.contactAddress1">{{studentInfoDto.contactAddress1}},</span>
          <span v-if="studentInfoDto.contactAddress2"> {{studentInfoDto.contactAddress2}},</span>
          <span v-if="studentInfoDto.fkAreaCityDivisionIdFromName">{{studentInfoDto.fkAreaCityDivisionIdFromName}},</span>
          <span v-if="studentInfoDto.fkAreaCityIdFrom&&(+studentInfoDto.fkStudentAppCountryIdFrom===228)">{{studentInfoDto.fkAreaCityIdFrom}},</span>
          <span v-else>{{studentInfoDto.fkAreaCityIdFromName}},</span>
          <span v-if="studentInfoDto.fkAreaStateIdFromName">{{studentInfoDto.fkAreaStateIdFromName}},</span>
          <span v-if="studentInfoDto.fkAreaRegionIdFromName">{{studentInfoDto.fkAreaRegionIdFromName}},</span>
          <span v-if="studentInfoDto.fkStudentAppCountryIdFromName">{{studentInfoDto.fkStudentAppCountryIdFromName}},</span>
          <span v-if="studentInfoDto.contactPostcode">{{`(${studentInfoDto.contactPostcode})`}}</span>
        </td>
      </tr>
      <tr><td colspan="4" style="font-weight:bold; background: #e1e1e1; color: #ff9700;">Parent's Information</td></tr>
      <tr>
        <td style="background: #f1f1f1">Father's Name</td>
        <td>{{studentParentsDto.fatherFirstName || ''}}{{studentParentsDto.fatherLastName || ''}}</td>
        <td style="background: #f1f1f1">Phone</td>
        <td>{{studentParentsDto.fatherPhoneNumber}}</td>
      </tr>
      <tr>
        <td style="background: #f1f1f1">Occupation</td>
        <td></td>
        <td style="background: #f1f1f1">Email</td>
        <td>{{studentParentsDto.fatherEmail}}</td>
      </tr>
      <tr>
        <td style="background: #f1f1f1">Workplace</td>
        <td colspan="3"></td>
      </tr>
      <tr>
        <td style="background: #f1f1f1">Mother's Name</td>
        <td>{{studentParentsDto.monterFirstName || ''}}{{studentParentsDto.monterLastName || ''}}</td>
        <td style="background: #f1f1f1">Phone</td>
        <td>{{studentParentsDto.monterPhoneNumber}}</td>
      </tr>
      <tr>
        <td style="background: #f1f1f1">Occupation</td>
        <td></td>
        <td style="background: #f1f1f1">Email</td>
        <td>{{studentParentsDto.monterEmail}}</td>
      </tr>
      <tr>
        <td style="background: #f1f1f1">Workplace</td>
        <td colspan="3"></td>
      </tr>
    </table>
    <table cellspacing="0">
      <tr>
        <td colspan="6" style="font-weight:bold; background: #e1e1e1; color: #ff9700;">Passport Information (Please provide a scanned copy of the applicant's passport if available to ensure that correct information is submitted for application)</td>
      </tr>
      <tr>
        <td style="width:16.6%; background: #f1f1f1">Passport<br/>Number</td>
        <td style="width:16.6%">{{studentInfoDto.passportNumber}}</td>
        <td style="width:16.6%; background: #f1f1f1">Date of<br/>Issue</td>
        <td style="width:16.6%">{{dateToForm(studentInfoDto.passportIssueDate)}}</td>
        <td style="width:16.6%; background: #f1f1f1">Date of<br/>Expiry</td>
        <td style="width:16.6%">{{dateToForm(studentInfoDto.passportIssueExpried)}}</td>
      </tr>
    </table>
    <table cellspacing="0">
      <tr>
        <td colspan="10" style="font-weight:bold; background: #e1e1e1; color: #ff9700;">English Language Proficiency</td>
      </tr>
      <template v-for="(item, index) in studentScoreDtos">
        <tr :key="index+'row1'">
          <td style="width:20%; background: #f1f1f1" colspan="2">{{item.examType}} / Test Date</td>
          <td style="width:20%" colspan="2">{{dateToForm(item.examinationDate)}}</td>
          <td style="width:10%; background: #f1f1f1">★ID</td>
          <td style="width:10%">{{item.examinationCheckAccount}}</td>
          <td style="width:10%; background: #f1f1f1">★Code</td>
          <td style="width:30%" colspan="3">{{item.testCenterNumber}}</td>
        </tr>
        <tr :key="index+'row2'">
          <td style="width:10%; background: #f1f1f1">Total Score</td>
          <td style="width:10%">{{item.examTotal}}</td>
          <td style="width:10%; background: #f1f1f1">Listening</td>
          <td style="width:10%">{{item.examListen}}</td>
          <td style="width:10%; background: #f1f1f1">Reading</td>
          <td style="width:10%">{{item.examRead}}</td>
          <td style="width:10%; background: #f1f1f1">Speaking</td>
          <td style="width:10%">{{item.examSpeak}}</td>
          <td style="width:10%; background: #f1f1f1">Writing</td>
          <td style="width:10%">{{item.examWrite}}</td>
        </tr>
      </template>
      <tr v-if="!studentScoreDtos || !studentScoreDtos.length">
        <td colspan="10" style="text-align:center;color: #999;">Empty Data</td>
      </tr>
    </table>
    <table cellspacing="0">
      <tr>
        <td colspan="4" style="font-weight:bold; background: #e1e1e1; color: #ff9700;">★Education History</td>
      </tr>
      <template v-for="(item, key) in studentEducationDtos">
        <tr :key="key+'row1'">
          <td style="width: 25%; background: #f1f1f1">From...To...</td>
          <td style="width: 25%">{{dateToForm(item.studyStartDate)}}-{{dateToForm(item.studyEndDate)}}</td>
          <td style="width: 25%; background: #f1f1f1">Completion Date</td>
          <td style="width: 25%">{{dateToForm(item.studyEndDate)}}</td>
        </tr>
        <tr :key="key+'row2'">
          <td style="background: #f1f1f1">Institution<br/> Name &amp; Address</td>
          <td colspan="3">{{item.fkInstitutionNameEn || item.fkInstitutionId}}<br/> &amp; <br/>{{item.fkAddress}}</td> 
        </tr>
        <tr :key="key+'row3'">
          <td style="background: #f1f1f1">Major</td>
          <td>{{item.major}}</td>
          <td style="background: #f1f1f1">Degree &amp; GPA</td>
          <td>{{item.fkMajorLevelName}} <br/>&amp;<br/> {{item.scoreValue}}/{{item.scoreName || ''}}</td>
        </tr>
      </template>
      <tr v-if="!studentEducationDtos || !studentEducationDtos.length">
        <td colspan="10" style="text-align:center;color: #999;">Empty Data</td>
      </tr>
    </table>
    <table cellspacing="0">
      <tr>
        <td colspan="2" style="font-weight:bold; background: #e1e1e1; color: #ff9700;">★Authenticity Check on Academic Qualification</td>
      </tr>
      <tr>
        <td style="width:25%; background: #f1f1f1">Contact Person of the Registry</td>
        <td style="width:75%"></td>
      </tr>
      <tr>
        <td style="background: #f1f1f1">Phone or Email</td>
        <td></td>
      </tr>
    </table>
    <table cellspacing="0">
      <tr>
        <td colspan="4" style="font-weight:bold; background: #e1e1e1; color: #ff9700;">Professional Experience (Start &amp; End Dates are required for application)</td>
      </tr>
      <template v-for="(item, key) in studentWorkDtos">
        <tr :key="key+'row1'">
          <td style="width:25%; background: #f1f1f1">From...To...</td>
          <td style="width:25%">{{dateToForm(item.workStartDate)}}-{{dateToForm(item.workEndDate)}}</td>
          <td style="width:25%; background: #f1f1f1">Workplace Phone</td>
          <td style="width:25%"></td>
        </tr>
        <tr :key="key+'row2'">
          <td style="background: #f1f1f1">Workplace &amp; Address</td>
          <td colspan="3">
            <span v-if="item.companyName">{{item.companyName}},</span>
            <span>{{item.fkAddress}}</span>
          </td>
        </tr>
        <tr :key="key+'row3'">
          <td style="background: #f1f1f1">Position</td>
          <td colspan="3">{{item.position}}</td>
        </tr>
      </template>
      <tr v-if="!studentWorkDtos || !studentWorkDtos.length">
        <td colspan="10" style="text-align:center;color: #999;">Empty Data</td>
      </tr>
    </table>
    <table cellspacing="0">
      <tr>
        <td colspan="12" style="font-weight:bold; background: #e1e1e1; color: #ff9700;">★Reference Information (if required by destination institution)</td>
      </tr>
      <template v-for="(item, key) in studentReferenceDtos">
        <tr :key="key+'row1'">
          <td style="width:16.6%; background: #f1f1f1" colspan="2">Reference's Name {{key+1}}</td>
          <td style="width:16.6%" colspan="2">{{item.firstName+' '+item.lastName}}</td>
          <td style="width:20.6%; background: #f1f1f1" colspan="2">Position</td>
          <td style="width:20%" colspan="2">{{item.position}}</td>
          <td style="width:10%; background: #f1f1f1" colspan="2">Title</td>
          <td colspan="2">{{item.titleName}}</td>
        </tr>
        <tr :key="key+'row2'">
          <td style="background: #f1f1f1" colspan="3">Email</td>
          <td colspan="3">{{item.email}}</td>
          <td style="background: #f1f1f1" colspan="3">Phone</td>
          <td colspan="3">{{item.phoneNumber}}</td>
        </tr>
        <tr :key="key+'row3'">
          <td style="background: #f1f1f1" colspan="3">Workplace</td>
          <td colspan="9">{{item.company}}</td>
        </tr>
        <tr :key="key+'row4'">
          <td style="background: #f1f1f1" colspan="3">Address &amp; Post Code</td>
          <td colspan="9">{{item.contactAddress1 + (item.contactAddress2 || '')+ ',' + item.fkAddress}}  <br/> &amp; <br/> {{item.contactPostcode}}</td>
        </tr>
        <tr :key="key+'row5'">
          <td style="background: #f1f1f1" colspan="4">How long have you known the applicant?</td>
          <td colspan="8"></td>
        </tr>
        <tr :key="key+'row6'">
          <td style="background: #f1f1f1" colspan="4">Relationship with the Applicant</td>
          <td colspan="8"></td>
        </tr>
      </template>
      <tr v-if="!studentReferenceDtos || !studentReferenceDtos.length">
        <td colspan="12" style="text-align:center;color: #999;">Empty Data</td>
      </tr>
    </table>
    <table cellspacing="0">
      <tr>
        <td colspan="4" style="font-weight:bold; background: #e1e1e1; color: #ff9700;">★Institution Choice (Please enter the exact name on official website and specify the major, campus and course duration to apply for)</td>
      </tr>
      <template v-if="studentInstitutionCourseDtos && studentInstitutionCourseDtos.length">
        <template v-for="(item, key) in studentInstitutionCourseDtos">
          <tr :key="key+'row1'">
            <td style="background: #f1f1f1">★Institution Name</td>
            <td colspan="3">{{item.fkInstitutionName}}</td>
          </tr>
          <tr :key="key+'row2'">
            <td style="background: #f1f1f1">★Course Website Link</td>
            <td colspan="3">{{item.institutionCourseWebsite}}</td>
          </tr>
          <tr :key="key+'row3'">
            <td style="width:25%; background: #f1f1f1">★Course</td>
            <td style="width:25%">{{item.fkInstitutionCourseName||item.institutionCourseName}}</td>
            <td style="width:25%; background: #f1f1f1">★Start Date</td>
            <td style="width:25%">{{dateToForm(item.openingTime)}}</td>
          </tr>
        </template>
        <tr>
          <td colspan="4" style="height: 120px; vertical-align: top">Do you apply for recognition of prior learning? (If yes, please provide course description in original language and in English translation at the time of application, or indicate to provide the document later)</td>
        </tr>
      </template>
      <tr v-else>
        <td colspan="10" style="text-align:center;color: #999;">Empty Data</td>
      </tr>
    </table>
    <table cellspacing="0">
      <tr>
        <td style="width: 25%;">Do you require accommodation?<br/>Yes (&nbsp;&nbsp;&nbsp;) / No (&nbsp;&nbsp;&nbsp;) </td>
        <td style="height: 80px;">Do you prefer student residence or homestay? (Homestay may be the only option for some institutions.) <u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u></td>
      </tr>
      <tr>
        <td>Do you require an airport pickup?<br/>Yes (&nbsp;&nbsp;&nbsp;) / No (&nbsp;&nbsp;&nbsp;) </td>
        <td style="height: 80px; vertical-align: top">Note (if you have any special requirement):</td>
      </tr>
    </table>
    <table cellspacing="0">
      <tr>
        <td style="font-weight:bold; background: #e1e1e1; color: #ff9700;">Application Document Checklist (Please tick the items which you have provided.)</td>
      </tr>
      <tr>
        <td>
          <span style="font-weight:bold">★Compulsory Documents:</span>
          <br/>
          <br/>
          <span>(&nbsp;&nbsp;&nbsp;) Completed Student Information Form (in WORD format) with a scanned copy of the signature page signed by the applicant and his/her parents</span>
          <br/>
          <br/>
          <span>(&nbsp;&nbsp;&nbsp;) Official Certificate of Enrollment and translation</span>
          <br/>
          <br/>
          <span>(&nbsp;&nbsp;&nbsp;) Official transcript(s) and translation</span>
          <br/>
          <br/>
          <span>(&nbsp;&nbsp;&nbsp;) IELTS/TOEFL certificate (if available. Please provide login details including password of the applicant's account of the IELTS/TOEFL official website for authenticity check. If not available yet, it can be submitted later.)</span>
          <br/>
          <br/>
          <span>(&nbsp;&nbsp;&nbsp;) Official graduation certificate(s) and translation</span>
          <br/>
          <br/>
          <span>(&nbsp;&nbsp;&nbsp;) Official degree certificate(s) and translation</span>
          <br/>
          <br/>
          <span>(&nbsp;&nbsp;&nbsp;) Have you ever been refused a visa application to any country? (If yes, please list the date of refusal and provide the refusal letter(s).)</span>
          <br/>
          <br/>
          <span>(&nbsp;&nbsp;&nbsp;) Passport copy (If there’s any visa record or exit and entry stamp in it, please also provide the scanned page.)</span>
          <br/>
          <br/>
          <br/>
          <span style="font-weight:bold">★Optional Documents:</span>
          <br/>
          <br/>
          <span>(&nbsp;&nbsp;&nbsp;) Curriculum Vitae (compulsory if the applicant has work experience or if the gap between two periods of education/work is 6 months or more.)</span>
          <br/>
          <br/>
          <span>(&nbsp;&nbsp;&nbsp;) Personal Statement in English (WORD format)</span>
          <br/>
          <br/>
          <span>(&nbsp;&nbsp;&nbsp;) Letter of Reference (One for a foundation/undergraduate degree program, two for a graduate program) The letter must show the position, address, email and phone number of the referee, be printed with referee’s workplace letter head, and be signed and dated by the referee. If the referee has an official workplace email address, please provide it.</span>
          <br/>
          <br/>
          <span>(&nbsp;&nbsp;&nbsp;) Award/qualification certificate(s) and translation (must be relevant to the proposed course)</span>
          <br/>
          <br/>
          <span>(&nbsp;&nbsp;&nbsp;) Portfolio (required for design/art courses)</span>
        </td>
      </tr>
    </table>
    <table cellspacing="0">
      <tr>
        <td colspan="4" style="font-weight:bold; background: #e1e1e1; color: #ff9700;">Credit Card Information (If application fee is required and if we are authorized to use the card to pay application fee on behalf of the applicant.)</td>
      </tr>
      <tr>
        <td style="width: 25%; background: #f1f1f1">Name on Card</td>
        <td style="width: 25%"></td>
        <td style="width: 25%; background: #f1f1f1">Type of Card</td>
        <td style="width: 25%"></td>
      </tr>
      <tr>
        <td style="background: #f1f1f1">Card Number</td>
        <td colspan="3"></td>
      </tr>
      <tr>
        <td style="background: #f1f1f1">Date of Issue</td>
        <td></td>
        <td style="background: #f1f1f1">Expire Date</td>
        <td></td>
      </tr>
      <tr>
        <td colspan="3" style="background: #f1f1f1">Security Code (the last 3 numbers in the signature section on the back of the card)</td>
        <td></td>
      </tr>
      <tr>
        <td style="background: #f1f1f1">Billing Address</td>
        <td colspan="3"></td>
      </tr>
      <tr>
        <td colspan="4" style="verticle-align: top; background: #f1f1f1">
          <span style="font-weight:bold; color: #ff9700;">Attention:</span><br/><br/>
          <span>1. A dual-currency card of HSBC, China Guangfa Bank, Shanghai Pudong Development Bank or Bank of China is suggested;</span><br/><br/>
          <span>2. Please make sure that the credit limit is sufficient for deduction of fees (including the application fee and bank charges);</span><br/><br/>
          <span>3. Please make sure the online payment function has been activated.</span>
        </td>
      </tr>
    </table>
    <p style="font-size: 14px; text-align: center; margin: 88px 0 24px;">Declaration</p>
    <p style="font-size: 14px; margin-bottom:12px;line-height: 20px;">We declare that the information we have given in this form is true and correct.</p>
    <p style="font-size: 14px; margin-bottom:12px;line-height: 20px;">We declare that the documents we have provided including transcript, graduation certificate and finance sources are genuine.</p>
    <p style="font-size: 14px; margin-bottom:12px;line-height: 20px;">We declare that we have abundant funds for overseas education. We assure you that the student will not encounter any financial difficulty during overseas studies. We are willing to take relevant responsibilities.</p>
    <p style="font-size: 14px; margin-bottom:24px">I agree to authorize my agent to fill in all forms, receive and send emails and sign all documents related to overseas studies on my behalf throughout my application for admission and enrolment to my proposed institution.</p>
    <p style="font-size: 14px; margin-bottom:36px">Date: </p>
    <p style="font-size: 14px; margin-bottom:12px; font-weight: bold">★Signature</p>
    <p style="font-size: 14px; margin-bottom:36px;">Student:</p>
    <p style="font-size: 14px; margin-bottom:36px;">Father:</p>
    <p style="font-size: 14px; margin-bottom:36px;">Mother:</p>
  </div>
</template>

<script>
import {
  getStudentApply,
  getIssueMajorLevelListApi,  // 专业等级
  getStateOptions,      // 省份
  getCityOptions,       // 城市
  getScoreTypeApi,
  getIssueTitle,
} from "@/api/common.js";
export default {
  inject: ['stuid', 'ctryOptions', ],
  data(){
    return {
      studentDto:{},// 学生个人资料
      studentInfoDto: {}, // 注册信息
      studentAppInFoDto: {}, // 个人资料
      accommodationDto: {}, // 后续安排
      studentParentsDto: {}, // 父母联系方式
      studentCountryItemDto: {}, // 目标国家
      studentEducationDtos: [], // 教育背景
      studentInstitutionCourseDtos: [], // 院校申请
      studentQualificationDtos: [], // 专业资格
      studentReferenceDtos: [], // 推荐人
      studentScoreDtos: [], // 考试成绩
      studentWorkDtos: [], // 工作经验
      observer: undefined,
    }
  },
  mounted(){
    // document.body.appendChild(this.$el)
    this.setStyle();
    this.observer = new MutationObserver(()=>{
      this.setStyle()
    })
    this.observer.observe(this.$el, { attributes: false, childList: true, subtree: true })
  },
  destroyed(){
    this.observer && this.observer.disconnect();
  },
  methods:{
    async getIssueTitle() {
      const { data } = await getIssueTitle();
      const titleOptions = data.data.titalEnum;
      return Promise.resolve(titleOptions);
    },
    setStyle(){
      const tdDom = this.$el.querySelectorAll('td')
      tdDom.forEach(item=>{
        item.style.padding = '12px 10px'
        item.style.border = '1px solid #999'
        item.style.whiteSpace = 'pre-line'
        item.style.wordBreak = 'break-all'
      })
      const tableDom = this.$el.querySelectorAll('table')
      tableDom.forEach((item,index)=>{
        item.style.width = '100%';
        item.style.borderCollapse = 'collapse'
        item.style.margin = '0px auto'
        item.style.verticalAlign = 'middle'
        item.style.borderBottom = '0px'
        item.style.fontSize = '14px'
        if(index == tableDom.length-1) return;
        const trDom = item.querySelectorAll('tr')
        const tdDom = trDom[trDom.length-1].querySelectorAll('td')
        tdDom.forEach(jtem=>{
          jtem.style.borderBottom = '0px'
        })
      })
    },
    dateToForm(str){
      if(!str) return ''
      let [year, month, day] = str.split('-')
      if(!day){
        return `${month}/${year}`;
      }
      day = day.split(' ')[0]
      return `${day}/${month}/${year}`;
    },
    async initFormGetHtml(){
      if (!this.stuid()) {
        this.$msge.error(this.t('validate.personalInfo'))
        return '';
      }
      const marjorData = await getIssueMajorLevelListApi();
      const marjorSelect = marjorData.data.datas;
      const scoreTypeData = await getScoreTypeApi();
      const scoreTypeSelect = scoreTypeData.data.datas;
      let res = await getStudentApply(this.stuid());
      if (res.data.data) {
        const {
          studentDto,
          accommodationDto,
          studentParentsDto,
          studentInfoDto,
          studentAppInFoDto,
          studentCountryItemDto,
          studentEducationDtos,
          studentInstitutionCourseDtos,
          studentQualificationDtos,
          studentReferenceDtos,
          studentScoreDtos,
          studentWorkDtos,
        } = res.data.data;
        this.accommodationDto = accommodationDto;
        this.studentParentsDto = studentParentsDto;
        this.studentInfoDto = studentInfoDto;
        this.studentAppInFoDto = studentAppInFoDto;
        this.studentDto = studentDto;
        this.studentCountryItemDto = studentCountryItemDto;
        if(studentEducationDtos){
          for await (let item of studentEducationDtos) {
            if(marjorSelect && marjorSelect.length){
              marjorSelect.find((option => {
                return option.majorLevelDtoList.some(jtem => {
                  if (jtem.id === item.fkMajorLevelId) {
                    Object.assign(item, { levelName: jtem.levelName })
                    return true;
                  }
                })
              }))
              // const major = marjorSelect.find(jtem=>jtem.id == item.fkMajorLevelId)
              // Object.assign(item, {levelName: major? major.levelName: ''})
            }
            if(scoreTypeSelect && scoreTypeSelect.length){
              const score = scoreTypeSelect.find(jtem=>jtem.key == item.scoreType)
              Object.assign(item, {scoreName: score? score.value: ''})
            }
            const fkAddress = await this.getAddressInfoById(item.fkStudentAppCountryId, item.fkAreaStateId, item.fkAreaCityId)
            Object.assign(item, {fkAddress})
          }
          this.studentEducationDtos = studentEducationDtos;
        } else {
          this.studentEducationDtos = [];
        }
        this.studentQualificationDtos = studentQualificationDtos || [];
        if(studentReferenceDtos){
          const titleOptions = await this.getIssueTitle();
          for await (let item of studentReferenceDtos){
            const fkAddress = await this.getAddressInfoById(item.fkStudentAppCountryId, item.fkAreaStateId, item.fkAreaCityId)
            const itemOption = titleOptions.find((option) => item.title == option.key);
            Object.assign(item, {fkAddress, titleName: itemOption.value})
          }
          this.studentReferenceDtos = studentReferenceDtos
        } else {
          this.studentReferenceDtos = []
        }
        this.studentScoreDtos = studentScoreDtos || [];
        if(studentWorkDtos){
          for await (let item of studentWorkDtos){
            const fkAddress = await this.getAddressInfoById(item.fkStudentAppCountryId, item.fkAreaStateId, item.fkAreaCityId)
            Object.assign(item, {fkAddress})
          }
          this.studentWorkDtos = studentWorkDtos;
        } else {
          this.studentWorkDtos = [];
        }
        this.studentInstitutionCourseDtos = studentInstitutionCourseDtos || [];
      }
      const ret = await new Promise((res)=>{
        setTimeout(()=>{
          let tableDom = document.querySelector('.export-form');
          tableDom.style.display = 'table'
          let html =`
            <!DOCTYPE html>
            <html 
              xmlns:o="urn:schemas-microsoft-com:office:office"
              xmlns:w="urn:schemas-microsoft-com:office:word"
              xmlns:m="http://schemas.microsoft.com/office/2004/12/omml"
              xmlns:dt="uuid:C2F41010-65B3-11d1-A29F-00AA00C14882"
              xmlns="http://www.w3.org/TR/REC-html40>
            <head>
              <meta http-equiv="Content-Type" content="text/html" charset="UTF-8">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <title>Document</title>
            </head>
            <body>
              ${tableDom.outerHTML}
            </body>
            </html>
          `;
          res(html)
        })
      })
      return ret;
    },
    async exportForm(){
      const loading = this.$msge.loading('loading...');
      try {
        const html = await this.initFormGetHtml();
        const blob = new Blob([html])
        const aLink = document.createElement('a')
        aLink.download = `${this.studentInfoDto.nameZh}(${this.studentDto.receiptCode}).doc`
        aLink.style.display = 'none';
        aLink.href = URL.createObjectURL(blob)
        document.body.appendChild(aLink)
        aLink.click();
        document.body.removeChild(aLink);
      } catch {}
      loading.close();
    },
    async getAddressInfoById(countryId, stateId, cityId){
      let countryName = '', stateName = '', cityName = '';

      if(stateId) {
        if(isNaN(Number(stateId))){
          stateName = stateId
        } else {
          const stateData = await getStateOptions(countryId)
          const stateSelect = stateData.data.datas || [];
          if(stateSelect.length){
            const state = stateSelect.find(item=>item.id == stateId)
            stateName = state? state.name: stateId;
          }
        }
      }
      
      if(cityId) {
        if(isNaN(Number(cityId))){
          cityName = cityId
        } else {
          const cityData = await getCityOptions(stateId)
          const citySelect = cityData.data.datas || [];
          if(citySelect.length){
            const city = citySelect.find(item=>item.id == cityId)
            cityName = city? city.name: cityId;
          }
        }
      }
     
      const countrySelect = this.ctryOptions();
      if(countrySelect.length){
        const country = countrySelect.find(item=>item.id == countryId)
        countryName = country? country.name: countryId;
      }
      return `${cityName?cityName + ',': ''}${stateName?stateName + ',': ''}` + countryName
    }
  }
}
</script>