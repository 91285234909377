import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import commonUI from "./index";
import ElementUI from "@/utils/element-ui-setting";
import "@/assets/style/index.scss";
import Message from '/packages/components/message'
import MessageBox from '/packages/components/message-box'
import OwlMobileDate from '/packages/components/OwlMobileDate.vue'
import toolStore from './store'
Vue.use(ElementUI);
Vue.use(commonUI);
Vue.component('OwlMobileDate',OwlMobileDate)

Vue.config.productionTip = false;
Vue.prototype.$msge = Message
Vue.prototype.$confIssue = MessageBox.confirm;
Vue.prototype.$alrt = MessageBox.alert;
Vue.prototype.$prmt = MessageBox.prompt
Vue.prototype.$isMobile = function(){
  const isMobileReg = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  return window && isMobileReg.test(window.navigator.userAgent);
}
Vue.prototype.$isPad = function(){
  return window && window.navigator.userAgent.indexOf('iPad')>=0;
}

new Vue({
  router,
  data: toolStore,
  render: (h) => h(App),
}).$mount("#app");

