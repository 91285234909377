<template>
  <div class="dialog-block">
    <upload-header
    :imgUrl="headerInfo.imgUrl"
    :name="headerInfo.name"
    :text="headerInfo.headerText"
    @file-upload="fileUpload"
    ></upload-header>
    <div class="information">
      <div class="item" v-for="(item, index) in contentInfo.status" :key="index">
        <div class="item-info" :style="{color: item.exist ? mainColor : '#333333'}">{{ t(item.title) }}</div>
        <div class="has-info">
          <span class="not-upload" v-if="!contentInfo.hasUploaded">{{ t('common.notUpload') }}</span>
          <template v-else>
            <FilledIcon v-if="item.exist" height="14" width="14" :fill="mainColor"></FilledIcon>
            <UnFilledIcon v-else height="14" width="14"></UnFilledIcon>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UploadHeader from './UploadHeader.vue';
import FilledIcon from '/packages/components/svg-icon/filled.vue';
import UnFilledIcon from '/packages/components/svg-icon/unFilled.vue';
import { enCvParseApi } from "@/api/common.js";
import i18n from "@/locale/index";

export default {
  name: 'uploadBlock',
  components: {
    UploadHeader,
    UnFilledIcon,
    FilledIcon,
  },
  inject: ["stuid"],
  mixins: [i18n],
  data: () => {
    return {
    }
  },
  props: {
    headerInfo: Object,
    contentInfo: Object,
    mainColor: String,
    isNotUpload: {
      type: Boolean,
      default: true,
    },
    type: String
  },
  methods: {
    async fileUpload(file) {
      this.$emit('set-loading', true);
      const type = this.type;
      const formData =  new FormData();
      formData.append('file', file);
      formData.append('type', type);
      formData.append('fkStudentId', this.stuid());
      if (type === 'issueFileResolverPassportNational' || type === 'issueFileResolverPassportCn') {
        formData.append('businessFileType', 'PASSPORT');
      } else {
        formData.append('businessFileType', 'CV');
      }
      try {
        const { data } = await enCvParseApi(formData);
        const resData = JSON.parse(data.data);
        console.log(resData);
        this.$emit('set-loading', false);
        if (resData.data.error_code) {
          return this.$msge.error(resData.data.error_msg);
        }
        if ((resData.result === 'success') && (Object.keys(resData.data).length) || (resData.status === 'success')) {
          const passportData = resData.data;
          const guid = resData.fkGuIds[0];
          const gender = ['女/F', '女', 'F'].includes(passportData.Sex) ? 0 : 1;
          if (type === 'issueFileResolverPassportCn') {
            const personInfo = {
              nameZh: passportData.Name,
              birthday: this.getFormatDate(passportData.BirthDate),
              gender, // 1:男 0:女
              lastName: passportData.FamilyName,
              firstName: passportData.FirstName,
              passportNumber: passportData.PassportNo,
              passportIssueDate: this.getFormatDate(passportData.IssueDate),
              passportIssueExpried: this.getFormatDate(passportData.ExpiryDate),
              fkStudentAppCountryIdFrom: 44,
              fkStudentAppCountryIdPassport: 44,
              isHavePassport: true,
              title: gender === 0 ? 2 : 1,
              cityBirth: passportData.BirthPlace,
            }
            this.$emit('set-analysis-status', {type: this.type, personInfo});
            this.$emit('set-result-Identified', { personInfo });
          } else if (type === 'issueFileResolverPassportNational') {
            const personInfo = {
              nameZh: '',
              birthday: this.getFormatDate(passportData.DateOfBirth),
              gender, // 1:男 0:女
              lastName: passportData.Surname,
              firstName: passportData.GivenName,
              passportNumber: passportData.ID,
              passportIssueDate: "",
              passportIssueExpried: this.getFormatDate(passportData.DateOfExpiration),
              fkStudentAppCountryIdFrom: passportData.CountryId,
              fkStudentAppCountryIdPassport: passportData.CountryId,
              isHavePassport: true,
              title: gender === 0 ? 2 : 1,
              cityBirth: passportData.BirthPlace,
            }
            this.$emit('set-analysis-status', {type: this.type, personInfo});
            this.$emit('set-result-Identified', { personInfo });
          } else if (type === 'issueFileResolverEduBackground') {
            const { educationBackground, name, workingExperience } = resData.data;
            const workExp = this.setWorkingExperience(workingExperience);
            const eduBack = this.setEducationBackground(educationBackground);
            // const personInfo = { // 教育背景识别的姓名不覆盖个人信息
            //   lastName: name.firstName.text ? name.firstName.text : undefined,
            //   firstName: name.surname.text ? name.surname.text : undefined,
            // }
            this.$emit('set-analysis-status', {type: this.type, workExp, eduBack });
            this.$emit('set-result-Identified', { workExp, eduBack,guid });
          }
        }
      } catch (err) {
        console.log(err);
        this.$emit('set-loading', false);
      }
    },
    setEducationBackground(educationBackground) {
      if (educationBackground.length) {
        const eduBack = educationBackground.map((eduData, index) => {
          const fkAreaCityId = eduData.location.cityId;
          const fkAreaStateId = eduData.location.stateId;
          const fkStudentAppCountryId = eduData.location.countryId;
          const educationLevelId = eduData.institution.educationLevelId;
          let isCompleted = true;
          let isShouldCompleted = true;
          let educationType = '';
          if ([60,61,62,63,70,71,81,82,83,90,9,100,101,102,103].includes(educationLevelId)) {
            educationType = 2;
          } else if ([10,20,30,40,41,42,50,51,52,53,54,55,56,57].includes(educationLevelId)) {
            educationType = 1;
          }
          const nowDateTime = new Date().getTime();
          const endDateTime = new Date(eduData.endTime.date).getTime();
          if (endDateTime > nowDateTime) {
            isCompleted = false;
          }
          return {
            major: eduData.major.text,
            studyInEnglish: eduData.isEnglish ? 2 : 0,
            studyStartDate: eduData.startTime.date,
            studyEndDate: eduData.endTime.date,
            fkStudentAppCountryId: fkStudentAppCountryId === -1 ? '' : fkStudentAppCountryId,
            fkAreaStateId: fkAreaStateId === -1 ? '' : fkAreaStateId,
            fkAreaCityId: fkAreaCityId === -1 ? '' : fkAreaCityId,
            fkInstitutionIdName: eduData.institution.text,
            fkInstitutionId: eduData.institution.schoolId !== -1 ? eduData.institution.schoolId : '',
            fkEducationLevelType: educationLevelId === -1 ? '' : educationLevelId,
            educationType,
            isCompleted,
            isShouldCompleted,
            address: eduData.institution.schoolAddress,
          }
        });
        return eduBack;
      }
      return [];
    },
    setWorkingExperience(workingExperience) {
      if (workingExperience.length) {
        const workExp = workingExperience.map((workData, index) => {
          const companyName = workData.company.text;
          const position = workData.position.text;
          const workStartDate = workData.startTime.date;
          const workEndDate = workData.endTime.date;
          const jobDescription = workData.workScope.text;
          const workMode = workData.workingType;
          const isOnDuty = workData.isContinueJob;
          const fkStudentAppCountryId = workData.location.countryId;
          const fkAreaStateId = workData.location.stateId;
          const fkAreaCityId = workData.location.cityId;
          return {
            companyName,
            position,
            workStartDate,
            workEndDate,
            jobDescription,
            fkStudentAppCountryId: fkStudentAppCountryId === -1 ? '' : fkStudentAppCountryId,
            fkAreaStateId: fkAreaStateId === -1 ? '' : fkAreaStateId,
            fkAreaCityId: fkAreaCityId === -1 ? '' : fkAreaCityId,
            workMode: workMode === 'part_time' ? 0 : 1, // 0: full time, 1: part time
            isOnDuty,
          }
        });
        return workExp;
      }
      return [];
    },
    getFormatDate(date) {
      if (date && date.length === 8) {
        const year = date.substring(0, 4); // year only (YYYY) 	YYYY-YYYY 	YYYY-YY
        const month = date.substring(4, 6); // month only (MM)	MM-MM 	MM-MMM 	MMMM-
        const day = date.substring(6, 8); // day only (DD)	DD-DD 	DD-DDD 	DDDD-
        return `${year}-${month}-${day}`;
      }
      return undefined;
    }
  }
}
</script>

<style lang='scss' scoped>
.dialog-block {
  width: 230px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;
  .information {
    height: 240px;
    width: 100%;
    margin-top: 4px;
    background: #F7F7F7;
    padding: 15px;
    .item {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      padding: 6px 0;
    }
    .not-upload {
      color: #999999;
    }
  }
}
</style>