<template>
  <div 
    :class="['stu-all-form-info mso-student-form', !studentId && !isMobile ? 'padding-left' : '']"
    ref="stuAllForm"
    :style="{ width: width }"
    v-if="!initLoading"
  >
    <slider 
      v-if="studentId"
      :nav-list="navTitleList" 
      :active-list="filterNavList" 
      v-model="navActive" 
      @handle-apply-info="handleApplyInfo"
      :hide-main-submit-btn="hideMainSubmitBtn"
    />
    <div class="forms-box">
      <div class="fixed-top">
        <h2 class="mso-form-title">{{t('mso.apply')}}<span class="tip">{{t('mso.pleaseLeaveTime')}}</span></h2>
        <apply-proccess 
          :proccess1="proccess1"
          :proccess2="proccess2"
          :proccess3="proccess3"
          :proccess4="proccess4_reference"
          :proccess5="proccess5_file"
          :proccess6="proccess6_accommondation"
          :list="filterNavList" 
        />
      </div>
      <el-collapse v-model="collapseActive" class="mso-collapse">
        <el-collapse-item :title="navTitleList[0]" name="nav0">
          <student-info
            :studentAppInFoDto="studentInfoDto"
            class="form-box-item"
            ref="personalInfo"
            :fkPlatformType="fkPlatformType"
            @on-porccess-changed="(val)=>{proccess1_studentInfo = val}"
            @on-student-update="onStudentUpdate"
          ></student-info>
          <!-- id 13 -->
          <student-parent-contact
            v-if="studentId"
            ref="relativesContact"
            :studentParentsDto="studentParentsDto"
            class="form-box-item"
            :required="isRequireParentContact"
            @on-porccess-changed="(val)=>{proccess1_parent = val}"
          />
        </el-collapse-item>
        <el-collapse-item :title="navTitleList[1]" name="nav1" v-if="studentId">
          <student-application
            :init-datas="studentInstitutionCourseDtos"
            @getDestinationCountry="getDestinationCountry"
            @updateDestinationCountry="updateDestinationCountry"
            class="form-box-item"
            ref="applicationSch"
            @on-porccess-changed="(val)=>{proccess2_isStudentApplySchool = val}"
            @get-config="updateConfigByAdd"
            @delete-config="delConfigByApply"
            @on-active-handle="onClickNavItem"
            :studentCountryItemDto="studentCountryItemDto"
            @on-mso-add-apply="shrinkAllExcept('nav1')"
            @update="setApplyInsititionTree"
            @update-apply="(updateApply) => getStudentApply(updateApply)"
          />
          <student-background-info
            :studentCountryItemDto="studentCountryItemDto"
            class="form-box-item"
            style="margin-top: 12px;"
            ref="backgroundInfo"
            @on-porccess-changed="(val)=>{proccess2_background = val}"
          ></student-background-info>
        </el-collapse-item>
        <el-collapse-item :title="navTitleList[2]" name="nav2" v-if="filterNavList[2].length">
          <!-- 教育背景 -->
          <student-education-background
            v-if="filterNavList[2].indexOf('education') >= 0"
            :show-main-title="true"
            ref="education"
            :required="subCateList['education'] && subCateList['education'].isRequired"
            :init-datas="studentEducationDtos"
            @on-porccess-changed="(val)=>{proccess3_enducation = val}"
            class="form-box-item"
          />
          <!-- 语言成绩 -->
          <student-language-proficiency
            v-if="filterNavList[2].indexOf('language') >= 0"
            ref="language"
            :required="subCateList['language'] && subCateList['language'].isRequired"
            :show-main-title="true"
            :studentScoreDtos="studentScoreDtos"
            @on-porccess-changed="(val)=>{proccess3_score = val}"
            class="form-box-item"
          ></student-language-proficiency>
          <!-- 工作经验 -->
          <student-work-experience
            v-if="filterNavList[2].indexOf('workExperience') >= 0"
            :show-main-title="true"
            ref="workExperience"
            :required="subCateList['workExperience'] && subCateList['workExperience'].isRequired"
            :init-datas="studentWorkDtos"
            @on-porccess-changed="(val)=>{proccess3_work = val}"
            class="form-box-item"
          ></student-work-experience>
          <!-- 证书技能 -->
          <student-professional-qualification
            v-if="filterNavList[2].indexOf('qualifications') >= 0"
            :show-main-title="true"
            :studentQualificationDtos="studentQualificationDtos"
            :required="subCateList['qualifications'] && subCateList['qualifications'].isRequired"
            @on-porccess-changed="(val)=>{proccess3_skill = val}"
            class="form-box-item"
            ref="qualifications"
          ></student-professional-qualification>
        </el-collapse-item>
        <el-collapse-item :title="navTitleList[3]" name="nav3" v-if="filterNavList[3].length">
          <student-referee
            ref="recommend"
            :required="subCateList['recommend'] && subCateList['recommend'].isRequired"
            :studentReferenceDtos="studentReferenceDtos"
            :studentApplyInstitutionTree="studentApplyInstitutionTree"
            class="form-box-item"
            @on-porccess-changed="val => (proccess4_reference = val)"
            @on-letter-change="onLetterChange"
          ></student-referee>
        </el-collapse-item>
        <el-collapse-item :title="navTitleList[4]" name="nav4" v-if="filterNavList[4].length">
          <student-file-upload
            ref="fileData"
            :toFileCompObj="toFileCompObj"
            :studentFileUploadList="studentFileUploadList"
            class="form-box-item"
            @on-porccess-changed="val => (proccess5_file = val)"
          ></student-file-upload>
        </el-collapse-item>
        <el-collapse-item :title="navTitleList[5]" name="nav5" v-if="filterNavList[5].length">
          <student-accommodation
            ref="arrangements"
            :accommodationDto="accommodationDto"
            class="form-box-item"
            @on-porccess-changed="(val)=>{proccess6_accommondation = val}"
          ></student-accommodation>
        </el-collapse-item>
      </el-collapse>
    </div>
    <apply-confirm
      ref="applyConfrim"
      :dialogVisible.sync="dialogVisible"
      :stufullName="stufullName"
      @handle-save-form="handleSaveForm"
    ></apply-confirm>
  </div>
</template>

<script>
import Slider from '../components/slider.vue'
import i18n from "@/locale/index";
import service from "@/utils/request";
import ApplyProccess from '../components/ApplyProcess.vue'
import ApplyConfirm from "../../components/ApplyConfirm.vue";
import {
  getStudentApply,
  getAppCountry,
  getFromConfig,
  
} from "@/api/common.js";
let requestId = 0;
let propRequestId = 0;
let propRespondId = 0;
export default {
  name: 'MsoStudentForm',
  components:{
    Slider,
    ApplyProccess,
    ApplyConfirm,
  },
  mixins: [i18n],
  props: {
    // 学生id
    studentId: {
      require: true,
      type: Number,
      default: null,
    },
    // 语言 
    lang: {
      type: String,
      default: "zh",
    },
    // 代理id
    publicLevel: {
      type: String,
      default: "5",
    },
    // 引用该公共资源的平台
    fkPlatformType: {
      type: String,
      default: "get_mso",
    },
    width: {
      type: String,
      require: false,
      default: "100%",
    },
    height: {
      type: String,
      require: false,
      default: "100%",
    },
    agentId: {
      require: true,
      type: Number,
      default: null,
    },
    // 请求截断
    interceptorsRequest: {
      require: false,
      type: Function,
    },
    // 响应截断
    interceptorsResponse: {
      require: false,
      type: Function,
    },
    // 只用于平台展示用
    platformType: {
      require: false,
      type: String,
      default: "",
    },
    ciphertext: {
      require: false,
      type: String,
      default: "",
    },
    // 是否纯浏览
    isDisable: {
      require: false,
      type: Boolean,
      default: false,
    },
    privateFilesUrl: {
      require: false,
      type: String,
      default: ""
    },
    publicFilesUrl: {
      require: false,
      type: String,
      default: ""
    },
    at: {
      request: false,
      type: String,
      default: ""
    },
    authorization: {
      require: false,
      type: String,
      default: "" //eg. Authorization: Basic bXNvQ2VudGVyOm1zb0NlbnRlcg== 
    },
    issueInnerAccount: {
      require: false,
      type: Boolean,
      default: false
    }
  },
  provide() {
    return {
      getLocale: () => this.lang,
      getAgentId: () => this.agentId,
      stuid: () => this.studentId,
      ctryOptions: () => this.countryOptions,
      hideSubmitBtn: () => this.hideSubmitBtn,
      platformType: () => this.fkPlatformType,
      formStatus: () => this.formStatus,
      isBmsLogin: () => false,
      formType: () => 'gea',
      companyId: () => this.companyId,
      platformLimitList: () => [],
      issueInnerAccount: () => this.issueInnerAccount,
    };
  },
  data() {
    return {
      initLoading: true,    // 初始化加载中
      formStatus: 0, // 0新建未提交 1提交申请 2 需要补件 3加申院校
      dialogVisible: false, // 提交申请确认弹窗
      countryOptions: [], // 国家选校

      studentParentsDto: {}, // 1父母联系方式
      studentInfoDto: {}, // 1注册信息
      proccess1_studentInfo: 0, // 1学生信息 0~100
      proccess1_parent: 0, // 1父母信息 0~100

      studentInstitutionCourseDtos: [], // 2院校申请
      studentCountryItemDto: {}, // 2目标国家

      proccess2_isStudentApplySchool: 0,  // 2 是否申请了院校 0~100
      proccess2_background: 0,  // 2 宗教信仰目标国家等背景资料 0~100

      studentEducationDtos: [], // 3教育背景
      studentScoreDtos: [], // 3考试成绩
      studentWorkDtos: [], // 3工作经验
      studentQualificationDtos: [], // 3专业资格

      proccess3_enducation: 0, // 教育背景进度 0~100
      proccess3_score: 0, // 考试成绩进度 0~100
      proccess3_work: 0, // 工作经验进度 0~100
      proccess3_skill: 0, // 专业资格进度 0~100

      studentReferenceDtos: [], // 4推荐人
      proccess4_reference: 0, // 推荐人进度 0~100

      toFileCompObj: {}, // 5传递给文件页的包含国家学校专业等级的对象
      proccess5_file: 0, //文件上传进度 0~100
      accommodationDto: {}, // 6后续安排
      proccess6_accommondation: 0, // 后续安排进度 0~100

      navTitleList: [], // 标题
      activeCategory: {}, // 需要显示的小分类
      needUploadFileList: [ 
        {
          id: 0,
          name: 'Uploaded Public File',
          list: []
        }
      ], // 需要上传的资料
      studentFileUploadList: [],
      isHideFileUpload: false,
      currentData: "", // 当前点击的模块name
      showBackgroundInfo: false, // 展示背景资料
      stufullName: {}, // 学生全名
      stuBirthday: '', // 生日
      activeNames: [  // 大分类列表，每个分类可能有小分类
        ["personalInfo", "relativesContact"], // 个人信息, 父母联系方式
        ["applicationSch", "backgroundInfo"], // 院校申请, 背景资料
        ["education", "language", "workExperience", "qualifications"], // 教育背景，语言成绩，工作经验，证书
        "recommend",    // 推荐人
        "fileData",     // 学术资料上传
        "arrangements", // 后续安排
      ],
      navActive: 'nav0', // 侧边栏默认第一个
      collapseActive: ['nav0'], // 手风琴选中的项
      studentApplyInstitutionTree: {},  // 申请的院校课程树
      letters: [],  // 推荐信列表{fileNameOrc: string,fkFileGuid: string, fkStudentInstitutionCourseId:[]}
    };
  },
  computed:{
    // 是否隐藏提交，编辑等相关按钮
    hideSubmitBtn(){
      return this.isDisable || this.formStatus === 1 || this.formStatus === 3;
    },
    hideMainSubmitBtn(){
      return this.isDisable || this.formStatus === 1;
    },
    // 通过配置显示过滤后的小分类名称ID
    filterNavList() {
      if (!this.studentId) {
        return [
          ["personalInfo"],
          '',
          '',
          '',
          '',
          ''
        ]
      }
      if (Object.keys(this.activeCategory).length === 0) {
        return [
          ["personalInfo", "relativesContact"],
          ['applicationSch'],
          '',
          '',
          '',
          ''
        ]
      }
      const list = this.subCateList;
      const filterList = this.activeNames.map((item, index)=>{
        if(index == 0 || index == 1) return item.filter(() => true)
        if(item.constructor.toString().indexOf('Array') >= 0) {
          return item.filter(jtem => list[jtem])
        } else {
          if(list[item]){
            return item;
          } else {
            return '';
          }
        }
      })
      return filterList;
    },
    // 需要显示的子分类
    subCateList(){
      let list = {}; 
      for (let key in this.activeCategory) {
        for (let item of this.activeCategory[key]) {
          if (!list[item.divisionKey]) {
            list[item.divisionKey] = item;
          }
        }
      }
      return list;
    },
    // 学生生日是否超过18岁，超过则父母联系人不必填
    isRequireParentContact() {
      if (this.stuBirthday) {
        const birthdayYear = (new Date(this.stuBirthday)).getFullYear();
        const nowYear = (new Date()).getFullYear();
        if (nowYear - birthdayYear < 18) {
          return true;
        }
      }
      return false;
    },
    // 个人信息进度
    proccess1(){
      let proccess = 0;
      proccess += this.proccess1_studentInfo / 2;
      proccess += this.proccess1_parent / 2;
      return proccess;
    },
    // 院校申请进度
    proccess2(){
      let proccess = 0;
      proccess += this.proccess2_isStudentApplySchool/2;
      proccess += this.proccess2_background/2;
      return proccess;
    },
    // 学术工作背景进度
    proccess3(){
      let proccess = 0;
      let lng = this.filterNavList[2].length;
      if(!lng) return proccess;
      for(let item of this.filterNavList[2]){
        switch (item){
          case 'education':
            proccess += this.proccess3_enducation / lng;
            break;
          case 'language':
            proccess += this.proccess3_score / lng;
            break;
          case 'workExperience':
            proccess += this.proccess3_work / lng;
            break;
          case 'qualifications':
            proccess += this.proccess3_skill / lng;
            break;
        }
      }
      return Math.round(proccess)
    },
    isMobile(){
      return this.$isMobile();
    }
  },
  watch: {
    // studentId: {
    //   immediate: false,
    //   handler(val,oldVal){
    //     if(!val || oldVal == val) return
    //     this.initial()
    //     this.getStudentApply()
    //   },
    // },
    collapseActive: {
      deep: true,
      handler(newVal){
        // 新打开手风琴
        if(newVal.length === 0){
          this.navActive = '';
        } 
        if(newVal.length === 1){
          this.navActive = newVal[0]
        }
      }
    },
    navActive(newVal){
      if(!newVal) return;
      if(this.collapseActive.length === 1 && this.collapseActive[0] === newVal) return;
      this.collapseActive= [newVal];
    },
  },
  created(){
    this.initial()
  },
  async mounted() {
    if(!window) return;
    const { data } = await getAppCountry(); // 获取国家选项
    this.countryOptions = data.datas;
    await this.getStudentApply();
    this.navTitleList = [
      this.t('mso.personalInfo'),
      this.t('mso.schoolApply'),
      this.t('mso.academicBackground'),
      this.t('mso.recommend'),
      this.t('mso.studentUpload'),
      this.t('mso.arrangements'),
    ]
    this.initLoading = false;
    this.$emit('loadingEnd')
  },
  methods:{
    initial(){
      if(requestId) {
        service.interceptors.request.eject(requestId)
      }
      requestId = service.interceptors.request.use(this.initRequest,
        (error) => {
          Promise.reject(error);
        }
      );
      if (this.interceptorsRequest) {
        propRequestId && service.interceptors.request.eject(propRequestId)
        propRequestId = service.interceptors.request.use(this.interceptorsRequest);
      }
      if (this.interceptorsResponse) {
        propRespondId && service.interceptors.response.eject(propRespondId)
        propRespondId = service.interceptors.response.use(this.interceptorsResponse);
      }
      
    },
    initRequest(config){
      config.headers.fkPlatformType = this.fkPlatformType;
      config.headers.publicLevel = this.publicLevel;
      config.headers.langCode = this.lang;
      config.headers.at = this.at;
      config.headers.Authorization = this.authorization;
      return config;
    },
    // 初始化 获取学生的申请信息
    async getStudentApply() {
      if (!this.studentId) return;
      let res = await getStudentApply(this.studentId);
      if (res.data.data) {
        const {
          accommodationDto,               // 后续安排
          studentParentsDto,              // 父母联系方式
          studentInfoDto,                 // 学生信息
          studentCountryItemDto,          // 目标国家
          studentEducationDtos,           // 教育背景
          studentInstitutionCourseDtos,   // 院校申请
          studentQualificationDtos,       // 专业资格
          studentReferenceDtos,           // 推荐人
          studentScoreDtos,               // 考试成绩
          studentWorkDtos,                // 工作经验
        } = res.data.data;
        if (
          studentInstitutionCourseDtos &&
          studentInstitutionCourseDtos.length
        ) {
          let i = 0;
          // 根据选择的学校加载配置
          for await (let item of studentInstitutionCourseDtos) {
            item.uniqueKey = ++i;
            await this.getConfigByApply({
              institutionId: item.fkInstitutionId,
              institutionName: item.fkInstitutionName,
              countryId: item.fkAreaCountryId,
              majorLevelId: item.fkMajorLevelId,
              studentCourseId: item.id,
              courseName: item.fkInstitutionCourseName || item.institutionCourseName,
              isAddApp: item.isAddApp || false
            });
          }
          this.studentFileUploadList = this.needUploadFileList;
          this.setApplyInsititionTree(studentInstitutionCourseDtos)
        }
        if(accommodationDto) {
          this.proccess6_accommondation = 100;
        }
        if(studentParentsDto.fatherPhoneNumber){
          this.proccess1_parent = 100;
        }
        if(studentInfoDto.id ){
          this.proccess1_studentInfo = 100;
        }
        if(studentEducationDtos && studentEducationDtos.length) {
          this.proccess3_enducation = 100;
        }
        if(studentQualificationDtos && studentQualificationDtos.length) {
          this.proccess3_skill = 100;
        }
        if(studentReferenceDtos && studentReferenceDtos.length) {
          this.proccess4_reference = 100;
        }
        if(studentScoreDtos && studentScoreDtos.length) {
          this.proccess3_score = 100;
        }
        if(studentWorkDtos && studentWorkDtos.length){
          this.proccess3_work = 100;
        }
        if(studentInstitutionCourseDtos && studentInstitutionCourseDtos.length) {
          this.proccess2_isStudentApplySchool = 100;
          this.proccess2_background = 100;
        }
        this.formStatus = studentInfoDto.status;
        this.stufullName = {
          lastName: studentInfoDto.lastName,
          firstName: studentInfoDto.firstName
        }
        
        this.stuBirthday = studentInfoDto.birthday;

        // 给子组件赋值
        setTimeout(()=>{
          this.$refs.personalInfo.setInitData(studentInfoDto);
          this.$refs.applicationSch.setInitData(studentInstitutionCourseDtos)
          this.$refs.applicationSch.getCountryItemDto(studentCountryItemDto)
          this.$refs.education && this.$refs.education.setInitData(studentEducationDtos, 1)
          this.$refs.qualifications && this.$refs.qualifications.setInitData(studentQualificationDtos)
          this.$refs.workExperience && this.$refs.workExperience.setInitData(studentWorkDtos)
          this.$refs.language && this.$refs.language.setInitData(studentScoreDtos)
          this.$refs.recommend && this.$refs.recommend.setInitData(studentReferenceDtos)
          this.$refs.arrangements && this.$refs.arrangements.setInitData(accommodationDto)
          this.$refs.relativesContact && this.$refs.relativesContact.setInitData(studentParentsDto);
          this.showBackgroundInfo = studentInstitutionCourseDtos.length !== 0;
          this.$refs.backgroundInfo.setInitData(studentCountryItemDto)
        })
      } 
    },
    /**
     * 根据申请学校获取配置
     *  @param {obj.key} 
     *  @param {obj.institutionId} 
     *  @param {obj.institutionName} 
     *  @param {obj.countryId} 
     *  @param {obj.majorLevelId} 
     *  @param {obj.studentCourseId} 
     *  @param {obj.courseName} 
     *  @param {obj.isAddApp} 当前课程是否加申 
     */
    async getConfigByApply({
      institutionId, 
      countryId, 
      majorLevelId, 
      studentCourseId, 
      courseName, 
      institutionName,
      isAddApp
    }) {
      if(!studentCourseId) return;
      const ret = await getFromConfig(countryId, majorLevelId, institutionId);
      const data = ret.data.data;
      const subNavList = data? data.appFormDivisionDtos: [];
      const needUploadFileList = data? data.attachmentDtos: [];
      const publicFile = [];
      const privateFile = [];
      // 分为公共文件和学校需要单独上传的文件
      if(needUploadFileList && needUploadFileList.length){
        for (let item of needUploadFileList){
          item.studentCourseId = studentCourseId;
          item.isAddApp = isAddApp;
          if(item.isMultipleRequired){  //需单独上传
            privateFile.push(item);
            item.courseName = courseName;
          } else {
            publicFile.push(item);
          }
        }
        // 新增私有的学校上传文件
        if(privateFile.length) {
          // 查找有没有相同的学校
          let institutionItem = this.needUploadFileList.find(item=>item.id == institutionId)
          // 如果没有相同的学校
          if(!institutionItem) {
            institutionItem = {
              name: institutionName,
              id: institutionId,
              list: privateFile,
            }
            this.needUploadFileList.push(institutionItem);
          } else {
            // 如果有，先过滤掉已有的studentCourseId的在新增
            const newList = institutionItem.list
              .filter(jtem=>jtem.studentCourseId != studentCourseId)
              .concat(privateFile)
            institutionItem.list = newList;
          }
        }
        // 新增公共的上传文件
        let pubLng = publicFile.length
        if(pubLng) {
          // 判断是否有新的公共文件
          const file = publicFile.filter(item=>{
            // 判断是否已有此文件id
            const index = this.needUploadFileList[0].list.findIndex(jtem => jtem.id == item.id);
            // 如果有此文件id
            if( index >= 0 ){
              this.needUploadFileList[0].list[index].isAddApp = 1;
              // 判断是否需要加StudentId
              const needAdd = this.needUploadFileList[0].list[index].studentCourseId.toString().split(',').indexOf(studentCourseId+'') >= 0;
              !needAdd && (this.needUploadFileList[0].list[index].studentCourseId += `,${studentCourseId}`)
            }
            return index < 0;
          })
          // 如果有新的公共文件
          if(file.length){
            this.needUploadFileList[0].list.push(...file)
          }
        }
      }

      // 侧边导航栏
      if((!subNavList || !subNavList.length) && this.activeCategory[studentCourseId]) {
        this.$delete(this.activeCategory, studentCourseId)
        return;
      }

      if (this.activeCategory[studentCourseId] ) {
        this.activeCategory[studentCourseId] = subNavList;
      } else {
        this.$set(this.activeCategory, studentCourseId, subNavList);
      }
    },
    onStudentUpdate({stuName, birthday, ret}){
      this.stufullName = stuName;
      this.stuBirthday = birthday;
      if (!this.studentId) { // 如果原来的学生id是空
        this.$emit("update:studentId", ret);
        window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({
          type: 'register',
          studentId: ret
        }))
      }
    },
    async updateConfigByAdd(params){
      // 过滤一遍，如果studentCourseId已有，需要删除
      let index = this.needUploadFileList.length - 1;
      while(this.needUploadFileList.length && index > 0){
        if(!index) break;
        const item = this.needUploadFileList[index]
        const j = item.list.findIndex(item=>item.studentCourseId == params.studentCourseId)
        if(j >= 0) {
          if (item.list.length == 1){
            this.needUploadFileList.splice(index, 1)
          } else {
            item.list.splice(j, 1)
          }
        }
        index--;
      }

      await this.getConfigByApply(params);
      this.studentFileUploadList = this.needUploadFileList;
    },
    // 删除配置，学校课程的资料上传
    delConfigByApply(key) {
      if (this.activeCategory[key]) {
        this.$delete(this.activeCategory, key);
        this.studentFileUploadList = this.studentFileUploadList.filter(item=>{
          const newList = item.list.filter(jtem => {
            const studentCourseId = jtem.studentCourseId.toString().split(',')
            const index = studentCourseId.indexOf(key+'') ;
            if(index >= 0){
              studentCourseId.splice(index, 1);
              jtem.studentCourseId = studentCourseId.join(',')
              return jtem.studentCourseId.length > 0
            }
            return true
          })
          if(newList.length > 0) {
            item.list = newList;
            return true
          } else {
            return false;
          }
        })
        this.needUploadFileList = this.studentFileUploadList;
        // this.$refs['fileData'] && this.$refs['fileData'].length && this.$refs['fileData'][0].deleteFileByStudentCourseId(key);
      }
    },
    // 获取目的国家
    getDestinationCountry({
      type,
      fkAreaCountryId,
      fkAreaCountryname,
      applyLength,
    }) {
      this.showBackgroundInfo = Boolean(applyLength);
      if (type == "add") {
        this.$refs.backgroundInfo.aimCountryAdd(
          fkAreaCountryId,
          fkAreaCountryname
        );
      }
      if (type == "delete") {
        this.$refs.backgroundInfo.deleteDestCtry(fkAreaCountryId);
      }
    },
    // 更新目的国家
    updateDestinationCountry({oldId, newId, newCountryname}) {
      this.$refs.backgroundInfo.updateDestCtry({oldId, newId, newCountryname});
    },
    onClickNavItem(key) {
      this.activeNames = [key];
      this.$emit('sub-menu-click',key);
    },
    // 提交申请
    async handleApplyInfo() {
      for(let item of this.filterNavList){
        // 如果是数组
        if (item.constructor.toString().indexOf('Array') >= 0){
          for (let jtem of item) {
            if (!(await this.checkSingleFormByName(jtem))) return;
          }
        } else {
          if (!(await this.checkSingleFormByName(item))) return;
        }
      }
      this.dialogVisible = true;
    },
    // 检查表单
    async checkSingleFormByName(name){
      if(!name) return;
      let ret = true;
      const subCateItem = this.subCateList[name];
      // 如果是必填
      if(name == 'personalInfo' || name == 'applicationSch' || (subCateItem && subCateItem.isRequired)){
        // 如果检查不通过
        if(this.$refs[name].checkFormStatus){
          const {error, isEdit, validate} = await this.$refs[name].checkFormStatus()
          if(!isEdit || !validate){
            this.$alrt(error, this.t("common.tip"), {confirmButtonText: this.t("common.confirm")})
            ret = false;
          }
        }
      }
      return ret;
    },
    handleSaveForm(){
      this.dialogVisible = false;
      this.formStatus = 1;
      this.$msge({
        message: this.t("common.saveSucc"),
        type: "success",
        duration: 2000,
        offset: 120,
        onClose: () => {
          this.$emit('submit-form-success')
        },
      });
    },
    // 手风琴收缩所有除了参数
    shrinkAllExcept(key){
      this.navActive = key;
      this.collapseActive = [key];
    },
    setApplyInsititionTree(list) {
      let institution = {};
      for(let item of list){
        if(institution[item.fkInstitutionId]){
          institution[item.fkInstitutionId].courses.push({
            name: item.fkInstitutionCourseName || item.institutionCourseName,
            fkInstitutionCourseId: item.fkInstitutionCourseId,
            id: item.id
          })
        } else {
          institution[item.fkInstitutionId] = {
            name: item.fkInstitutionName ,
            courses: [{
              name: item.fkInstitutionCourseName || item.institutionCourseName,
              fkInstitutionCourseId: item.fkInstitutionCourseId,
              id: item.id,
            }]
          }
        }
      }
      this.studentApplyInstitutionTree = institution;
    },
    onLetterChange(letters){
      if(!letters.length) {
        return this.letters = [];
      }
      this.letters = JSON.parse(JSON.stringify(letters)).sort((a,b)=> b.id-a.id );
    }
  },
  
}
</script>
<style lang="scss">
.mso-student-form{
  &.stu-all-form-info{
    background-color: transparent;
    .title-button-comp{
      .main-title{
        padding-left: 12px;
        font-size: 16px;
        font-weight: normal;
      }
    }
  }
  .forms-box{
    overflow: visible;
    .fixed-top{
      position: sticky;
      top: 0;
      z-index: 12;
      background: #fff;
    }
  }
  .mso-collapse{
    padding-left:22px;
    border-bottom: 0;
  }
}
@media screen and(max-width: 768px){
  .mso-student-form{
    &.stu-all-form-info{
      .title-button-comp .main-title{
        font-size: .28rem;
      }
      .nav-comp .el-button{
        display: none;
      }
    }
    .forms-box {
      padding: 0;
      padding-top: 1.2rem;
    }
    .mso-collapse{
      padding-left: 0;
    }
  }
}
</style>