<template>
  <el-upload
  action=""
  :show-file-list="false"
  :before-upload="beforeUpload"
  :http-request="handleUploadFile"
  :on-success="handleUploadSuccess"
  :style="{ backgroundImage: `url(${imgUrl})` }"
  >
    <div class="upload-header" slot="trigger">
      <div class="icon">
        <BlueBackground v-if="name === 'blue-background'"></BlueBackground>
        <GreenPassport v-if="name === 'green-passport'"></GreenPassport>
        <OrangeIdcard v-if="name === 'orange-idcard'"></OrangeIdcard>
      </div>
      <div class="text">{{ t(text) }}</div>
    </div>
  </el-upload>
</template>

<script>
import i18n from "@/locale/index";
import BlueBackground from '/packages/components/svg-icon/blueBackground';
import GreenPassport from '/packages/components/svg-icon/greenPassport';
import OrangeIdcard from '/packages/components/svg-icon/orangeIdcard';
const fileTypes = [
  'image/jpeg', 'image/png', 'application/pdf', 'image/bmp', 'image/gif', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword'
];

export default {
  name: 'uploadHeader',
  components: {
    BlueBackground,
    GreenPassport,
    OrangeIdcard
  },
  props: {
    imgUrl: String,
    name: String,
    text: String 
  },
  mixins: [i18n],
  data: () => {
    return {
    }
  },
  methods: {
    beforeUpload(file) {
      const type = file.type.toLowerCase();
      if (!fileTypes.includes(type)) {
        this.$msge.error('不支持该文件的格式，请重新上传符合格式要求的文件！');
        return false;
      } else if (file.size / 1024 / 1024 > 5) {
        this.$msge.error('文件大小不超过5M，请您重新上传符合要求的文件！');
        return false;
      }
      return true;
    },
    handleUploadFile(params) {
      const file = params.file;
      this.$emit('file-upload', file);
    },
    handleUploadSuccess(uploadFile) {
      this.$emit('click-header');
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-header {
  display: flex;
  height: 90px;
  width: 230px;
  height: 90px;
  border-radius: 4px;
  cursor: pointer;
  justify-content: space-evenly;
  align-items: center;
  padding: 0 5px;
  .text {
    font-size: 16px;
    color: #ffffff;
  }
  .icon {
    .svg-icon {
      fill: #ffffff;
    }
  }
}
</style>