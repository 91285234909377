<template>
  <div class="stu-background-info">
    <!-- 目的国家 -->
    <el-form
      v-for="(country, index) in stuBackInfoFormData.studentCountryList"
      :key="country.id"
      :class="['stu-background-form no-border', (!isEditing(country.id) || hideSubmitBtn()) && 'disabled']"
      ref="stuBackgroundForm"
      :rules="stuBackgroundRules"
      :model="stuBackInfoFormData"
      label-position="top"
      size="small"
    >
      <div class="destCtry-block sub-option-area">
        <el-form-item
          :prop="'studentCountryList.' + index + '.isStudyInThisCountry'"
          :rules="rules.needFieldRadio"
        >
          <div slot="label" class="country-label" >
            <div class="label-wrap">
              <span>{{ t("backgroundInfo.isStudyInThisCountry") }}</span>
              <span class="row-title text-notice-red">
                {{ country.countryName }}
              </span>
            </div>
            <owl-btn-set 
              v-if="!hideSubmitBtn()"
              :updateShow="!isEditing(country.id)" 
              @update="setUpdateCountry(country.id, country)"
            ></owl-btn-set>
          </div>
          <owl-radio
            :disabled="!isEditing(country.id)"
            v-model="country.isStudyInThisCountry"
            :options="isRequiredOption"
            :option-config="{ label: 'name', value: 'id' }"
          >
          </owl-radio>
        </el-form-item>
        <template v-if="country.isStudyInThisCountry == 1">
          <div class="form-tip" v-show="isEditing(country.id)"> {{ t("backgroundInfo.updatedVisa") }} </div>
          <el-row :gutter="10">
            <el-col :span="24" :xs="24">
              <el-form-item
                :prop="'studentCountryList.' + index + '.visaIssueDate'"
                :label="t('backgroundInfo.visaIssueDate')"
                :rules="{
                  required: true,
                  message: t('validate.requiredField'),
                  trigger: 'change',
                }"
              >
                <owl-input
                  :disabled="!isEditing(country.id)"
                  v-model="country.visaIssueDate"
                  type="date"
                  @change="handleVisaIssueDate(country, index)"
                >
                </owl-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" :xs="24">
              <el-form-item
                :prop="'studentCountryList.' + index + '.visaType'"
                :label="t('backgroundInfo.visaType')"
                :rules="rules.needFiledRule"
              >
                <owl-input
                  :disabled="!isEditing(country.id)"
                  v-model="country.visaType"
                >
                </owl-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" :xs="24">
              <el-form-item
                :prop="'studentCountryList.' + index + '.visaExpiryDate'"
                :label="t('backgroundInfo.visaExpiryDate')"
                :rules="rules.needFiledRule"
              >
                <owl-input
                  :disabled="!isEditing(country.id)"
                  v-model="country.visaExpiryDate"
                  type="date"
                  @change="handleVisaExpried(country, index)"
                >
                </owl-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" :xs="24">
              <el-form-item
                :prop="'studentCountryList.' + index + '.studyLength'"
                :label="t('backgroundInfo.studyLength')"
                :rules="rules.needFiledRule"
              >
                <owl-input
                  type="number"
                  :disabled="!isEditing(country.id)"
                  v-model="country.studyLength"
                >
                </owl-input>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
        <owl-btn-save 
          v-show="isEditing(country.id)" 
          @save="handleSaveItem(index)"
          show-delete
          @delete="cancelAimCountry(index, country.id)"
        />
      </div>
    </el-form>

    <el-form 
      v-if="stuBackInfoFormData.studentCountryList && stuBackInfoFormData.studentCountryList.length"
      :class="['stu-background-form no-border', isDisabled && 'disabled']"
      :model="stuBackInfoFormData"
      :rules="stuBackgroundRules"
      label-position="top"
      size="small"
      ref="stuReligionForm"
    >
      <!-- 宗教信仰等等 -->
      <div class="sub-option-area form-item" style="padding-top: 12px">
        <owl-btn-set
          v-if="!hideSubmitBtn()"
          style="margin-bottom: 15px; float: right"
          :updateShow="isDisabled && stuBackInfoFormData.studentCountryList.length > 0"
          @update="updateReligionsInfo"
        ></owl-btn-set>
        <el-row :gutter="10">
          <el-col :span="24" :xs="24">
            <el-form-item
              :label="t('backgroundInfo.isHaveReligion')"
              prop="isHaveReligion"
              :rules="rules.needFieldRadio"
            >
              <owl-radio
                :disabled="isDisabled"
                v-model="stuBackInfoFormData.isHaveReligion"
                :options="isHaveReligionOption"
                :option-config="{ label: 'name', value: 'id' }"
              >
              </owl-radio>
            </el-form-item>
          </el-col>
          <el-col :span="24" :xs="24" v-if="stuBackInfoFormData.isHaveReligion === 1" >
            <el-form-item
              :label="t('backgroundInfo.religionNote')"
              prop="religionNote"
              :rules="rules.needFiledRule"
            >
              <owl-input
                type="textarea"
                :rows="3"
                v-model="stuBackInfoFormData.religionNote"
                :disabled="isDisabled"
              ></owl-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" :xs="24">
            <el-form-item
              :label="t('backgroundInfo.isVisaRefused')"
              prop="isVisaRefused"
              :rules="rules.needFieldRadio"
            >
              <owl-radio
                :disabled="isDisabled"
                v-model="stuBackInfoFormData.isVisaRefused"
                :options="isRequiredOption"
                :option-config="{ label: 'name', value: 'id' }"
              >
              </owl-radio>
            </el-form-item>
          </el-col>
          <el-col :span="24" :xs="24" v-if="stuBackInfoFormData.isVisaRefused === 1" >
            <el-form-item
              :label="t('backgroundInfo.visaRefusedNote')"
              prop="visaRefusedNote"
              :rules="rules.needFiledRule"
            >
              <owl-input
                type="textarea"
                :rows="3"
                v-model="stuBackInfoFormData.visaRefusedNote"
                :disabled="isDisabled"
              ></owl-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" :xs="24">
            <el-form-item
              :label="t('backgroundInfo.isDisability')"
              prop="isDisability"
              :rules="rules.needFieldRadio"
            >
              <owl-radio
                :disabled="isDisabled"
                v-model="stuBackInfoFormData.isDisability"
                :options="isRequiredOption"
                :option-config="{ label: 'name', value: 'id' }"
              >
              </owl-radio>
            </el-form-item>
          </el-col>
          <el-col :span="24" :xs="24" v-if="stuBackInfoFormData.isDisability == true" >
            <el-form-item
              :label="t('backgroundInfo.disabilityNote')"
              prop="disabilityNote"
              :rules="rules.needFiledRule"
            >
              <owl-input
                type="textarea"
                :rows="3"
                v-model="stuBackInfoFormData.disabilityNote"
                :disabled="isDisabled"
              ></owl-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" :xs="24">
            <el-form-item
              :label="t('backgroundInfo.isEnglishMotherLanguage')"
              prop="isEnglishMotherLanguage"
              :rules="rules.needFieldRadio"
            >
              <owl-radio
                :disabled="isDisabled"
                v-model="stuBackInfoFormData.isEnglishMotherLanguage"
                :options="isRequiredOption"
                :option-config="{ label: 'name', value: 'id' }"
              >
              </owl-radio>
            </el-form-item>
          </el-col>
          <el-col :span="24" :xs="24">
            <el-form-item
              :label="t('backgroundInfo.isStudyInEnglish')"
              prop="isStudyInEnglish"
              :rules="rules.needFieldRadio"
            >
              <owl-radio
                :disabled="isDisabled"
                v-model="stuBackInfoFormData.isStudyInEnglish"
                :options="isRequiredOption"
                :option-config="{ label: 'name', value: 'id' }"
              >
              </owl-radio>
            </el-form-item>
          </el-col>
          <el-col :span="24" :xs="24">
            <el-form-item prop="whoPay" :rules="rules.needFieldRadio">
              <div slot="label" style="display: inline-block">
                <span>{{ t("backgroundInfo.whoPay") }}</span>
                <span v-if="!isDisabled" class="text-notice-red">
                  {{ t("backgroundInfo.whoPayTips") }}
                </span>
              </div>
              <owl-radio
                :disabled="isDisabled"
                v-model="stuBackInfoFormData.whoPay"
                :options="whoPayOption"
                :option-config="{ label: 'name', value: 'id' }"
              >
              </owl-radio>
            </el-form-item>
          </el-col>
          <el-col :span="24" :xs="24" v-if="stuBackInfoFormData.whoPay === 1">
            <el-form-item
              prop="payNote"
              :label="t('backgroundInfo.payNote')"
              :rules="rules.needFiledRule"
            >
              <owl-input
                type="textarea"
                :rows="2"
                v-model="stuBackInfoFormData.payNote"
                :disabled="isDisabled"
              ></owl-input>
            </el-form-item>
          </el-col>
        </el-row>
        <owl-btn-save v-show="!isDisabled" @save="handleSaveReligions" show-delete @delete="cancelReligionsInfo" />
      </div>
    </el-form>
  </div>
</template>
<script>
import i18n from "@/locale/index";
import mixin from "@/utils/mixin";
import OwlInput from "../../components/OwlInput.vue";
import OwlRadio from "../../components/OwlRadio.vue";
import OwlBtnSave from "/packages/components/OwlBtnSave.vue";
import OwlBtnSet from "/packages/components/OwlBtnSet.vue";
import {
  saveOrUpdateTagerCountry,
  deleteCountryToIssue,
} from "@/api/common.js";
export default {
  name: "StudentBackgroundInfo",
  mixins: [mixin, i18n],
  inject: ["stuid", "hideSubmitBtn","platformType"],
  components: {
    OwlInput,
    OwlRadio,
    OwlBtnSave,
    OwlBtnSet,
  },
  props: {
    studentCountryItemDto: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    isDisabled(){
      return this.hideSubmitBtn() || !this.isEditing('religions')
    },
  },
  data() {
    return {
      fkPlatformType:this.platformType(),
      destCtryOptions: [],
      isRequiredOption: [],
      isHaveReligionOption: [],
      whoPayOption: [],
      stuBackInfoFormData: {
        studentCountryList: [
          // {
          //   fkAreaCountryId: "", // 申请国家id
          //   isStudyInThisCountry: 0, // 在目的国家学习过？0否/1是
          //   visaIssueDate: "", // 签证签发日期
          //   visaType: "", // 签证类型
          //   visaExpiryDate: "", // 签证过期日期
          //   studyLength: "", // 学习长度（整月数）
          // },
        ],
        isHaveReligion: 0, // 是否有特别的宗教信仰：0否/1是
        religionNote: "", // 宗教说明
        isVisaRefused: 0, // 是否有被拒签：0否/1是
        visaRefusedNote: "", // 拒签说明
        isDisability: 0, // 是否残疾或特殊需求：0否/1是
        disabilityNote: "", // 残疾说明
        isEnglishMotherLanguage: 0, // 是否英语为母语：0否/1是
        isStudyInEnglish: 0, // 是否接受过英语学习教育：0否/1是
        whoPay: 0, // 谁支付费用
        payNote: "", // 支付说明
        fkStudentId: this.stuid(),
      },
      stuBackgroundRules: {},
      isSaveReligions: false,
      isSaveCountry: false,
    };
  },
  mounted() {
    this.isRequiredOption = [
      { id: 0, name: this.t("common.no") },
      { id: 1, name: this.t("common.yes") },
    ];
    this.isHaveReligionOption = [
      { id: 0, name: this.t("common.hasNot") },
      { id: 1, name: this.t("common.has") },
    ];
    this.whoPayOption = [
      { id: 0, name: this.t("backgroundInfo.whoPay0") },
      { id: 1, name: this.t("backgroundInfo.whoPay1") },
    ];
  },
  methods: {
    async setInitData(newVal) {
      if (this.stuBackInfoFormData.studentCountryList.length) {
        return this.addCompletedStatus()
      };
      if(!newVal || !Object.keys(newVal).length) return;
      for (let key in newVal) {
        if(/(isDisability|isEnglishMotherLanguage|isHaveReligion|isStudyInEnglish|isVisaRefused|whoPay)/.test(key)){
          newVal[key] = newVal[key] ? 1: 0;
        }
      }
      const studentCountryItemDto = newVal; //JSON.parse(JSON.stringify(newVal));
      for (let key in this.stuBackInfoFormData) {
        if (key === "studentCountryList") {
          this.stuBackInfoFormData.studentCountryList = studentCountryItemDto.studentCountryDtos || []
        } else if (key === "fkStudentId"){
          this.stuBackInfoFormData.fkStudentId = this.stuid()
        } else {
          this.$set(this.stuBackInfoFormData, key, studentCountryItemDto[key]);
        }
      }
    },
     // 删除目的国家 （由课程申请决定是否删除）
    deleteDestCtry(fkAreaCountryId) {
      const studentCountryList = this.stuBackInfoFormData.studentCountryList
      let index = studentCountryList.findIndex(item => item.fkAreaCountryId == fkAreaCountryId);
      if(index < 0) return;
      const id = studentCountryList[index].id;
      deleteCountryToIssue(id, fkAreaCountryId, this.stuid()).then(() => {
        studentCountryList.splice(index,1)
        // 如果是编辑状态移除编辑状态
        if(this.isEditing(id)) this.switchEditStatus(id); 
        // 最后一个删除清空数据
        if(studentCountryList.length === 0) {
          for (let key in this.stuBackInfoFormData) {
            if(/(isDisability|isEnglishMotherLanguage|isHaveReligion|isStudyInEnglish|isVisaRefused|whoPay)/.test(key)){
              this.stuBackInfoFormData[key] = 0;
            }
            if(/(religionNote|visaRefusedNote|disabilityNote|payNote)/.test(key)) {
              this.stuBackInfoFormData[key] = "";
            }
          }
        }
        this.onFormStatusChanged()
      })
      .catch((e) => {
        this.$msge({
          message: e.message || this.t("common.deleteFail"),
          type: "error",
        });
      });
    },
    handleVisaIssueDate(country, index) {
      const dateStr = country.visaExpiryDate;
      const issueDate = new Date(country.visaIssueDate).getTime();
      const issueExpriedDate = new Date(dateStr).getTime();
      if (dateStr) {
        if (issueDate > issueExpriedDate) {
          this.$alrt(
            this.t("validate.issueDateValid", {
              issueDate: "(" + country.visaIssueDate + ")",
            }),
            "",
            {
              confirmButtonText: this.t("common.confirm"),
            }
          );
          this.stuBackInfoFormData.studentCountryList[index].visaIssueDate = "";
        }
      }
    },
    handleVisaExpried(country, index) {
      const dateStr = country.visaIssueDate;
      const issueExpriedDate = new Date(country.visaExpiryDate).getTime();
      const issueDate = new Date(dateStr).getTime();
      if (dateStr) {
        if (issueDate > issueExpriedDate) {
          this.$alrt(
            this.t("validate.issueDateValid", {
              expriDate: "(" + country.visaExpiryDate + ")",
            }),
            "",
            {
              confirmButtonText: this.t("common.confirm"),
            }
          );
          this.stuBackInfoFormData.studentCountryList[index].visaExpiryDate = "";
        }
      }
    },
    async checkFormStatus() {
      let validate = true;
      try {
        if(this.$refs.stuBackgroundForm) {
          for (let item of this.$refs.stuBackgroundForm){
            await item.validate()
          }
        }
        await this.$refs.stuReligionForm.validate(); // 如果没填抛出异常
      } catch {
        validate = false;
      }
      return {
        error: this.t('validate.backgroundInfo'),
        isEdit: this.editList.length === 0,
        validate,
      }
    },
    // 保存单个目标国家
    async handleSaveItem(index) {
      const sumitData = this.getUpdateAimCountryData(index)
      await this.handleUpdateAll(sumitData, 'saveCountry')
      this.switchEditStatus(sumitData.id);
      this.onFormStatusChanged()
    },
    // 给外部使用自动保存功能
    async autoSave(){
      if(!this.editList.length) return;
      const key = this.editList[0]
      if (key === 'religions'){
        await this.handleSaveReligions()
      } else {
        const index = this.stuBackInfoFormData.studentCountryList.findIndex(item=>item.id == key)
        if(index >= 0) await this.handleSaveItem(index)
      }
    },
    getUpdateAimCountryData(index){
      const studentCountryItem = this.stuBackInfoFormData.studentCountryList[index];
      const sumitData = {
        id: studentCountryItem.id,
        fkAreaCountryId: studentCountryItem.fkAreaCountryId,
        isStudyInThisCountry: studentCountryItem.isStudyInThisCountry,
        visaIssueDate: studentCountryItem.visaIssueDate,
        visaType: studentCountryItem.visaType,
        visaExpiryDate: studentCountryItem.visaExpiryDate,
        studyLength: studentCountryItem.studyLength,
        fkStudentId: this.stuid(),
      }
      // 如果用户没在申请国家
      if (!sumitData.isStudyInThisCountry) {
        sumitData.visaExpiryDate = "";
        sumitData.visaIssueDate = "";
        sumitData.studyLength = "";
        sumitData.visaType = "";
      }
      return sumitData;
    },
    // 保存全部 sumitData 如果有目标国家更新
    async handleUpdateAll(sumitData = null, type = null){
      let cleanSumitData = {};
      if(!sumitData){
        sumitData = this.getUpdateAimCountryData(0)
      }
      for (let key in this.stuBackInfoFormData){
        // 清理Radio选项关联项数据
        if(/(isDisability|isEnglishMotherLanguage|isHaveReligion|isStudyInEnglish|isVisaRefused|whoPay)/.test(key)){
          cleanSumitData[key] = this.stuBackInfoFormData[key] === 0 
              ? 0 : this.stuBackInfoFormData[key];
        }
        if(/(religionNote|visaRefusedNote|disabilityNote|payNote)/.test(key)) {
          cleanSumitData[key] = this.stuBackInfoFormData[key];
        }
      }
      cleanSumitData = Object.assign(cleanSumitData, sumitData);
      try {
        await saveOrUpdateTagerCountry(cleanSumitData);
        if (type === 'saveCountry') {
          this.isSaveCountry = true
        } else if (type === 'saveReligions') {
          this.isSaveReligions = true;
        }
        if (this.isSaveCountry && this.isSaveReligions) {
          this.addCompletedStatus();
        }
        this.$msge({
          message: this.t("common.saveSucc"),
          type: "success",
        });
      } catch {}
    },
    // 提供给外部方法 选完课程生成相应背景资料-目的国家
    aimCountryAdd(fkAreaCountryId, fkAreaCountryname){
      const studentCountryList = this.stuBackInfoFormData.studentCountryList
      // 国家去重
      if(studentCountryList.findIndex(item=> item.fkAreaCountryId == fkAreaCountryId ) > -1) return;
      const sumitData = {}
      for (let key in this.stuBackInfoFormData){
        if(key == "studentCountryList") continue;
        sumitData[key] = this.stuBackInfoFormData[key]
      }
      Object.assign(sumitData, {
        fkAreaCountryId,
        isStudyInThisCountry: 0,
        fkStudentId: this.stuid(),
      });
      saveOrUpdateTagerCountry(sumitData).then((res) => {
        const item = {
          fkStudentId: this.stuid(),
          fkAreaCountryId: fkAreaCountryId,
          isStudyInThisCountry: 0,
          visaIssueDate: "",
          visaType: "",
          visaExpiryDate: "",
          studyLength: "",
          id: res.data.data,
          countryName: fkAreaCountryname,
        }
        let lng = studentCountryList.unshift(item);
        // 自动帮用户保存背景资料，用户需要改动时，手动再调整
        this.addCompletedStatus();
        // if(!this.editList.length) {
        //   // 如果没有编辑状态，当前目标国家开启编辑状态
        //   this.switchEditStatus(res.data.data, item); 
        // }
        // this.stuBackInfoFormData.studentCountryList = studentCountryList;
        // if(lng == 1 && !this.hideSubmitBtn() && !this.isEditing('religions')) {
        //   // 如果只有一条，开启宗教信仰的编辑
        //   this.switchEditStatus('religions', this.religionsBackUpData())
        // }
        this.onFormStatusChanged()
      });
    },
    async updateDestCtry({oldId, newId, newCountryname}) {
      const studentCountryList = this.stuBackInfoFormData.studentCountryList
      const index = studentCountryList.findIndex((item) => item.fkAreaCountryId == oldId);
      const newIndex = studentCountryList.findIndex((item) => item.fkAreaCountryId == newId);
      if (index === -1) {
        return;
      }
      if (newIndex > -1) {
        this.deleteDestCtry(oldId);
        return;
      }
      const item = studentCountryList[index];
      const sumitData = {}
      for (let key in this.stuBackInfoFormData){
        if(key == "studentCountryList") continue;
        sumitData[key] = this.stuBackInfoFormData[key]
      }
      Object.assign(sumitData, {
        fkAreaCountryId: newId,
        id: item.id,
        isStudyInThisCountry: item.isStudyInThisCountry,
        fkStudentId: this.stuid(),
      });
      await saveOrUpdateTagerCountry(sumitData);
      item.fkAreaCountryId = newId;
      item.countryName = newCountryname;
      studentCountryList.splice(index, 1, item);
    },
    // 编辑目标国家
    setUpdateCountry(id, country){
      if(this.hasEdit()) return;
      this.switchEditStatus(id, country);
      this.onFormStatusChanged()
    },
    // 编辑宗教信仰
    updateReligionsInfo(){
      if(this.hasEdit()) return;
      this.switchEditStatus('religions', this.religionsBackUpData())
      this.onFormStatusChanged()
    },
    religionsBackUpData(){
      return {
        isHaveReligion: this.stuBackInfoFormData.isHaveReligion,
        religionNote: this.stuBackInfoFormData.religionNote,
        isVisaRefused: this.stuBackInfoFormData.isVisaRefused,
        visaRefusedNote: this.stuBackInfoFormData.visaRefusedNote,
        isDisability: this.stuBackInfoFormData.isDisability,
        disabilityNote: this.stuBackInfoFormData.disabilityNote,
        isEnglishMotherLanguage: this.stuBackInfoFormData.isEnglishMotherLanguage,
        isStudyInEnglish: this.stuBackInfoFormData.isStudyInEnglish,
        whoPay: this.stuBackInfoFormData.whoPay,
        payNote: this.stuBackInfoFormData.payNote
      }
    },
    // 取消宗教信仰等
    cancelReligionsInfo(){
      this.$confIssue(this.t("validate.cancelEdit"), "", {
        distinguishCancelAndClose: true,
        confirmButtonText: this.t("common.confirm"),
        cancelButtonText: this.t("common.cancel"),
      }).then(()=>{
        const backup = this.getEditBackupByKey('religions')
        this.$refs.stuReligionForm.clearValidate();
        this.switchEditStatus('religions')
        this.onFormStatusChanged()
        if(backup) {
          for (let key in backup){
            this.stuBackInfoFormData[key] = backup[key]
          }
        }
      })
    },
    // 保存宗教信息等
    async handleSaveReligions(){
      await this.handleUpdateAll(null, 'saveReligions'); // 修改保存
      this.switchEditStatus('religions')
      this.onFormStatusChanged()
    },
    // 取消目标国家编辑
    cancelAimCountry(index, id){
      this.$confIssue(this.t("validate.cancelEdit"), "", {
        distinguishCancelAndClose: true,
        confirmButtonText: this.t("common.confirm"),
        cancelButtonText: this.t("common.cancel"),
      }).then(async ()=>{
        const backup = this.getEditBackupByKey(id)
        this.$refs.stuBackgroundForm[index].clearValidate();
        this.switchEditStatus(id)
        if(backup) {
          this.stuBackInfoFormData.studentCountryList.splice(index, 1, backup)
        }
        this.onFormStatusChanged()
      })
    },
    onFormStatusChanged(){
      let lng = this.stuBackInfoFormData.studentCountryList.length + 1;
      let proccess = lng === 1? 0: Math.floor(((lng-this.editList.length) / lng) * 100)
      this.$emit('on-porccess-changed', proccess)
    }
  },
};
</script>
<style lang="scss" scoped>
.stu-background-info {
  .add-wrap {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    .select {
      margin-right: 12px;
    }
  }
  .btn-set-wrap {
    position: relative;

    .btn-set {
      position: absolute;
      z-index: 1;
      right: 5px;
    }
  }
  .form-save-btn-wrap{
    margin-bottom: 16px;
  }
  .country-label{
    display: inline-flex;
    width: calc(100% - 12px);
    align-items: flex-start;
    flex-wrap: wrap;
    &:first-child{
      margin-top: 12px;
    }
    .label-wrap{
      flex-grow: 1;
      width: 0;
      margin-right: 12px;
    }
  }

}
.country-select {
  font-size: 14px;
  text-align: left;
  margin-bottom: 10px;
}
</style>
