<template>
  <svg t="1637290241593" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2421">
    <path d="M234.666667 554.666667H128l85.333333-81.066667L345.6 341.333333l51.2 51.2-81.066667 81.066667H896V554.666667H234.666667z" p-id="2422"></path>
  </svg>
</template>
<script>
export default {
  name: "arrowIcon",
};
</script>
