<template>
  <div class="stu-lang-prof">
    <title-button-comp
      :mainTitle="t('languageInfo.examination')"
      :showMainTitle="showMainTitle"
      :buttonName="t('languageInfo.addExamType')"
      @handleAdd="handleAddExam"
      :loading="loading"
      :addShow="!hideSubmitBtn()"
    >
      <el-upload
        v-if="false && [2, 3, 4, 5, 6].includes(companyId()) && !hideSubmitBtn() && isBmsLogin()"
        :disabled="uploadLoading"
        action=""
        :show-file-list="false"
        :before-upload="beforeUpload"
        :http-request="handleUploadFile"
        >
        <el-button slot="trigger" type="primary" :loading="uploadLoading" class="submit-form">
          <span class="file-identify">{{ t("stuManagement.fileIdentificationForIELTS") }}</span>
        </el-button>
      </el-upload>
    </title-button-comp>
    <el-form
      v-for="(languageProf, index) in stuLangProfFormData.languageProfs"
      :key="languageProf.uniqueKey"
      :class="['language-prof-form', !isEditing(languageProf.uniqueKey) && 'disabled show-form']"
      ref="langProfForm"
      :model="stuLangProfFormData"
      label-position="top"
      size="small"
      :unique-key="languageProf.uniqueKey"
    >
      <div class="sub-title">
        <span>{{ languageProf.examTypeName }}</span>
        <owl-btn-set
          v-if="!hideSubmitBtn()"
          :updateShow="!isEditing(languageProf.uniqueKey)" 
          @update="updateExamType(languageProf)" 
          deleteShow 
          @delete="deleteExamType(languageProf, index)" />
      </div>
      <el-row :gutter="10">
        <el-col :span="24" :xs="24">
          <el-form-item 
            :label="t('languageInfo.examType')" 
            :prop="'languageProfs.' + index + '.examType'"
            :rules="[rules.needFiledRule]"
          >
            <owl-select
              :disabled="!isEditing(languageProf.uniqueKey)"
              v-model="languageProf.examType" 
              :options="examTypeOptions"
              @change="examTypeChange(languageProf, index)"
              searchKey="value"
              :option-config="{ label: 'label', value: 'key' }">
            </owl-select>
          </el-form-item>
        </el-col>
        <template v-if="languageProf.examType === 1 || showGmatType(languageProf.examType)">
          <template v-if="languageProf.examType === 1">
            <el-col :span="24" :xs="24">
              <el-form-item
                :label="t('languageInfo.isUkviIelts')"
                :prop="'languageProfs.' + index + '.isUkviIelts'"
                :rules="rules.numberSelectRule"
              >
                <owl-select
                  :disabled="!isEditing(languageProf.uniqueKey)"
                  v-model="languageProf.isUkviIelts"
                  :options="[
                    { label: t('common.no'), value: false },
                    { label: t('common.yes'), value: true },
                  ]"
                  :option-config="{ label: 'label', value: 'value' }"
                ></owl-select>
              </el-form-item>
            </el-col>
            <el-col :span="24" :xs="24" v-if="languageProf.isUkviIelts">
              <el-form-item
                :label="t('languageInfo.ukviNumber')"
                :prop="'languageProfs.' + index + '.ukviNumber'"
                :rules="[
                  rules.needFiledRule,
                  {
                    validator: checkRegstNo('UKVI'),
                    trigger: 'blur',
                  }]"
                >
                <owl-input
                  v-model="languageProf.ukviNumber"
                  :disabled="!isEditing(languageProf.uniqueKey)"
                ></owl-input>
              </el-form-item>
            </el-col>
          </template>
          <template v-if="showGmatType(languageProf.examType)">
            <el-col :span="24" :xs="24">
              <el-form-item :label="t('languageInfo.examinationLogin')">
                <owl-input
                  v-model="languageProf.examinationLogin"
                  :disabled="!isEditing(languageProf.uniqueKey)"
                ></owl-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" :xs="24">
              <el-form-item :label="t('languageInfo.examinationPin')">
                <owl-input
                  v-model="languageProf.examinationPin"
                  :disabled="!isEditing(languageProf.uniqueKey)"
                ></owl-input>
              </el-form-item>
            </el-col>
          </template>
        </template>
        <template v-if="showToeflType(languageProf.examType)">
          <el-col :span="24" :xs="24">
            <el-form-item
              :label="t('languageInfo.examListen')"
              :prop="'languageProfs.' + index + '.examListen'"
              :rules="rules.needFiledRule"
            >
              <owl-select
                :disabled="!isEditing(languageProf.uniqueKey)"
                v-model="languageProf.examListen"
                :options="getExamOptions(languageProf.examType)"
                :option-config="{ label: 'label', value: 'value' }"
                @change="(val) => handleExamLSRW(val,languageProf)"
              ></owl-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" :xs="24">
            <el-form-item
              :label="t('languageInfo.examRead')"
              :prop="'languageProfs.' + index + '.examRead'"
              :rules="rules.needFiledRule"
            >
              <owl-select
                :disabled="!isEditing(languageProf.uniqueKey)"
                v-model="languageProf.examRead"
                :options="getExamOptions(languageProf.examType)"
                :option-config="{ label: 'label', value: 'value' }"
                @change="(val) => handleExamLSRW(val,languageProf)"
              ></owl-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" :xs="24">
            <el-form-item
              :label="t('languageInfo.examWrite')"
              :prop="'languageProfs.' + index + '.examWrite'"
              :rules="rules.needFiledRule"
            >
              <owl-select
                :disabled="!isEditing(languageProf.uniqueKey)"
                v-model="languageProf.examWrite"
                :options="getExamOptions(languageProf.examType)"
                :option-config="{ label: 'label', value: 'value' }"
                @change="(val) => handleExamLSRW(val,languageProf)"
              ></owl-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" :xs="24">
            <el-form-item
              :label="t('languageInfo.examSpeak')"
              :prop="'languageProfs.' + index + '.examSpeak'"
              :rules="rules.needFiledRule"
            >
              <owl-select
                :disabled="!isEditing(languageProf.uniqueKey)"
                v-model="languageProf.examSpeak"
                :options="getExamOptions(languageProf.examType)"
                :option-config="{ label: 'label', value: 'value' }"
                @change="(val) => handleExamLSRW(val,languageProf)"
              ></owl-select>
            </el-form-item>
          </el-col>
        </template>
        <template v-if="showToeflType(languageProf.examType)">
          <el-col :span="24" :xs="24">
            <el-form-item :prop="'languageProfs.' + index + '.examTotal'" :label="t('languageInfo.examTotal')"
              :rules="[
                rules.needFiledRule,
                {
                  validator: checkExamTotal(),
                  trigger: 'blur',
                }]"
            >
              <owl-input
                :disabled="!isEditing(languageProf.uniqueKey)"
                v-model="languageProf.examTotal"
              ></owl-input>
              <!-- <div class="label" v-show="!isEditing(languageProf.uniqueKey)">
                {{ getOverall(languageProf) }}
              </div> -->
            </el-form-item>
          </el-col>
          <el-col :span="24" :xs="24">
            <el-form-item
              :prop="'languageProfs.' + index + '.examinationCheckAccount'"
              :rules="[
                rules.needFiledRuleInput,
                {
                  validator: checkRegstNo(languageProf.examType),
                  trigger: 'blur',
                },
              ]"
            >
              <div class="label-slot" slot="label">
                <span v-if="[1, 2, 4, 10].includes(languageProf.examType)">{{ t('languageInfo.registrationNo') }}</span>
                <span v-else>{{ t('languageInfo.examinationCheckAccount') }}</span>
              </div>
              <owl-input
                v-model="languageProf.examinationCheckAccount"
                :disabled="!isEditing(languageProf.uniqueKey)"
              ></owl-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" :xs="24">
            <el-form-item
              :label="t('languageInfo.testCenterNumber')"
              :prop="'languageProfs.' + index + '.testCenterNumber'"
              :rules="[
                rules.needFiledRule,
                {
                  validator: checkTestCenterNum(languageProf.examType),
                  trigger: 'blur',
                },
              ]"
            >
              <owl-input
                v-model="languageProf.testCenterNumber"
                :disabled="!isEditing(languageProf.uniqueKey)"
              ></owl-input>
            </el-form-item>
          </el-col>
        </template>
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('languageInfo.examinationDate')"
            :prop="'languageProfs.' + index + '.examinationDate'"
            :rules="rules.needFiledRule"
          >
            <owl-input
              v-model="languageProf.examinationDate"
              :disabled="!isEditing(languageProf.uniqueKey)"
              type="date"
              :placeholder="t('languageInfo.examinationDate')"
              @change="handleChangeExamDate(index)"
            ></owl-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24" v-if="showGGSAType(languageProf.examType)">
          <el-form-item
            :label="t('languageInfo.examScore')"
            :prop="'languageProfs.' + index + '.examTotal'"
            :rules="rules.needFiledRule"
          >
            <owl-input
              type="number"
              v-model="languageProf.examTotal"
              :disabled="!isEditing(languageProf.uniqueKey)"
            ></owl-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24" v-if="showToeflType(languageProf.examType) && languageProf.examType !== 2">
          <el-form-item
            :prop="'languageProfs.' + index + '.examinationNumber'"
            :rules="[
              rules.needFiledRule,
              {
                validator: checkExaminationNum(languageProf.examType),
                trigger: ['blur'],
              },
            ]"
          >
            <div class="label-slot" slot="label">
              <span v-if="languageProf.examType === 1">{{t('languageInfo.examinationNumber')}}</span>
              <span v-else>{{t('languageInfo.testTakerId')}}</span>
            </div>
            <owl-input
              v-model="languageProf.examinationNumber"
              :disabled="!isEditing(languageProf.uniqueKey)"
            ></owl-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24" v-if="languageProf.examType === 10 ||languageProf.examType === 4">
          <el-form-item
            :label="t('languageInfo.fkStudentAppCountryId')"
            :prop="'languageProfs.' + index + '.fkStudentAppCountryId'"
            :rules="rules.needFiledRule"
          >
            <owl-select
              :disabled="!isEditing(languageProf.uniqueKey)"
              v-model="languageProf.fkStudentAppCountryId"
              :options="countryOptions"
              :option-config="{ label: 'name', value: 'id' }"
            ></owl-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('languageInfo.isReexamination')"
            :prop="'languageProfs.' + index + '.isReexamination'"
            :rules="rules.needFieldRadio"
          >
            <owl-radio
              :disabled="!isEditing(languageProf.uniqueKey)"
              v-model="languageProf.isReexamination"
              :options="[
                { value: false, label: t('common.wantN') },
                { value: true, label: t('common.want') },
              ]"
              :option-config="{ label: 'label', value: 'value' }"
            />
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24" v-if="languageProf.isReexamination === true" >
          <el-form-item
            :label="t('languageInfo.reexaminationDate')"
            :prop="'languageProfs.' + index + '.reexaminationDate'"
            :rules="rules.needFiledRule"
          >
            <owl-input
              type="date"
              v-model="languageProf.reexaminationDate"
              :disabled="!isEditing(languageProf.uniqueKey)"
              :placeholder="t('languageInfo.reexaminationDate')"
            ></owl-input>
          </el-form-item>
        </el-col>
      </el-row>
      <owl-btn-save
        v-show="isEditing(languageProf.uniqueKey)"
        @save="handleSave(languageProf.uniqueKey, index)"
        show-delete
        @delete="cancelExamType(languageProf, index)"
      />
    </el-form>
    <div class="form-no-data-tip" v-if="!stuLangProfFormData.languageProfs.length">
      {{t('validate.noFormDataTip')}}
    </div>
  </div>
</template>
<script>
import i18n from "@/locale/index";
import mixin from "@/utils/mixin";
import localMixin from "../mixin";
import OwlSelect from "/packages/components/OwlSelect.vue";
import OwlInput from "/packages/components/OwlInput.vue";
import OwlRadio from "/packages/components/OwlRadio.vue";
import OwlBtnSave from "/packages/components/OwlBtnSave.vue";
import OwlBtnSet from "/packages/components/OwlBtnSet.vue";
import { saveOrUpdateStudentScore, deleteStudentScore, enCvParseApi, getScoreTypeEumByTypeApi, } from "@/api/common.js";
export default {
  name: "StudentLanguageProficiency",
  mixins: [mixin, i18n, localMixin],
  inject: ["stuid", "ctryOptions", "hideSubmitBtn", 'companyId', 'isBmsLogin',"platformType"],
  components: { OwlSelect, OwlInput, OwlRadio, OwlBtnSave, OwlBtnSet },
  props: {
    studentScoreDtos: {
      type: Array,
      default: () => [],
    },
    required: Boolean,
    showMainTitle: Boolean,
  },
  data() {
    return {
      fkPlatformType:this.platformType(),
      uploadLoading: false,
      loading: false,
      hasExamination: 0,
      examType: "",
      examTypeOptions: [],
      showDetailData: false,
      stuLangProfFormData: {
        languageProfs: [
          // {
          // fkStudentId: "", // 学生id
          // examTypeName: "",
          // examType: "", // 考试类型
          // examListen: "", // 听
          // examRead: "", // 读
          // examWrite: "", // 写
          // examSpeak: "", // 说
          // examTotal: "", // 总分
          // examinationCheckAccount: "", // 考试查询账户
          // examinationCheckPassword: "", // 考试查询密码
          // examinationDate: "", // 考试日期
          // examinationLogin: "", // 选择了GMAT,GRE考试：登录账号
          // examinationPin: "", // 选择了GMAT,GRE考试：登录密码
          // examinationNumber: "", // 考试号码
          // fkStudentAppCountryId: "", // 认证的国家（PTE）
          // testCenterNumber: "", // 测试中心号码
          // ukviNumber: "", // UKVI编号
          // isUkviIelts: "", // 是否UKVI IELTS：false否/ true是
          // isReexamination: "", // 是否想重考：0否/1是
          // reexaminationDate: "", // 重考日期
          // id: null,
          // },
        ],
      },
    };
  },
  mounted() {
    this.getScoreTypeEumByType();
  },
  computed:{
    countryOptions() {
      return this.ctryOptions();
    },
    getOverall(){
      return (exam) => {
        const listen = exam.examListen * 1;
        const read = exam.examRead * 1;
        const write = exam.examWrite * 1;
        const speak = exam.examSpeak * 1;
        if(exam.examType === 1){
          const total = (listen + speak + read + write) / 4;
          const totalInteger = parseInt(total);
          let totalRemainder = total - totalInteger;
          if (totalRemainder < 0.25) {
            totalRemainder = 0;
          } else if (totalRemainder >= 0.25 && totalRemainder < 0.75) {
            totalRemainder = 0.5;
          } else if (totalRemainder >= 0.75) {
            totalRemainder = 1;
          }
          exam.examTotal = totalInteger + totalRemainder;
        } else {
          exam.examTotal = listen + speak + read + write;
        }
        return exam.examTotal;
      }
    },
  },
  methods: {
    async getScoreTypeEumByType() {
      const { data } = await getScoreTypeEumByTypeApi(3);
      const options = [];
      data.datas.forEach(item => {
        if (item.key === 10) {
          options.push({
            label: 'University Test',
            value: item.value,
            key: item.key
          });
        } else {
          options.push({
            label: item.value,
            value: item.value,
            key: item.key
          });
        }
      })
      this.examTypeOptions = options;
    },
    beforeUpload(file) {
      const fileTypes = [
        'image/jpeg', 'image/png', 'application/pdf', 'image/bmp', 'image/gif'
      ];
      const type = file.type.toLowerCase();
      if (!fileTypes.includes(type)) {
        this.$msge.error('不支持该文件的格式，请重新上传符合格式要求的文件！');
        return false;
      }
      return true;
    },
    async handleUploadFile(params) {
      this.uploadLoading = true;
      const formData =  new FormData();
      const file = params.file;
      formData.append('file', file);
      formData.append('type', 'issueFileIeltsOrc');
      try {
        const { data } = await enCvParseApi(formData);
        this.uploadLoading = false;
        const resData = JSON.parse(data.data);
        if (resData.data.error_code) {
          return this.$msge.error(resData.data.error_msg);
        }
        if ((resData.result === 'success') && (Object.keys(resData.data).length) || (resData.status === 'success')) {
          const result = resData.data.kvsInfo;
          const IELTS_KEY = {
            'Listening': 'examListen',
            'Reading': 'examRead',
            'Speaking': 'examSpeak',
            'Writing': 'examWrite',
            'Overall Band Score': 'examTotal',
            'Date': 'examinationDate',
            'UKVI Number': 'ukviNumber',
            'Centre Number': 'testCenterNumber',
            'Candidate Number': 'examinationNumber',
            'Text Report Form Number': 'examinationCheckAccount'
          }
          const lanProf = {};
          result.forEach((elem) => {
            if (IELTS_KEY[elem.fieldName]) {
              lanProf[IELTS_KEY[elem.fieldName]] = elem.fieldWord;
            }
          });
          lanProf.examType = 1;
          if (lanProf.ukviNumber) {
            lanProf.isUkviIelts = true;
          } else {
            lanProf.isUkviIelts = false;
          }
          this.handleAddExam(lanProf);
        }
      } catch (err) {
        this.uploadLoading = false;
      }
    },
    handleExamLSRW(val, languageProf) {
      const l = Number(languageProf.examListen) ? Number(languageProf.examListen) : 0;
      const r = Number(languageProf.examRead)? Number(languageProf.examRead):0;
      const w = Number(languageProf.examWrite)? Number(languageProf.examWrite):0;
      const s = Number(languageProf.examSpeak)? Number(languageProf.examSpeak):0;
      if (languageProf.examType === 4) {
        languageProf.examTotal = Math.round((l + s + r + w) / 4)
      }
    },
    setInitData(newVal) {
      if(!newVal || !newVal.length) return;
      const studentScoreDtos = JSON.parse(JSON.stringify(newVal));
      this.$set(this.stuLangProfFormData, "languageProfs", studentScoreDtos.map((item,index)=>{
        item.uniqueKey = index+1;
        item.examType = Number(item.examType);
        return item;
      }));
    },
    handleChangeExamDate(index) {
      const date = this.stuLangProfFormData.languageProfs[index].examinationDate;
      const nowDate = new Date().getTime();
      const examDate = new Date(date).getTime();
      if (examDate > nowDate) {
        this.$alrt(
          this.t("validate.checkExamDate"),
          this.t("common.tip"),
          {
            confirmButtonText: this.t("common.confirm"),
            callback: () => {
              this.stuLangProfFormData.languageProfs[index].examinationDate = '';
            },
          }
        );
      }
    },
    // 新增
    handleAddExam(item) {
      if(this.hasEdit()) return;
      const uniqueKey = Date.now();
      let lanProf = {
        fkStudentId: this.stuid(),
        examTypeName: '',
        examType: "",
        examListen: "",
        examRead: "",
        examWrite: "",
        examSpeak: "",
        examTotal: "",
        examinationCheckAccount: "",
        examinationCheckPassword: "",
        examinationDate: "",
        examinationLogin: "",
        examinationPin: "",
        examinationNumber: "",
        fkStudentAppCountryId: "",
        testCenterNumber: "",
        ukviNumber: "",
        isUkviIelts: "",
        isReexamination: false,
        reexaminationDate: "",
        id: null,
        uniqueKey: uniqueKey,
      };
      if (item) {
        lanProf = Object.assign(lanProf, item);
      }
      this.stuLangProfFormData.languageProfs.unshift(lanProf);
      this.switchEditStatus(uniqueKey);
      this.onFormStatusChanged();
    },
    // 删除
    deleteExamType(languageProf, index) {
      if(this.loading) return;
      this.$confIssue(this.t("validate.delMsgLang"), "", {
        distinguishCancelAndClose: true,
        confirmButtonText: this.t("common.confirm"),
        cancelButtonText: this.t("common.cancel"),
      }).then(async () => {
        if (languageProf.id) {
          try {
            this.loading = true;
            await deleteStudentScore(languageProf.id)
            this.loading = false;
          } catch {
            this.loading = false;
            this.$msge({
              type: "error",
              message: this.t("common.deleteFail"),
            });
            return;
          }
        }
        this.stuLangProfFormData.languageProfs.splice(index, 1);
        if (!this.stuLangProfFormData.languageProfs.length) {
          this.addUncompletedStatus()
        }
        this.isEditing(languageProf.uniqueKey) && this.switchEditStatus(languageProf.uniqueKey)
        this.onFormStatusChanged();
        this.$msge({
          type: "success",
          message: this.t("common.deleteSucc"),
        });
      });
    },
    // 取消
    cancelExamType(exam, index) {
      this.$confIssue(this.t("validate.cancelEdit"), "", {
        distinguishCancelAndClose: true,
        confirmButtonText: this.t("common.confirm"),
        cancelButtonText: this.t("common.cancel"),
      }).then(()=>{
        const backup = this.getEditBackupByKey(exam.uniqueKey)
        this.switchEditStatus(exam.uniqueKey)
        this.onFormStatusChanged();
        if(backup) {
          this.$set(this.stuLangProfFormData.languageProfs, index, backup)
        } else {
          this.stuLangProfFormData.languageProfs.splice(index, 1)
        }
      })
    },
    getExamOptions(type) {
      let start, end, step;
      if (type === 1) {
        (start = 0), (end = 9), (step = 0.5);
      } else if(type === 2){
        (start = 0), (end = 30), (step = 1);
      } else {
        (start = 10), (end = 90), (step = 1);
      }
      let options = [];
      for (let i = end; i >= start; ) {
        options.push({ label: i, value: i });
        i -= step;
      }
      return options;
    },
    // 检查总分要大于0
    checkExamTotal(){
      const validRegstFun = (rule, value, callback) => {
        if (Number(value)>0) {
          callback();
        } else {
          callback(new Error(this.t("validate.examTotalTips")));
        }
      };
      return validRegstFun;
    },
    // 查询号码校验
    checkRegstNo(type) {
      const validRegstFun = (rule, value, callback) => {
        const len = value.trim().length;
        if (type === 2) {
          if (len < 16 || len > 19) {
            callback(new Error(this.t("validate.checkToeflRegstNo")));
          } else {
            callback();
          }
        } else if (type === 1) {
          if (len < 16 || len > 19) {
            callback(new Error(this.t("validate.checkIeltsRegstNo")));
          } else {
            callback();
          }
        } else if (type === 4 || type === 10) {
          if (len < 9 || len > 13) {
            callback(new Error(this.t("validate.checkPteRegstNo")));
          } else {
            callback();
          }
        } else if(type === "UKVI") {
          if (len < 19 || len > 26) {
            callback(new Error(this.t("validate.checkUKVI")));
          } else {
            callback();
          }
        } else {
          callback()
        }
      };
      return validRegstFun;
    },
    // 测试中心号码校验
    checkTestCenterNum(type) {
      const validTestCenter = (rule, value, callback) => {
        const len = value.trim().length;
        if (type === 2) {
          if (len < 9 || len > 12) {
            callback(new Error(this.t("validate.checkToeflTestCenterNo")));
          } else {
            callback();
          }
        } else if (type === 1) {
          if (len < 5 || len > 7) {
            callback(new Error(this.t("validate.checkIeltsTestCenterNo")));
          } else {
            callback();
          }
        } else if (type === 4 || type === 10) {
          if (len < 5 || len > 9) {
            callback(new Error(this.t("validate.checkPteTestCenterNo")));
          } else {
            callback();
          }
        }
      };
      return validTestCenter;
    },
    checkExaminationNum(type) {
      const validExamNum = (rule, value, callback) => {
        const len = value.trim().length;
        if (type === 1) {
          if (len < 5 || len > 20) {
            callback(new Error(this.t("validate.checkIeltsCandNo")));
          } else {
            callback();
          }
        } else if (type === 4 || type === 10) {
          if (len < 9 || len > 12) {
            callback(new Error(this.t("validate.checkPteTakerID")));
          } else {
            callback();
          }
        }
      };
      return validExamNum;
    },
    async checkFormStatus() {
      if(!this.required) return {isEdit: this.editList.length == 0, validate: true, error: this.t('validate.language')};
      return await this.formStatus();
    },
    async formStatus() {
      let validate = true;
      try {
        if(this.$refs.langProfForm) {
          for await(let item of this.$refs.langProfForm) {
            await item.validate();
          }
        }
      } catch {
        validate = false;
      }
      return {
        isEdit: this.editList.length == 0 && this.stuLangProfFormData.languageProfs.length > 0,
        validate,
        error: this.t('validate.language')
      }
    },
    // 编辑
    updateExamType(data){
      if(this.hasEdit()) return;
      this.switchEditStatus(data.uniqueKey, data)
      this.onFormStatusChanged();
    },
    // 保存
    async handleSave(uniqueKey, index){
      const {error, isEdit, validate} = await this.formStatus();
      if(!isEdit && !validate){
        this.$alrt(error, this.t("common.tip"), {confirmButtonText: this.t("common.confirm")})
        return false;
      }
      const item = this.stuLangProfFormData.languageProfs[index];
      item.examTotal = item.examTotal * 1
      try {
        const res = await saveOrUpdateStudentScore(item)
        if(res.data.code == '1003') {
          this.switchEditStatus(uniqueKey);
          this.onFormStatusChanged();
          this.$msge({
            message: this.t("common.saveSucc"),
            type: "success",
          });
          if (this.stuLangProfFormData.languageProfs.length === 1) {
            this.addCompletedStatus()
          }
          if(!item.id) item.id = res.data.data;
        }
      } catch {}
    },
    // 给外部使用自动保存功能
    async autoSave(){
      if(!this.editList.length) return;
      const key = this.editList[0]
      const index = this.stuLangProfFormData.languageProfs.findIndex(item=>item.uniqueKey == key)
      if(index >= 0) await this.handleSave(key, index)
    },
    onFormStatusChanged(){
      let lng = this.stuLangProfFormData.languageProfs.length
      let editing = this.editList.length;
      let proccess = 0;
      if(lng){
        proccess = Math.floor((lng - editing) / lng * 100);
      }
      this.$emit('on-porccess-changed', proccess)
    },
    examTypeChange(item, index){
      const examType = item.examType;
      const exam = this.examTypeOptions.some((item) => item.examType === examType);
      if (exam.key === 10) {
        item.examTypeName = 'University Test';
      } else {
        item.examTypeName = exam.value;
      }
      this.$refs.langProfForm[index].clearValidate();
      item.examListen = '';
      item.examRead = '';
      item.examWrite = '';
      item.examSpeak = '';
      item.examTotal = '';
      item.examinationCheckAccount = '';
      item.examinationCheckPassword = '';
      item.ukviNumber = '';
    }
  },
};
</script>
<style lang="scss" scoped>
.stu-lang-prof {
  text-align: center;
  .language-prof-form {
    width: 100%;
    text-align: left;
  }
  .label-slot {
    display: inline;
  }
}
.stu-lang-prof {
  .el-select {
    width: 100%;
  }
  .el-date-editor.el-input {
    width: 100%;
  }
}
</style>
