// import enLocale from "element-ui/lib/locale/lang/en";
// import zhLocale from "element-ui/lib/locale/lang/zh-CN";
// import twLocale from "element-ui/lib/locale/lang/zh-TW";

import enLang from "./lang/en";
import zhLang from "./lang/zh-CN";
import twLang from "./lang/zh-TW";

const messages = {
  "en-us": {
    ...enLang,
    // ...enLocale,
  },
  zh: {
    ...zhLang,
    // ...zhLocale,
  },
  "zh-hk": {
    ...twLang,
    // ...twLocale,
  },
};
export default {
  inject: {
    getLocale: {
      form: "getLocale",
      default: "zh",
    },
  },
  computed: {
    parentLang() {
      return this.lang || this.getLocale() || "zh";
    },
    t() {
      return (str, obj) => {
        let params = str.split('.')
        let langObj = {};
        let ret = '';
        try {
          switch (this.parentLang) {
            case "zh":
            case "zh-hk":
            case "en-us":
              langObj = messages[this.parentLang];
              break;
            default:
              langObj = messages['zh'];
              break;
          }
        } catch {
          langObj = messages['zh'];
        }
        while (params.length > 0){
          let str = params.shift();
          if(typeof langObj[str] === 'object') {
            langObj = langObj[str]
          } else {
            ret = langObj[str]
          }
        }
        if (typeof ret === "string") {
          ret = ret.replace(/\{(.*?)\}/g, function (match, capture) {
            capture = capture.trim();
            return obj ? obj[capture] || "" : "";
          });
        }
        return ret;
      };
    },
  },
};
