<template>
  <div>
    <template v-if="!disabled && options && options.length " >
      <el-select
        v-if="!isMobile"
        v-model="_value"  
        filterable 
        :filter-method="inputFilterWords"
        clearable
        :allow-create="allowCreate"
        :placeholder="placeholder || t('stuManagement.pleaseSelect')"
        @visible-change="onSwitchDrop"
      >
        <div :class="['owl-select-wrap', options.length<=40 && 'no-pagination']">
          <el-option 
            v-for="item in filterOptions"
            :label="item[optionConfig.label]"
            :key="item[optionConfig.value]"
            :value="item[optionConfig.value]"
          ></el-option>
          <div class="pagination-wrap">
            <i class="left-arrow" title="Previous page" @click="toPage(currentPage-1)"></i>
            <span @click="toPage(1)" :class="['number', currentPage == 1 && 'active']">1</span>
            <span class="number" v-show="currentPage>3">...</span>
            <span @click="toPage(currentPage-1)" class="number" v-show="currentPage>2">{{currentPage-1}}</span>
            <span class="number active" v-show="currentPage != 1 && currentPage != lastPage">{{currentPage}}</span>
            <span @click="toPage(currentPage+1)" class="number" v-show="currentPage + 1 < lastPage">{{currentPage+1}}</span>
            <span class="number" v-show="currentPage + 2 <lastPage">...</span>
            <span @click="toPage(lastPage)" :class="['number', currentPage == lastPage && 'active']" v-show="lastPage>1">{{lastPage}}</span>
            <i class="right-arrow" title="Next page" @click="toPage(currentPage+1)"></i>
          </div>
        </div>
      </el-select>
      <owl-mobile-select 
        v-else-if="isMobile && !isMobileCanSearch"
        v-model="_value"
        :placeholder="placeholder || t('stuManagement.pleaseSelect')"
        :options="options"
        :optionConfig="optionConfig"
      />
      <owl-mobile-select-page
        v-else-if="isMobile && isMobileCanSearch"
        v-model="_value"
        :placeholder="placeholder || t('stuManagement.pleaseSelect')"
        :options="options"
        :optionConfig="optionConfig"
      ></owl-mobile-select-page>
    </template>
    <el-input :disabled="isDisable" v-else-if="!disabled && (!options || !options.length)" v-model="_value"/>
    <div v-else-if="disabled" class="label" v-html="((options && options.length) ? label || _value  : _value)|| '&nbsp;'"></div>
  </div>
</template>
<script>
import i18n from "@/locale/index";
import OwlMobileSelect from './OwlMobileSelect.vue';
import OwlMobileSelectPage from './OwlMobileSelectPage.vue';
export default {
  components:{
    OwlMobileSelect,
    OwlMobileSelectPage,
  },
  mixins: [i18n],
  model:{
    prop: "value",
    event: "change"
  },
  props: {
    isDisable: {
      type: Boolean,
      default: false,
    },
    needOption: {
      type: Boolean
    },
    value: {
      type: [Number,String,Boolean]
    },
    searchKey: {
      default: 'label'
    },
    disabled: {
      type: Boolean
    },
    optionConfig: {
      type: Object,
      require: false,
      defalut: {
        label: "label",
        value: "value"
      }
    },
    options: {
      type: Array,
    },
    allowCreate: {
      type: Boolean,
      require: false,
      defalut: false
    },
    englishOnly:{
      type: Boolean,
      default: true,
      require: false,
    },
    placeholder: String,
    // 移动端显示pc下拉
    isMobileCanSearch: {
      type: Boolean,
      require: false,
      defalut: false
    }
  },
  data(){
    return {
      currentPage: 1,
      keywords: '',
      time: '',
      filterOptions: [],
      showOptions: [],
      lastPage: 1,
    }
  },
  watch:{
    options: {
      handler(newVal){
        if(!newVal || !newVal.length) return
        let index = newVal.findIndex(item=>item[this.optionConfig.value] == this.value);
        this.currentPage = index > 0? Math.ceil((index + 1) / 40) : 1;
        this.filterOptions = newVal.slice((this.currentPage-1)*40, this.currentPage*40)
        this.lastPage = Math.ceil(newVal.length / 40)
      },
      deep: true,
      immediate: true
    },
    value(newVal){
      if(!this.options || !this.options.length) return;
      let index = this.options.findIndex(item=>item[this.optionConfig.value] == newVal);
      let currentPage = index > 0? Math.ceil((index + 1) / 40) : 1;
      if(currentPage != this.currentPage){
        this.currentPage = currentPage;
        this.filterOptions = this.options.slice((this.currentPage-1)*40, this.currentPage*40)
        this.lastPage = Math.ceil(newVal.length / 40)
      }
    }
  },
  computed:{
    label(){
      if(!this.options || this.options.length == 0) return "";
      const item = this.options.find(item=> item[this.optionConfig.value] == this.value)
      return item ? item[this.optionConfig.label] : ""
    },
    _value:{
      get(){
        return this.value
      },
      set(val){
        if(this.englishOnly ){
          if (val.toString().match(/[\u4e00-\u9fa5]/)) {
            val = val.toString().replace(/[\u4e00-\u9fa5]/g, '');
            this.$msge.error(this.t('validate.onlyEnlishInput'));
          }
          if(val.toString().match(/[。，！？·【】—《》‘’“”、；：|]/)){
            val = val.toString().replace(/[。，！？·【】—《》‘’“”、；：|]/g, '');
            this.$msge.error(this.t('validate.pleaseEnPunctuation'));
          }
        }
        this.keywords = '';
        this.$emit("change", val);
        if (this.needOption) {
          const option = this.filterOptions.filter((item) => item[this.optionConfig.value] === val);
          this.$emit("getOption", option[0]);
        }
      }
    },
    isMobile(){
      return this.$isMobile();
    }
  },
  methods:{
    inputFilterWords(val){
      this.keywords = val;
      this.currentPage = 1;
      this.getFilterOptions();
    },
    getFilterOptions(){
      let list = [];
      if(!this.keywords){
        list = this.options;
      } else {
        list = this.options.filter(item => 
          item[this.optionConfig[this.searchKey]] && item[this.optionConfig[this.searchKey]].toLowerCase().indexOf(this.keywords.toLowerCase())>=0
        )
      }
      this.lastPage = Math.ceil(list.length / 40)
      this.filterOptions = list.slice((this.currentPage-1)*40, this.currentPage*40)
    },
    toPage(next){
      if(next < 1) {
        this.currentPage = 1;
      } else if (next > this.lastPage){
        this.currentPage = this.lastPage
      } else {
        this.currentPage = next;
      }
      this.getFilterOptions();
    },
    onSwitchDrop(){
      this.keywords = '';
      let index = this.options.findIndex(item=>item[this.optionConfig.value] == this._value);
      this.currentPage = index > 0? Math.ceil((index + 1) / 40) : 1;
      this.filterOptions = this.options.slice((this.currentPage-1)*40, this.currentPage*40)
      this.lastPage = Math.ceil(this.options.length / 40)
    }
  }
}
</script>

<style lang="scss" scoped>
.owl-select-wrap{
  padding-bottom: 36px;
  &.no-pagination{
    padding-bottom: 0;
    .pagination-wrap{
      display: none;
    }
  }
  .pagination-wrap{
    position: absolute;
    height: 34px;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #ffffff;
    display: flex;
    align-items: center;
    padding: 0 20px;
    justify-content: center;
    cursor: pointer;
  }
  .left-arrow{
    width: 10px;
    height: 10px;
    border: 2px solid #999;
    transform: rotate(-45deg);
    border-right: 0;
    border-bottom: 0;
    border-radius: 2px;
    transition: border-color .25s;
    margin-right: 18px;
    cursor: pointer;
    &:hover{
      border-color: #ff9700; 
      border-color: var(--form-color-primary);
    }
  }
  .right-arrow{
    border-radius: 2px;
    transition: border-color .25s;
    width: 10px;
    height: 10px;
    border: 2px solid #999;
    border-left:0;
    border-bottom: 0;
    transform: rotate(45deg);
    margin-left: 12px;
    &:hover{
      border-color: #ff9700; 
      border-color: var(--form-color-primary);
    }
    }
    .number{
    width: fit-content;
    height: 20px;
    // padding: 0 4px;
    margin-right: 6px;
    font-size: 14px;
    line-height: 1;
    text-align: center;
    color: #333;
    &.active{
      color: #ff9700; 
      color: var(--form-color-primary);
      font-weight: bold;
    }
    &:hover{
      color: #ff9700; 
      color: var(--form-color-primary);
    }
  }
}
</style>
