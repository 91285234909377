<template>
  <svg
    t="1625559752721"
    class="icon"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="3054"
    :width="width"
    :height="height"
    :fill="fill"
  >
    <path pid="0" d="M816 112H208c-88 0-160 72-160 160v480c0 88 72 160 160 160h608c88 0 160-72 160-160V272c0-88-72-160-160-160zm96 640c0 52.8-43.2 96-96 96H208c-52.8 0-96-43.2-96-96V272c0-52.8 43.2-96 96-96h608c52.8 0 96 43.2 96 96v480z" _fill="#fff"/><path pid="1" d="M459.2 489.6c22.4-22.4 36.8-54.4 36.8-89.6 0-70.4-57.6-128-128-128s-128 57.6-128 128c0 35.2 14.4 67.2 36.8 89.6C225.6 520 192 576 192 640v57.6c0 30.4 24 54.4 52.8 54.4h246.4c28.8 0 52.8-24 52.8-54.4V640c0-64-33.6-120-84.8-150.4zM304 400c0-35.2 28.8-64 64-64s64 28.8 64 64-28.8 64-64 64-64-28.8-64-64zm176 288H256v-48c0-62.4 49.6-112 112-112s112 49.6 112 112v48zm320-208H640c-17.6 0-32 14.4-32 32s14.4 32 32 32h160c17.6 0 32-14.4 32-32s-14.4-32-32-32zm0-160H608c-17.6 0-32 14.4-32 32s14.4 32 32 32h192c17.6 0 32-14.4 32-32s-14.4-32-32-32zm0 320H672c-17.6 0-32 14.4-32 32s14.4 32 32 32h128c17.6 0 32-14.4 32-32s-14.4-32-32-32z" fill="#fff"></path>
  </svg>
</template>
<script>
export default {
  name: "GreenPassport",
  props: {
    fill: {
      default: "#ffffff"
    },
    width: {
      default: '40px'
    },
    height: {
      default: '40px'
    }
  },
};
</script>
