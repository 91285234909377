<template>
  <div>
    <template v-if="!disabled && options && options.length ">
      <el-select
      v-model="_value"
      filterable
      clearable
      :placeholder="placeholder || t('stuManagement.pleaseSelect')">
        <el-option-group
          v-for="group in options"
          :key="group[config.key]"
          :label="group[config.label]">
          <el-option
            v-for="item in group[groupKey]"
            :key="item[groupConfig.key]"
            :label="item[groupConfig.label]"
            :value="item[groupConfig.value]">
          </el-option>
        </el-option-group>
      </el-select>
    </template>
    <div v-else class="label" v-html="((options && options.length) ? label || _value  : _value)|| '&nbsp;'"></div>
  </div>
</template>

<script>
import i18n from "@/locale/index";
export default {
  mixins: [i18n],
  model:{
    prop: "value",
    event: "change"
  },
  props: {
    placeholder: String,
    disabled: {
      type: Boolean
    },
    value: {
      type: [ Number, String ]
    },
    options: {
      type: Array,
      require: true,
    },
    config: {
      type: Object,
      require: false,
      default:() => {
        return {
          key: 'label',
          label: 'label'
        }
      }
    },
    groupKey: {
      type: String,
      require: true,
    },
    groupConfig: {
      type: Object,
      require: false,
      default: () => {
        return {
          value: 'value',
          label: 'label',
          key: 'id'
        }
      }
    },
  },
  computed: {
    label(){
      if(!this.options || this.options.length == 0) return "";
      let label = ''
      this.options.find((option => {
        return option[this.groupKey].some(item => {
          if (item[this.groupConfig.value] === this.value) {
            label = item[this.groupConfig.label];
            return true;
          }
        })
      }))
      return label || ''
    },
    _value: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("change", val);
      }
    }
  },
}
</script>

<style lang='scss' scoped>

</style>