<template>
  <svg
    t="1625560075253"
    class="icon"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="3196"
  >
    <path
      d="M768 352a224 224 0 0 1 0 448h-32a32 32 0 0 1 0-64h32a160 160 0 0 0 0-320 147.2 147.2 0 0 0-27.84 2.56 32 32 0 0 1-38.08-27.52 192 192 0 0 0-380.16 0 32 32 0 0 1-38.08 27.52A147.2 147.2 0 0 0 256 416a160 160 0 0 0 0 320h32a32 32 0 0 1 0 64h-32a224 224 0 0 1 0-448h8.32a256 256 0 0 1 495.36 0z"
      p-id="3197"
    ></path>
    <path
      d="M480 608a32 32 0 0 1 64 0v288a32 32 0 0 1-64 0z"
      p-id="3198"
    ></path>
    <path
      d="M600 746.88a32 32 0 0 1 48 42.24l-112 128a32 32 0 0 1-48 0l-112-128a32 32 0 1 1 48-42.24l88 100.48z"
      p-id="3199"
    ></path>
  </svg>
</template>
<script>
export default {
  name: "FileIcon",
  props: {
    fill: String,
  },
};
</script>
