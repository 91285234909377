<template>
  <div
    :class="['stu-all-form-info', !stuid && !isMobile ? 'padding-left' : '']"
    ref="stuAllForm"
    :style="{ width: width }"
    v-if="!initLoading"
  >
    <nav-comp
      ref="navCompRef"
      :lang="lang"
      :is-require-parent-contact="isRequireParentContact"
      @onClickNavItem="onClickNavItem"
      @handleApplyInfo="handleApplyInfo"
      :navDynamic="navDynamic"
      :hideSubmit="hideMainSubmitBtn"
      :curActiveName="activeNames"
      :showBackgroundInfo="showBackgroundInfo"
      @export-form="exportForm"
      :show-all-files-download="fileCounts > 0"
      @all-files-downLoad="allFilesDownLoad"
      :fileUploadList="studentFileUploadList"
      :submitTime="submitTime"
    >
    </nav-comp>
    <div class="forms-box" :style="{ height: height }">
      <!-- <el-collapse v-model="activeNames" accordion> -->
        <!-- 个人信息 -->
        <div class="remark-box" v-if="companyId == 6">
          <div style="display:inline-block">
            <span>{{ t('common.followRemark') }}：</span>
            <div class="icon-box" @click="handleRemark">
              <edit-icon v-show="isDisabledRemark" class="opera-icon"> </edit-icon>
              <save-icon v-show="!isDisabledRemark" class="opera-icon"> </save-icon>
            </div>
          </div>
          <owl-input
            style="flex:1"
            size="small"
            :rows="5"
            :type="'textarea'"
            :englishOnly="false"
            :disabled="isDisabledRemark"
            v-model="remark"
          ></owl-input>
        </div>
        <div
          v-show="activeNames === 'personalInfo'"
        >
          <div class="title">{{t('stuManagement.personalInfo')}}</div>
          <student-info
            class="form-box-item"
            ref="personalInfo"
            comp-name="personalInfo"
            :bmsLoginId="bmsLoginId"
            :fkPlatformType="fkPlatformType"
            @on-student-update="onStudentUpdate"
          ></student-info>
        </div>
        <template v-if="stuid">
          <!-- 院校申请 -->
          <div
            v-show="activeNames === 'applicationSch'"
          >
            <div class="title">{{t('stuManagement.applicationSch')}}</div>
            <student-application
              @getDestinationCountry="getDestinationCountry"
              @updateDestinationCountry="updateDestinationCountry"
              class="form-box-item"
              ref="applicationSch"
              comp-name="applicationSch"
              @update="setApplyInsititionTree"
              @get-config="updateConfigByAdd"
              @delete-config="delConfigByApply"
              @on-active-handle="onClickNavItem"
              @update-apply="(updateApply) => getStudentApply(updateApply)"
              @save-submit-application="handleApplyInfo"
            ></student-application>
          </div>
          <!-- 背景资料 -->
          <div
            v-show="showBackgroundInfo && activeNames === 'backgroundInfo'"
          >
            <div class="title">{{t('stuManagement.backgroundInfo')}}</div>
            <student-background-info
              class="form-box-item"
              ref="backgroundInfo"
              comp-name="backgroundInfo"
            ></student-background-info>
          </div>
          <template v-for="(item, index) in navDynamic">
            <!-- 教育背景 eduData传[]是因为识别改为在表单内部做，而不在issue系统做了-->
            <template v-if="item.divisionKey == 'education'">
              <div
                :key="index + 'education'"
                v-show="activeNames === 'education'"
              >
                <div class="title">{{t('stuManagement.eduBackground')}}</div>
                <student-education-background
                  ref="education"
                  comp-name="education"
                  class="form-box-item"
                  :required="item.isRequired"
                  @setIdentifiedData="setIdentifiedData"
                  @setActiveName="(name) => activeNames = name"
                ></student-education-background>
              </div>
            </template>
            <!-- 工作经验 workExp传[]是因为识别改为在表单内部做，而不在issue系统做了 -->
            <template v-else-if="item.divisionKey == 'workExperience'">
              <div
                :key="index + 'workExperience'"
                v-show="activeNames === 'workExperience'"
              >
                <div class="title">{{t('stuManagement.experience')}}</div>
                <student-work-experience
                  ref="workExperience"
                  class="form-box-item"
                  comp-name="workExperience"
                  :required="item.isRequired"
                ></student-work-experience>
              </div>
            </template>
            <!-- 语言成绩 -->
            <template v-else-if="item.divisionKey == 'language'">
              <div
                :key="index + 'language'"
                v-show="activeNames === 'language'"
                
              >
                <div class="title">{{t('stuManagement.examination')}}</div>
                <student-language-proficiency
                  ref="language"
                  class="form-box-item"
                  comp-name="language"
                  :required="item.isRequired"
                ></student-language-proficiency>
              </div>
            </template>
            <!-- 专业资格证 -->
            <template v-else-if="item.divisionKey == 'qualifications'">
              <div
                :key="index + 'qualifications'"
                v-show="activeNames === 'qualifications'"
              >
                <div class="title">{{t('stuManagement.profQual')}}</div>
                <student-professional-qualification
                  class="form-box-item"
                  :required="item.isRequired"
                  comp-name="qualifications"
                  ref="qualifications"
                ></student-professional-qualification>
              </div>
            </template>
            <!-- 推荐人 -->
            <template v-else-if="item.divisionKey == 'recommend'">
              <div
                :key="index + 'recommend'"
                v-show="activeNames === 'recommend'"
              >
                <div class="title">{{t('stuManagement.referee')}}</div>
                <student-referee
                  ref="recommend"
                  comp-name="recommend"
                  class="form-box-item"
                  :required="item.isRequired"
                  :studentApplyInstitutionTree="studentApplyInstitutionTree"
                  @on-letter-change="onLetterChange"
                ></student-referee>
              </div>
            </template>
            <!-- 后续安排 -->
            <template v-else-if="item.divisionKey == 'arrangements'">
              <div
                :key="index + 'arrangements'"
                v-show="activeNames === 'arrangements'"
              >
                <div class="title">{{t('stuManagement.FollowUpArrangements')}}</div>
                <student-accommodation
                  ref="arrangements"
                  comp-name="arrangements"
                  class="form-box-item"
                  :required="item.isRequired"
                ></student-accommodation>
              </div>
            </template>
            <!-- 父母联系方式 id:13 -->
            <template v-else-if="item.divisionKey == 'relativesContact'">
              <div
                :key="index + 'relativesContact'"
                v-show="activeNames === 'relativesContact'"
              >
                <div class="title">{{t('stuManagement.parentContactWay')}}</div>
                <student-parent-contact
                  ref="relativesContact"
                  class="form-box-item"
                  comp-name="relativesContact"
                  :required="item.isRequired && isRequireParentContact"
                ></student-parent-contact>
              </div>
            </template>
            <!-- 学生资料上传 id:15 -->
            <template v-else-if="item.divisionKey == 'fileData'">
              <div
                :key="index + 'fileData'"
                v-show="activeNames === 'fileData'"
              >
                <div class="title">{{t('stuManagement.stuInfoUpload')}}</div>
                <student-file-upload
                  ref="fileData"
                  comp-name="fileData"
                  :toFileCompObj="toFileCompObj"
                  :studentFileUploadList="studentFileUploadList"
                  class="form-box-item"
                  :required="item.isRequired"
                  :letters="letters"
                  @upload-files-count="(count) => fileCounts = count"
                ></student-file-upload>
              </div>
            </template>
          </template>
        </template>
      <!-- </el-collapse> -->
    </div>
    <apply-confirm
      ref="applyConfrim"
      :dialogVisible.sync="dialogVisible"
      :stufullName="stufullName"
      @handle-save-form="handleSaveForm"
    ></apply-confirm>
    <div style="display:none" v-if="!isWexinMiniprogram">
      <export-to-form ref="exportFormRef"></export-to-form>
    </div>
  </div>
</template>
<script>
import i18n from "@/locale/index";
import mixin from "@/utils/mixin";
import service from "@/utils/request";
import EditIcon from "../../components/svg-icon/EditIcon";
import SaveIcon from "../../components/svg-icon/SaveIcon";
import NavComp from "../../components/nav-comp/NavComp.vue";
import ApplyConfirm from "../../components/ApplyConfirm.vue";
import ExportToForm from "../../components/ExportToForm.vue";
import OwlInput from "/packages/components/OwlInput.vue";
import {
  getStudentApply,
  getAppCountry,
  getFromConfig,
  allDownLoadFileApi,
  getStudentFileDataApi,
  getRegisterDataApi,
  saveOrUpdateStudentRemarkApi,
} from "@/api/common.js";
import { getIsWexinMiniprogram } from '@/utils/tool'
let requestId = 0;
let propRequestId = 0;
let propRespondId = 0;
export default {
  name: "StudentAllFormInfo",
  watch: {
    lang(newVal) {
      document.documentElement.lang = newVal;
    },
    activeNames:{
      deep: true,
      handler(newVal){
        this.$emit('on-active-names-change', newVal);
      }
    },
  },
  components: {
    NavComp,
    OwlInput,
    EditIcon,
    SaveIcon,
    ApplyConfirm,
    ExportToForm,
  },
  provide() {
    return {
      getLocale: () => this.lang,
      getAgentId: () => this.agentId,
      stuid: () => this.stuid,
      ctryOptions: () => this.countryOptions,
      hideSubmitBtn: () => this.hideSubmitBtn,
      platformType: () => this.fkPlatformType,
      formStatus: () => this.formStatus,
      formType: () => 'gea',
      isBmsLogin: () => this.isBmsLogin,
      companyId: () => this.companyId,
      platformLimitList: () => this.platformLimitList,
      issueInnerAccount: () => this.issueInnerAccount,
      isNewAppStatus: () => this.isNewAppStatus,
    };
  },
  mixins: [mixin, i18n],
  props: {
    // 学生id
    studentId: {
      require: true,
      type: [Number, String],
      default: '',
    },
    // 语言 
    lang: {
      type: String,
      default: "zh",
    },
    // 代理id
    publicLevel: {
      type: [String, Number],
      default: "5",
    },
    // 引用该公共资源的平台
    fkPlatformType: {
      type: String,
      default: "get_mso",
    },
    width: {
      type: String,
      require: false,
      default: "100%",
    },
    height: {
      type: String,
      require: false,
      default: "100%",
    },
    agentId: {
      require: true,
      type: [Number, String],
      default: '',
    },
    // 请求截断
    interceptorsRequest: {
      require: false,
      type: Function,
    },
    // 响应截断
    interceptorsResponse: {
      require: false,
      type: Function,
    },
    // 是否纯浏览
    isDisable: {
      require: false,
      type: Boolean,
      default: false,
    },
    // 只用于平台展示用
    platformType: {
      require: false,
      type: String,
      default: "",
    },
    ciphertext: {
      require: false,
      type: String,
      default: "",
    },
    privateFilesUrl: {
      require: false,
      type: String,
      default: ""
    },
    publicFilesUrl: {
      require: false,
      type: String,
      default: ""
    },
    at: {
      require: false,
      type: String,
      default: ""
    },
    isBmsLogin: {
      require: false,
      type: Boolean,
      default: false
    },
    bmsLoginId:{
      require: false,
      type: String,
      default:''
    },
    companyId: Number,
    // 根据平台局部限制
    platformLimitList: {
      require: false,
      tpye: Array,
      default: () => []
    },
    // 是否有课程id-奖学金小程序需要
    hadCourseId: {
      require: false,
      type: Boolean,
      default: false
    },
    issueInnerAccount: {
      require: false,
      type: Boolean,
      default: false
    }
    // 教育背景相关改为在表单内部
    // eduBack: {
    //   require: false,
    //   type: Array,
    // },
    // workExp: {
    //   require: false,
    //   type: Array,
    // },
  },
  computed: {
    isMobile(){
      return this.$isMobile();
    },
    filterNavList() {
      let list = {};
      if (JSON.stringify(this.navList) == "{}") return list;
      for (let key in this.navList) {
        for (let item of this.navList[key]) {
          if (!list[item.divisionKey]) {
            list[item.divisionKey] = item;
          }
        }
      }
      return list;
    },
    navDynamic() {
      return Object.values(this.filterNavList).sort((a, b) => {
        return a.viewOrder > b.viewOrder ? -1 : 1;
      });
    },
    hideMainSubmitBtn(){
      return this.isDisable || this.formStatus === 1;
    },
    hideSubmitBtn(){
      return (this.isDisable || this.formStatus === 1 || this.formStatus === 3);
    },
    // 学生生日是否超过18岁，超过则父母联系人不必填
    isRequireParentContact() {
      if (this.stuBirthday) {
        const birthdayYear = (new Date(this.stuBirthday)).getFullYear();
        const nowYear = (new Date()).getFullYear();
        if (nowYear - birthdayYear < 18) {
          return true;
        }
      }
      return false;
    },
    // 是否是微信小程序环境里
    isWexinMiniprogram() {
      return getIsWexinMiniprogram();
    }
  },
  data() {
    return {
      isNewAppStatus: false,
      submitTime: '',
      remark: '',
      isDisabledRemark: true,
      timer: null,
      eduBack: [],
      workExp: [],
      fileCounts: 0, // 已上传文件数量
      initLoading: false, // 初始化
      valued:'',
      formStatus: 0,        // 0新建未提交 1提交申请 2 需要补件 3加申院校 4在线表单提交
      dialogVisible: false,
      stuid: null,
      countryOptions: [],   // 国家
      toFileCompObj: {}, // 传递给文件页的包含国家学校专业等级的对象
      navList: {}, // 侧边栏
      needUploadFileList: [ 
        {
          id: 0,
          name: 'Uploaded Public File',
          list: []
        }
      ], // 需要上传的资料
      studentFileUploadList: [],
      isHideFileUpload: false,
      showBackgroundInfo: false, // 展示背景资料
      stufullName: {}, // 学生全名
      stuBirthday: '', // 生日
      activeNames: "applicationSch",
        //[
        // "applicationSch",
        // "personalInfo",
        // "backgroundInfo",
        // "education",
        // "workExperience",
        // "language",
        // "qualifications",
        // "recommend",
        // "arrangements",
        // "relativesContact",
        // "fileData",
        // ],
      studentApplyInstitutionTree: {},  // 申请的院校课程树
      letters: [],  // 推荐信列表{fileNameOrc: string,fkFileGuid: string, fkStudentInstitutionCourseId:[]}
    };
  },
  created() {
    if(requestId) {
      service.interceptors.request.eject(requestId)
    }
    requestId =service.interceptors.request.use(
      (config) => {
        config.headers.fkPlatformType = this.fkPlatformType;
        config.headers.publicLevel = this.publicLevel;
        config.headers.langCode = this.lang;
        config.headers.ciphertext = this.ciphertext;
        config.headers.at = this.at;
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );
    if (this.interceptorsRequest) {
      propRequestId && service.interceptors.request.eject(propRequestId)
      propRequestId = service.interceptors.request.use(this.interceptorsRequest);
    }
    if (this.interceptorsResponse) {
      propRespondId && service.interceptors.response.eject(propRespondId)
      propRespondId = service.interceptors.response.use(this.interceptorsResponse);
    }
    this.stuid = this.studentId;
    if( this.platformLimitList.includes('get_scholarship') && !this.hadCourseId) {
      this.activeNames = 'personalInfo';
    }else if(this.$route.query.activeNames){
      this.activeNames = this.$route.query.activeNames;
    }
  },
  async mounted() {
    await this.getCountryOptions();
    await this.getStudentApply();
    this.initLoading = false;
    this.$emit('loadingEnd');
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;  
    }
  },
  methods: {
    async saveRemark() {
      try {
        await saveOrUpdateStudentRemarkApi(this.studentId, this.remark);
        this.$refs.personalInfo.stuInfoFormData.remark = this.remark;
      } catch(e) {
        this.isDisabledRemark = false;
      }
    },
    handleRemark() {
      if (!this.isDisabledRemark) {
        this.saveRemark();
      }
      this.isDisabledRemark = !this.isDisabledRemark;
    },
    setIdentifiedData({ workExp, eduBack }) {
      if (workExp && workExp.length) {
        const workExperienceRef = this.$refs.workExperience[0];
        workExperienceRef.setIdentifiedData(workExp);
        const fileDataRef = this.$refs.fileData[0];
        fileDataRef.getStudentFileData();
      } else if (eduBack && eduBack.length) {
        const educationRef = this.$refs.education[0];
        educationRef.setIdentifiedData(eduBack);
        const fileDataRef = this.$refs.fileData[0];
        fileDataRef.getStudentFileData();
      }
    },
    // 获取已上传文件数量
    async getStudentFileData() {
      const { data } = await getStudentFileDataApi(this.studentId);
      if (data.datas && data.datas.length!==0){
        this.fileCounts = data.datas.length
      } else {
        this.fileCounts = 0
      }
    },
    onStudentUpdate({stuName, birthday, ret}){
      this.stufullName = stuName;
      this.stuBirthday = birthday;
      if (!this.stuid) {
        this.stuid = ret;
        this.$emit("update:studentId", ret); // 外部使用sync修饰符
        this.$emit('updateStudentId',ret);
        setTimeout(()=>{
          this.getStudentApply();// 防止没有学生id
        },500)
      }
    },
    async getCountryOptions() {
      const { data } = await getAppCountry();
      this.countryOptions = data.datas;
    },
    onClickNavItem(key) {
      if (key === this.activeNames) return;
      if (this.checkEditStatus(key)) {
        document.querySelector('.forms-box').scrollTo(0, 0);
        this.activeNames = key;
        this.$emit('sub-menu-click',key);
      }
    },
    // 获取目的国家
    getDestinationCountry({
      type,
      fkAreaCountryId,
      fkAreaCountryname,
      applyLength,
    }) {
      this.showBackgroundInfo = Boolean(applyLength);
      if (type == "add") {
        this.$refs.backgroundInfo.aimCountryAdd(
          fkAreaCountryId,
          fkAreaCountryname,
        );
      } else if (type == "delete") {
        this.$refs.backgroundInfo.deleteDestCtry(fkAreaCountryId);
      }
    },
    // 更新目的国家
    updateDestinationCountry({oldId, newId, newCountryname}) {
      this.$refs.backgroundInfo.updateDestCtry({oldId, newId, newCountryname});
    },
    async getStudentApply() {
      if (this.studentId === '' || this.studentId === null) return;
      this.$refs.navCompRef.submitBtnLoading = true;
      try {
        let res = await getStudentApply(this.studentId);
        if (res.data.data) {
          let {
            accommodationDto,               // 后续安排
            studentParentsDto,              // 父母联系方式
            studentInfoDto,                 // 学生信息
            studentCountryItemDto,          // 目标国家
            studentEducationDtos,           // 教育背景
            studentInstitutionCourseDtos,   // 院校申请
            studentQualificationDtos,       // 专业资格
            studentReferenceDtos,           // 推荐人
            studentScoreDtos,               // 考试成绩
            studentWorkDtos,                // 工作经验
            studentDto,                     // 学生信息
          } = res.data.data;
          this.getCompleteStatus(res.data.data);

          if (
            studentInstitutionCourseDtos &&
            studentInstitutionCourseDtos.length
          ) {
              let i = 0;
              // 根据选择的学校加载配置
              for await (let item of studentInstitutionCourseDtos) {
                item.uniqueKey = ++i;
                const params = {
                  institutionId: item.fkInstitutionId,
                  institutionName: item.fkInstitutionName,
                  institutionShortName: item.fkInstitutionShortName,
                  countryId: item.fkAreaCountryId,
                  majorLevelId: item.fkMajorLevelId,
                  studentCourseId: item.id,
                  courseName: item.fkInstitutionCourseName || item.institutionCourseName,
                  isAddApp: item.isAddApp || false
                }
                await this.updateConfigByAdd(params)
                // await this.getConfigByApply();
              }
              this.studentFileUploadList = this.needUploadFileList;
              this.setApplyInsititionTree(studentInstitutionCourseDtos)
          }
          this.agentContactEmail = studentDto.agentContactEmail;
          this.receiptCode = studentDto.receiptCode
          this.submitTime = studentDto.submitTime;
          this.formStatus = studentInfoDto.status;
          this.stufullName = {
            lastName: studentInfoDto.lastName,
            firstName: studentInfoDto.firstName
          }
          this.stuid = studentInfoDto.id
          this.stuBirthday = studentInfoDto.birthday;
          this.remark = studentInfoDto.remark;
          this.isNewAppStatus = studentDto.studentOfferItemStepKey === 'STEP_NEW_APP';
          // 给子组件赋值
          // this.$nextTick(function() {
          this.$refs.personalInfo.setInitData(studentInfoDto);
          this.$refs.applicationSch.setInitData(studentInstitutionCourseDtos)
          this.showBackgroundInfo = studentInstitutionCourseDtos.length !== 0;
          this.$refs.backgroundInfo.setInitData(studentCountryItemDto);
          this.$refs.applicationSch.getCountryItemDto(studentCountryItemDto);
          if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;  
          }
          this.timer = setInterval(()=>{
            if (this.navDynamic.length && (this.$refs.qualifications || this.$refs.relativesContact || this.$refs.arrangements || this.$refs.workExperience || this.$refs.education || this.$refs.language || this.$refs.recommend)) {
              this.$refs.workExperience && this.$refs.workExperience[0].setInitData(studentWorkDtos);
              this.$refs.education && this.$refs.education[0].setInitData(studentEducationDtos, 1);
              this.$refs.qualifications && this.$refs.qualifications[0].setInitData(studentQualificationDtos)
              this.$refs.language && this.$refs.language[0].setInitData(studentScoreDtos)
              this.$refs.recommend && this.$refs.recommend[0].setInitData(studentReferenceDtos)
              this.$refs.arrangements && this.$refs.arrangements[0].setInitData(accommodationDto)
              this.$refs.relativesContact && this.$refs.relativesContact[0].setInitData(studentParentsDto);
            
              clearInterval(this.timer);
              this.timer = null;
            } else {
              // if (!this.navDynamic.length && this.timer) {
              //   clearInterval(this.timer);
              //   this.timer = null;
              // }
            }
            setTimeout(() => {
              this.$refs.navCompRef.submitBtnLoading = false;
            },100)
          }, 50);
          // })
        }
      } catch(e){
        this.$refs.navCompRef.submitBtnLoading = false;
      }
    },
    getCompleteStatus(data) {
      const {
        accommodationDto,               // 后续安排
        studentParentsDto,              // 父母联系方式
        studentInfoDto,                 // 学生信息
        studentCountryItemDto,          // 目标国家
        studentEducationDtos,           // 教育背景
        studentInstitutionCourseDtos,   // 院校申请
        studentQualificationDtos,       // 专业资格
        studentReferenceDtos,           // 推荐人
        studentScoreDtos,               // 考试成绩
        studentWorkDtos,                // 工作经验
        fileDtos,
      } = data;
      this.$root.toolMutations.clearState(this.$root.toolState)
      if (studentInfoDto.nameZh) { // 个人信息
        this.$root.toolMutations.addCompleted(this.$root.toolState, 'personalInfo');
      } else {
        this.$root.toolMutations.addUncompleted(this.$root.toolState, 'personalInfo');
      }
      if (studentInstitutionCourseDtos.length) { // 院校申请
        this.$root.toolMutations.addCompleted(this.$root.toolState, 'applicationSch');
      } else {
        this.$root.toolMutations.addUncompleted(this.$root.toolState, 'applicationSch');
      }
      if (studentCountryItemDto) { // 背景资料
        this.$root.toolMutations.addCompleted(this.$root.toolState, 'backgroundInfo');
      } else {
        this.$root.toolMutations.addUncompleted(this.$root.toolState, 'backgroundInfo');
      }
      if (studentQualificationDtos.length) { // 专业资格
        this.$root.toolMutations.addCompleted(this.$root.toolState, 'qualifications');
      } else {
        this.$root.toolMutations.addUncompleted(this.$root.toolState, 'qualifications');
      }
      if (studentWorkDtos.length) { // 工作经验
        this.$root.toolMutations.addCompleted(this.$root.toolState, 'workExperience');
      } else {
        this.$root.toolMutations.addUncompleted(this.$root.toolState, 'workExperience');
      }
      if (studentEducationDtos.length) { // 教育背景
        this.$root.toolMutations.addCompleted(this.$root.toolState, 'education');
      } else {
        this.$root.toolMutations.addUncompleted(this.$root.toolState, 'education');
      }
      if (accommodationDto.isNeedAccommodation !== null) { // 后续安排
        this.$root.toolMutations.addCompleted(this.$root.toolState, 'arrangements');
      } else {
        this.$root.toolMutations.addUncompleted(this.$root.toolState, 'arrangements');
      }
      if (studentReferenceDtos.length) { // 推荐人
        this.$root.toolMutations.addCompleted(this.$root.toolState, 'recommend');
      } else {
        this.$root.toolMutations.addUncompleted(this.$root.toolState, 'recommend');
      }
      if (studentScoreDtos.length) { // 考试成绩
        this.$root.toolMutations.addCompleted(this.$root.toolState, 'language');
      } else {
        this.$root.toolMutations.addUncompleted(this.$root.toolState, 'language');
      }
      if (studentParentsDto.monterFirstName) { // 父母联系方式
        this.$root.toolMutations.addCompleted(this.$root.toolState, 'relativesContact');
      } else {
        this.$root.toolMutations.addUncompleted(this.$root.toolState, 'relativesContact');
      }
      if (fileDtos && fileDtos.length) {
        this.$root.toolMutations.addUploadedFile(this.$root.toolState, {type: 'init', files: fileDtos});
      }
    },
    /**
     * 根据申请学校获取配置
     *  @param {obj.key} 
     *  @param {obj.institutionId} 
     *  @param {obj.institutionName} 
     *  @param {obj.countryId} 
     *  @param {obj.majorLevelId} 
     *  @param {obj.studentCourseId} 
     *  @param {obj.courseName} 
     *  @param {obj.isAddApp} 当前课程是否加申 
     */
    async getConfigByApply({
      institutionId, 
      countryId, 
      majorLevelId, 
      studentCourseId, 
      courseName, 
      institutionName,
      institutionShortName,
      isAddApp
    }) {
      if(!studentCourseId) return;
      const ret = await getFromConfig(countryId, majorLevelId, institutionId);
      const data = ret.data.data;
      const navList = data? data.appFormDivisionDtos: [];
      const needUploadFileList = data? data.attachmentDtos: [];
      // 公共文件
      const publicFile = [];
      // 需要单独为课程上传的文件
      const privateFile = [];
      // 分为公共文件和学校需要单独上传的文件
      if(needUploadFileList && needUploadFileList.length){
        // 分类
        for (let item of needUploadFileList){
          item.studentCourseId = studentCourseId;
          item.isAddApp = isAddApp;
          if(item.isMultipleRequired){  //需单独上传
            privateFile.push(item);
            item.courseName = courseName;
          } else {
            publicFile.push(item);
          }
        }
        // 新增私有的学校上传文件
        if(privateFile.length) {
          // 查找有没有相同的学校
          let institutionItem = this.needUploadFileList.find(item=>item.id == institutionId)
          // 如果没有相同的学校
          if(!institutionItem) {
            institutionItem = {
              name: institutionName,
              shortName: institutionShortName,
              id: institutionId,
              list: privateFile,
            }
            this.needUploadFileList.push(institutionItem);
          } else {
            // 如果有，先过滤掉已有的studentCourseId的在新增
            institutionItem.list.push(...privateFile)
          }
        }
        // 新增公共的上传文件
        let pubLng = publicFile.length
        if(pubLng) {
          // 判断是否有新的公共文件
          const file = publicFile.filter(item=>{
            // 判断是否已有此文件id
            const index = this.needUploadFileList[0].list.findIndex(jtem => jtem.id == item.id);
            // 如果有此文件id
            if( index >= 0 ){
              this.needUploadFileList[0].list[index].isAddApp = 1;
              // 判断是否需要加studentCourseId
              const needAdd = this.needUploadFileList[0].list[index].studentCourseId.toString().split(',').indexOf(studentCourseId+'') >= 0;
              !needAdd && (this.needUploadFileList[0].list[index].studentCourseId += `,${studentCourseId}`)
            }
            return index < 0;
          })
          // 如果有新的公共文件
          if(file.length){
            this.needUploadFileList[0].list.push(...file)
          }
        }
      }

      // 侧边导航栏
      if((!navList || !navList.length) && this.navList[studentCourseId]) {
        this.$delete(this.navList, studentCourseId)
        return;
      }
      if (this.navList[studentCourseId] ) {
        this.navList[studentCourseId] = navList;
      } else {
        this.$set(this.navList, studentCourseId, navList);
      }
    },
    async updateConfigByAdd(params){
      // 过滤一遍，如果studentCourseId已有，需要删除
      let index = this.needUploadFileList.length - 1;
      while(this.needUploadFileList.length && index > 0){
        if(!index) break;
        const item = this.needUploadFileList[index]
        const j = item.list.findIndex(item=>item.studentCourseId == params.studentCourseId)
        if(j >= 0) {
          if (item.list.length == 1){
            this.needUploadFileList.splice(index, 1)
          } else {
            item.list.splice(j, 1)
          }
        }
        index--;
      }
      await this.getConfigByApply(params);
    },
    // 删除配置，学校课程的资料上传
    delConfigByApply(key) {
      if (this.navList[key]) {
        this.$delete(this.navList, key);
        this.studentFileUploadList = this.studentFileUploadList.filter(item=>{
          const newList = item.list.filter(jtem => {
            const studentCourseId = jtem.studentCourseId.toString().split(',')
            const index = studentCourseId.indexOf(key+'') ;
            if(index >= 0){
              studentCourseId.splice(index, 1);
              jtem.studentCourseId = studentCourseId.join(',')
              return jtem.studentCourseId.length > 0
            }
            return true
          })
          if(newList.length > 0) {
            item.list = newList;
            return true
          } else {
            return false;
          }
        })
        const arr = this.needUploadFileList[0];
        if (this.studentFileUploadList[0] && this.studentFileUploadList[0].name === 'Uploaded Public File') {
          this.needUploadFileList = this.studentFileUploadList
        } else {
          this.needUploadFileList = [arr,...this.studentFileUploadList];
        }
      }
    },
    // 提交申请
    async handleApplyInfo() {
      const res = await this.checkFormSubmitStatus();
      if (!res ) return
      if (this.stuid) this.dialogVisible = true;
    },
    // 确认
    handleSaveForm(submitTime) {
      this.dialogVisible = false;
      this.$refs.applyConfrim.checkList = [];
      this.formStatus = 1;
      this.submitTime = submitTime;
      this.$msge({
        message: this.t("common.saveSucc"),
        type: "success",
        duration: 2000,
        onClose: () => {
          this.$emit('submit-form-success')
        },
      });
    },
    async checkFormSubmitStatus() {
      const formRefs = [
        "personalInfo",
        "applicationSch",
        "backgroundInfo",
        ...this.navDynamic.map((item) => item.divisionKey),
      ];
      for await(let item of formRefs) {
        let formClip = this.$refs[item];
        if (!formClip) {
          this.$alrt(this.t(`validate.${item}`), "", {
            confirmButtonText: this.t("common.confirm"),
          });
          return false
        }
        formClip = formClip.length? formClip[0]: formClip;
        if (formClip.checkFormStatus) {
          if (formClip === this.$refs.applicationSch && formClip.stuInstCourseFormData && formClip.stuInstCourseFormData.instCourses.length) {
            const instCourses = formClip.stuInstCourseFormData.instCourses;
            let errorFlag = false;
            let i = 0;
            for await(let jtem of instCourses) {
              if (!jtem.statusRpa) {
                const {error, isEdit, validate} = await formClip.checkFormStatus(formClip.$refs.applicationForm[i])
                if(!isEdit || !validate){
                  this.$alrt(error, this.t("common.tip"), {confirmButtonText: this.t("common.confirm")})
                  this.activeNames = item;
                  errorFlag = true;
                  return false;
                }
              }
              i++;
            }
            if (errorFlag) {
              return false;
            }
          } else {
            const {error, isEdit, validate} = await formClip.checkFormStatus()
            console.log(error, isEdit, validate);
            if(!isEdit || !validate){
              this.$alrt(error, this.t("common.tip"), {confirmButtonText: this.t("common.confirm")})
              this.activeNames = item;
              return false;
            }
          }
        }
      }
      return { flag: true, item: "" };
    },
    setApplyInsititionTree(list) {
      let institution = {};
      for(let item of list){
        if(institution[item.fkInstitutionId]){
          institution[item.fkInstitutionId].courses.push({
            name: item.fkInstitutionCourseName || item.institutionCourseName,
            fkInstitutionCourseId: item.fkInstitutionCourseId,
            id: item.id,
            disabled: false,
          })
        } else {
          institution[item.fkInstitutionId] = {
            name: item.fkInstitutionName,
            shortName: item.fkInstitutionShortName,
            courses: [{
              name: item.fkInstitutionCourseName || item.institutionCourseName,
              fkInstitutionCourseId: item.fkInstitutionCourseId,
              id: item.id,
              disabled: false,
            }]
          }
        }
      }
      this.studentApplyInstitutionTree = institution;
    },
    onLetterChange(letters){
      if(!letters.length) {
        return this.letters = [];
      }
      this.letters = JSON.parse(JSON.stringify(letters)).sort((a,b)=> b.id-a.id );
    },
    checkEditStatus(key) {
      const formRefs = [
        "applyConfrim",
        "personalInfo",
        "applicationSch",
        "backgroundInfo",
        ...this.navDynamic.map((item) => item.divisionKey),
      ];
      const curActiveName = this.activeNames;
      let curFormClip = this.$refs[curActiveName];
      if (Array.isArray(curFormClip)) {
        curFormClip = this.$refs[curActiveName][0];
      }
      for (let item of formRefs) {
        let formClip = this.$refs[item];
        if (Array.isArray(formClip)) {
          formClip = this.$refs[item][0];
        }
        if (curFormClip.hasEdit && curFormClip.hasEdit()) {
          return false;
        } else if(formClip.hasEdit && formClip.hasEdit(key === item)){
          this.activeNames = item;
          return false;
        }
      }
      return true;
    },
    // 导出表单
    exportForm(){
      if (this.checkEditStatus()) {
        this.$refs.exportFormRef.exportForm();
      }
    },
    allFilesDownLoad() {
      // 下载全部文件
      allDownLoadFileApi(this.studentId).then((res) => {
        if (res) {
          const blob = new Blob([res.data], { type: "application/zip" });
          const elink = document.createElement("a");
          elink.download = this.t("stuManagement.studentInformation");
          elink.style.display = "none";
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href); // 释放URL 对象
          document.body.removeChild(elink);
        }
      });
    }
  },
};
</script>
<style lang="scss">
.el-form.disabled .el-form-item .el-form-item__label,
.el-form .el-form-item__label {
  white-space: pre-line !important;
}
.padding-left {
  padding-left: 20px;
}
.gea-header-style{
  width:100%;
  height:54px;
  background: url('../../../src/assets/geabg.jpg') no-repeat;
  background-size: 100% auto;
  background-size: cover;
}
@media screen and(max-width: 768px){
  .gea-header-style{
    height:27px;
  }
}
.stu-all-form-info {

  .title {
    padding: 30px 12px 10px;
    font-size: 18px;
    font-weight: 600;
    text-align: left;
  }
}
.remark-box {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1000px;
  padding: 20px 12px 0;
  font-size: 14px;
  text-align: left;
  .icon-box {
    display: inline-block;
    border: 1px solid #ff9700;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    box-sizing: border-box;
    text-align: center;
    line-height: 28px;
    cursor: pointer;
    margin-right: 10px;
  }
  .opera-icon {
    width: 16px;
    height: 16px;
    display: inline-block;
  }
}
</style>
