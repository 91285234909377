import axios from "axios";
import messageBox from "../../packages/components/message-box";
const whiteList = [
  "/issue/student/getStudentApply",
  "/appFile/downloadIssue",
  "/appFile/issueDownloadList",
  "/authentication/oauth/token"
];

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 300000,
});

service.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
service.interceptors.response.use((response) => {
  const res = response.data;
  if (res.code === "1003") {
    return response;
  } else {
    if (!whiteList.includes(response.config.url)) {
      let message = res.message;
      if (res.code === "1004") {
        // TODO 使用国际化翻译文件配置 暂时用不了i18n.t  待修复
        message = "系統錯誤！System error!";
      }
      process.env.NODE_ENV != "development" &&
      res.code != '0002' && 
      messageBox.alert(JSON.stringify(message), 'error', {
        confirmButtonText: 'confirm'
      })
    } else {
      return response;
    }
    return Promise.reject(res);
  }
});

export const transformRequest = [
  (data) => {
    let ret = "";
    for (const it in data) {
      if (it) {
        ret +=
          encodeURIComponent(it) + "=" + encodeURIComponent(data[it]) + "&";
      }
    }
    return ret;
  },
];

export default service;
