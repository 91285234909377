<template>
  <div class="stu-work-experience">
    <title-button-comp
      :mainTitle="t('stuManagement.workExperience')"
      :buttonName="t('stuManagement.addWorkExperience')"
      @handleAdd="handleAdd"
      :showMainTitle="showMainTitle"
      :addShow="!hideSubmitBtn()"
      :loading="loading"
    ></title-button-comp>
    <el-form
      v-for="(workData, index) in studentWorkExpData.stuWorkExperience"
      :key="workData.uniqueKey"
      :class="[
        'stu-work-form',
        isDisabled(workData.uniqueKey) && 'disabled',
        !isEditing(workData.uniqueKey) && 'show-form',
      ]"
      :unique-key="workData.uniqueKey"
      :ref="'stuWorkForm'+workData.uniqueKey"
      :rules="stuWorkRules"
      :model="workData"
      size="small"
      label-position="top"
    >
      <div class="sub-title">
        {{ t("stuManagement.workExperience") }}
        <span>{{ studentWorkExpData.stuWorkExperience.length - index }}</span>
        <owl-btn-set
          deleteShow
          :updateShow="isDisabled(workData.uniqueKey)"
          @update="updateWorkItem(workData)"
          @delete="delworkExperience(workData, index, workData.uniqueKey)"
          v-if="!hideSubmitBtn()"
        />
      </div>
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item
            :label="t('stuManagement.workExperienceNum')"
            prop="viewOrder"
            :rules="rules.needFiledRule"
          >
            <owl-select
              :disabled="isDisabled(workData.uniqueKey)"
              v-model="workData.viewOrder"
              @change="handleChangeViewOrder(workData)"
              :options="viewOrderOption"
              :option-config="{ label: 'label', value: 'value' }"
            ></owl-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('stuManagement.position')"
            prop="position"
            :rules="rules.needFiledRule"
          >
            <owl-input
              v-model="workData.position"
              :disabled="isDisabled(workData.uniqueKey)"
            ></owl-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('stuManagement.workStartTime')"
            prop="workStartDate"
            :rules="rules.needFiledRule"
          >
            <owl-input
              v-model="workData.workStartDate"
              :disabled="isDisabled(workData.uniqueKey)"
              type="date"
              :placeholder="t('common.startDate')"
              @change="handleDate(index, 1)"
            ></owl-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('stuManagement.workEndTime')"
            prop="workEndDate"
            :rules="workData.isOnDuty == 0 ? rules.needFiledRule : undefined"
          >
            <owl-input
              v-model="workData.workEndDate"
              :disabled="isDisabled(workData.uniqueKey)"
              type="date"
              :placeholder="t('common.endDate')"
              @change="handleDate(index, 2)"
            ></owl-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('stuManagement.countryOranisation')"
            prop="fkStudentAppCountryId"
            :rules="rules.needFiledRule"
          >
            <owl-select
              :disabled="isDisabled(workData.uniqueKey)"
              v-model="workData.fkStudentAppCountryId"
              :options="countryOptions"
              :option-config="{ label: 'name', value: 'id' }"
              @change="
                handleSelectCountry(workData.fkStudentAppCountryId, index)
              "
              :isMobileCanSearch="isMobileCanSearch"
            ></owl-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('stuManagement.stateOranisation')"
            prop="fkAreaStateId"
            :rules="rules.needFiledRule"
          >
            <owl-select
              :disabled="isDisabled(workData.uniqueKey)"
              v-model="workData.fkAreaStateId"
              allow-create
              :options="stateOptions[workData.uniqueKey]"
              :option-config="{ label: 'fullName', value: 'id' }"
              @change="handleSelectState(workData.fkAreaStateId, index)"
              :isMobileCanSearch="isMobileCanSearch"
            ></owl-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('stuManagement.cityOranisation')"
            prop="fkAreaCityId"
            :rules="rules.needFiledRule"
          >
            <owl-select
              :disabled="isDisabled(workData.uniqueKey)"
              allow-create
              v-model="workData.fkAreaCityId"
              :options="cityOptions[workData.uniqueKey]"
              :option-config="{ label: 'fullName', value: 'id' }"
            ></owl-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('stuManagement.companyName')"
            prop="companyName"
            :rules="rules.needFiledRule"
          >
            <owl-input
              v-model="workData.companyName"
              :disabled="isDisabled(workData.uniqueKey)"
            ></owl-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('stuManagement.isOnDuty')"
            prop="isOnDuty"
            :rules="rules.needFieldRadio"
          >
            <owl-radio
              :disabled="isDisabled(workData.uniqueKey)"
              v-model="workData.isOnDuty"
              :options="[
                { value: 1, label: t('common.yes') },
                { value: 0, label: t('common.no') },
              ]"
              :option-config="{ label: 'label', value: 'value' }"
            />
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('stuManagement.workMode')"
            prop="workMode"
            :rules="rules.needFieldRadio"
          >
            <owl-radio
              :disabled="isDisabled(workData.uniqueKey)"
              v-model="workData.workMode"
              :options="workModeOption"
              :option-config="{ label: 'name', value: 'id' }"
            >
            </owl-radio>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item
            :label="t('stuManagement.duties')"
            prop="jobDescription"
            :rules="rules.needFiledRule"
          >
            <owl-input
              type="textarea"
              v-model="workData.jobDescription"
              :disabled="isDisabled(workData.uniqueKey)"
            ></owl-input>
          </el-form-item>
        </el-col>
      </el-row>
      <owl-btn-save
        v-if="isEditing(workData.uniqueKey)"
        @save="handleSave(workData.uniqueKey, index)"
        @delete="cancelEditWord(workData, index)"
        show-delete
        :loading="loading"
      />
    </el-form>
    <div class="form-no-data-tip" v-if="!studentWorkExpData.stuWorkExperience.length">
      {{ t('validate.noFormDataTip') }}
    </div>
  </div>
</template>
<script>
import i18n from "@/locale/index";
import mixin from "@/utils/mixin";
import OwlSelect from "/packages/components/OwlSelect.vue";
import OwlInput from "/packages/components/OwlInput.vue";
import OwlRadio from "/packages/components/OwlRadio.vue";
import OwlBtnSave from "/packages/components/OwlBtnSave.vue";
import OwlBtnSet from "/packages/components/OwlBtnSet.vue";
import {
  getStateOptions,
  getCityOptions,
  saveOrUpdateStudentWork,
  deleteSudentWord,
} from "@/api/common.js";
export default {
  name: "StudentWorkExperience",
  mixins: [mixin, i18n],
  inject: ["stuid", "ctryOptions", "hideSubmitBtn","platformType", "platformLimitList"],
  components: { OwlSelect, OwlInput, OwlRadio, OwlBtnSave, OwlBtnSet },
  props: ["initDatas", "required", "showMainTitle"],
  data() {
    return {
      fkPlatformType:this.platformType(),
      showDetailData: false,
      cityOptions: {},
      stateOptions: {},
      workModeOption: [],
      loading: false,
      studentWorkExpData: {
        stuWorkExperience: [
          // {
          //   companyName: "", // 单位名称
          //   fkAreaCityId: "", // 单位所在城市
          //   fkAreaStateId: "", // 单位所在州省
          //   fkStudentAppCountryId: "", // 单位所在国家
          //   fkStudentId: 0, // 学生id
          //   isOnDuty: 0, // 目前是否在职：0否/1是
          //   jobDescription: "", // 职责描述
          //   position: "", // 职位
          //   viewOrder: "", // 排序（倒序），数字由大到小排列
          //   workEndDate: "", // 在职结束时间
          //   workMode: 1, // 工作时间模式 1=全日 2=业余
          //   workStartDate: "", // 在职开始时间
          // },
        ],
      },
      stuWorkRules: {},
      viewOrderOption: [
        { label: 1, value: 1 },
        { label: 2, value: 2 },
        { label: 3, value: 3 },
        { label: 4, value: 4 },
        { label: 5, value: 5 },
      ],
    };
  },
  computed: {
    countryOptions() {
      return this.ctryOptions();
    },
    isDisabled() {
      return (uniqueKey) => {
        return this.hideSubmitBtn() || !this.isEditing(uniqueKey)
      }
    },
    // 手机端显示可搜索下拉
    isMobileCanSearch() {
      return this.platformLimitList().includes('get_scholarship');
    },
  },
  mounted() {
    this.workModeOption = [
      { id: 1, name: this.t("stuManagement.fullTime") },
      { id: 0, name: this.t("stuManagement.partTime") },
    ];
  },
  methods: {
    async setIdentifiedData(data) {
      let i = 0;
      const orignalLen = this.studentWorkExpData.stuWorkExperience.length;
      const workExpArr = [];
      for await (let item of data) {
        let key = Date.now();
        const workExp = {
          id: item.id || '',
          companyName: item.companyName,
          fkAreaCityId: this.getIdTypeValue(item.fkAreaCityId),
          fkAreaStateId: this.getIdTypeValue(item.fkAreaStateId),
          fkStudentAppCountryId: this.getIdTypeValue(item.fkStudentAppCountryId),
          fkStudentId: this.stuid(),
          isOnDuty: Number(item.isOnDuty),
          jobDescription: item.jobDescription,
          position: item.position,
          viewOrder: item.viewOrder ? 1*item.viewOrder : orignalLen === 0 ? ++i : '',
          workEndDate: item.workEndDate && item.workEndDate.split(" ")[0],
          workMode: item.workMode,
          workStartDate: item.workStartDate && item.workStartDate.split(" ")[0],
          uniqueKey: key,
          isShowInput: false,
        };
        i--;
        workExpArr.push(workExp);
        if (item.fkStudentAppCountryId) {
          await this.getStateData(item.fkStudentAppCountryId, key);
        }
        if (item.fkAreaStateId && !isNaN(Number(item.fkAreaStateId))) {
          await this.getCityData(item.fkAreaStateId, key);
        }
        this.switchEditStatus(key);
        await this.waitMoment(1);
      }
      this.studentWorkExpData.stuWorkExperience.unshift(...workExpArr);
    },
    async setInitData(data) {
      if(!data || !data.length) {
        return;
      };
      this.loading = true;
      let i = data.length;
      const workExps = [];
      for await (let item of data) {
        let key = item.uniqueKey ? item.uniqueKey : Date.now();
        const workExp = {
          id: item.id || '',
          companyName: item.companyName,
          fkAreaCityId: (item.fkAreaCityId === '' || isNaN(Number(item.fkAreaCityId)))
            ? item.fkAreaCityId
            : item.fkAreaCityId * 1,
          fkAreaStateId: (item.fkAreaStateId === '' || isNaN(Number(item.fkAreaStateId)))
            ? item.fkAreaStateId
            : item.fkAreaStateId * 1,
          fkStudentAppCountryId: (item.fkStudentAppCountryId === '' || isNaN(Number(item.fkStudentAppCountryId)))
            ? item.fkStudentAppCountryId
            : item.fkStudentAppCountryId * 1,
          fkStudentId: this.stuid(),
          isOnDuty: Number(item.isOnDuty),
          jobDescription: item.jobDescription,
          position: item.position,
          viewOrder: item.viewOrder || i,
          workEndDate: item.workEndDate && item.workEndDate.split(" ")[0],
          workMode: item.workMode,
          workStartDate: item.workStartDate && item.workStartDate.split(" ")[0],
          uniqueKey: key,
          isShowInput: false,
        };
        workExps.push(workExp)
        await this.waitMoment(1);
        if (item.fkStudentAppCountryId) {
          await this.getStateData(item.fkStudentAppCountryId, key);
        }
        if (item.fkAreaStateId && !isNaN(Number(item.fkAreaStateId))) {
          await this.getCityData(item.fkAreaStateId, key);
        }
        i--;
        if (!item.id) {
          this.switchEditStatus(key);
        }
      }
      this.studentWorkExpData.stuWorkExperience = workExps;
      this.loading = false;
    },
    // 获取省
    async getStateData(country, key) {
      const { data } = await getStateOptions(country);
      this.$set(this.stateOptions, key, data.datas);
    },
    // 获取城市
    async getCityData(state, key) {
      if (this.stateOptions[key] && this.stateOptions[key].length !== 0) {
        const { data } = await getCityOptions(state);
        this.$set(this.cityOptions, key, data.datas);
      }
    },
    handleChangeViewOrder(workData) {
      const viewOrder = workData.viewOrder;
      const stuWorkExperience = this.studentWorkExpData.stuWorkExperience;
      let num = 0;
      stuWorkExperience.map((workExperience) => {
        if(viewOrder.toString() === workExperience.viewOrder.toString()){
          num++;
        }
      })
      if (num > 1) {
        this.$alrt(
          this.t("stuManagement.workExperienceNumMsg", {
            num: viewOrder,
          }),
          "",
          {
            confirmButtonText: this.t("common.confirm"),
          }
        );
        workData.viewOrder = null;
      }
    },
    handleAdd() {
      if(this.hasEdit()) return;
      this.showDetailData = false;
      const uniqueKey = Date.now();
      this.studentWorkExpData.stuWorkExperience.unshift({
        viewOrder: this.studentWorkExpData.stuWorkExperience.length + 1,
        companyName: "",
        fkAreaCityId: "",
        fkAreaStateId: "",
        fkStudentAppCountryId: "",
        fkStudentId: this.stuid(),
        isOnDuty: 0,
        jobDescription: "",
        position: "",
        workEndDate: "",
        workMode: 1,
        workStartDate: "",
        uniqueKey: uniqueKey,
        isShowInput: false, // 国家决定省份城市是输入框
        id: null,
      });
      this.switchEditStatus(uniqueKey);
      this.onFormStatusChanged()
    },
    // 开始日期，结束日期
    handleDate(index, num) {
      const data = this.studentWorkExpData.stuWorkExperience[index];
      if (data.workStartDate && data.workEndDate) {
        if (data.workStartDate > data.workEndDate) {
          this.$alrt(
            this.t("validate.checkDate", {
              name: this.t("stuManagement.workTime"),
            }),
            this.t("common.tip"),
            {
              confirmButtonText: this.t("common.confirm"),
              callback: () => {
                if (num === 1) {
                  data.workStartDate = "";
                } else {
                  data.workEndDate = "";
                }
              },
            }
          );
        }
      }
    },
    // 国家
    handleSelectCountry(countryId, index) {
      this.studentWorkExpData.stuWorkExperience[index].fkAreaStateId = "";
      this.studentWorkExpData.stuWorkExperience[index].fkAreaCityId = "";
      if (!countryId) return;
      const key = this.studentWorkExpData.stuWorkExperience[index].uniqueKey;
      this.$delete(this.stateOptions, key);
      this.$delete(this.cityOptions, key);
      this.getStateData(countryId, key);
      let optionIndex = "";
      this.countryOptions.forEach((v, i) => {
        if (v.id === countryId) {
          optionIndex = i;
        }
      });
      if (!this.countryOptions[optionIndex].fkStudentAppCountryId) {
        this.studentWorkExpData.stuWorkExperience[index].isShowInput = true;
      } else {
        this.studentWorkExpData.stuWorkExperience[index].isShowInput = false;
      }
    },
    // 省change
    handleSelectState(stateId, index) {
      const key = this.studentWorkExpData.stuWorkExperience[index].uniqueKey;
      this.$delete(this.cityOptions, key);
      this.studentWorkExpData.stuWorkExperience[index].fkAreaCityId = "";
      if (!stateId || isNaN(stateId)) {
        return;
      }
      this.getCityData(stateId, key);
    },
    // 删除工作经验
    delworkExperience(data, index, uniqueKey) {
      const vueComponent = this.$refs['stuWorkForm'+uniqueKey][0];
      if (vueComponent) {
        vueComponent.clearValidate();
      }
      this.$confIssue(this.t("validate.delMsgWorkInfo"), "", {
        confirmButtonText: this.t("common.confirm"),
        cancelButtonText: this.t("common.cancel"),
        type: "warning",
      }).then(async () => {
        if (data.id) {
          this.loading = true;
          try {
            await deleteSudentWord(data.id);
          } catch{
            this.$msge.error(this.t("common.deleteFail"));
            return;
          } finally {
            this.loading = false;
          }
        }
        this.isEditing(data.uniqueKey) && this.switchEditStatus(data.uniqueKey)
        this.$delete(this.stateOptions, data.uniqueKey);
        this.$delete(this.cityOptions, data.uniqueKey);
        this.studentWorkExpData.stuWorkExperience.splice(index, 1);
        if (!this.studentWorkExpData.stuWorkExperience.length) {
          this.addUncompletedStatus()
        }
        this.onFormStatusChanged()
        this.$msge.success(this.t("common.deleteSucc"));
      });
    },
    // 取消编辑
    cancelEditWord(data, index){
      if(this.loading) return;
      this.$confIssue(this.t("validate.cancelEdit"), "", {
        distinguishCancelAndClose: true,
        confirmButtonText: this.t("common.confirm"),
        cancelButtonText: this.t("common.cancel"),
      }).then(async ()=>{
        const backup = this.getEditBackupByKey(data.uniqueKey)
        this.switchEditStatus(data.uniqueKey)
        this.onFormStatusChanged()
        if(backup) {
          this.$set(this.studentWorkExpData.stuWorkExperience, index, backup)
          this.loading = true;
          await this.getStateData(backup.fkStudentAppCountryId, data.uniqueKey)
          await this.getCityData(backup.fkAreaStateId, data.uniqueKey)
          this.loading = false;
        } else {
          this.studentWorkExpData.stuWorkExperience.splice(index, 1)
        }
      })
    },
    async checkFormStatus() {
      if(!this.required) return {
        isEdit: this.editList.length == 0, 
        validate: true, 
        error: this.t('validate.workExperience')
      };
      let validate = true;
      try {
        const temp = Object.keys(this.$refs);
        if(this.$refs && temp && temp.length > 1) {
          for await(let key of temp) {
            if (key !== 'fileInput') {
              if (!this.$refs[key].length) {
                this.$refs[key] = null;
                continue;
              }
              await this.$refs[key][0].validate();
            }
          }
        }
      } catch {
        validate = false;
      }
      return {
        isEdit: this.editList.length == 0 && this.studentWorkExpData.stuWorkExperience.length > 0,
        validate,
        error: this.t('validate.workExperience')
      }
    },
    // 编辑
    updateWorkItem(data) {
      if(this.hasEdit()) return;
      this.switchEditStatus(data.uniqueKey, data);
      this.onFormStatusChanged()
    },
    // 保存单个子项
    async handleSave(key, index) {
      const workExpData = this.studentWorkExpData.stuWorkExperience[index];
      let error = false;
      const validateFieldList=['companyName', 'fkAreaCityId', 'fkAreaStateId', 'fkStudentAppCountryId','isOnDuty','jobDescription','position','workMode','workStartDate'];
      if(!workExpData.isOnDuty){
        validateFieldList.push('workEndDate')
      }
      for await (let item of validateFieldList){
        const valid = await new Promise(res=>{
          this.$refs['stuWorkForm'+key][0].validateField(item,(error)=>{
            res(error)
          })
        })
        if(workExpData.isOnDuty){
          this.$refs['stuWorkForm'+key][0].clearValidate('workEndDate')
        }
        if(valid) error = true;
      }
      if(error) return;
      this.loading = true;
      try{
        const res = await saveOrUpdateStudentWork(workExpData);
        if (res.data.code === "1003") {
          this.switchEditStatus(key);
          this.onFormStatusChanged()
          this.$msge({
            message: this.t("common.saveSucc"),
            type: "success",
          });
          if (this.studentWorkExpData.stuWorkExperience.length === 1) {
            this.addCompletedStatus()
          }
          if (!workExpData.id) {
            workExpData.id = res.data.data;
            this.studentWorkExpData.stuWorkExperience.sort((a, b) => {
              return a.workEndDate > b.workEndDate ? -1 : 1;
            });
          }
        } else {
          this.$msge({
            message: this.t("common.saveFail"),
            type: "error",
          });
        }
      } catch{ }
      this.loading = false;
    },
    // 自动保存正在编辑的
    async autoSave(){
      if(!this.editList.length) return;
      const key = this.editList[0]
      const index = this.studentWorkExpData.stuWorkExperience.findIndex(item=>item.uniqueKey == key)
      if(index >= 0) await this.handleSave(key, index)
    },
    // 表单状态改变
    onFormStatusChanged(){
      let lng = this.studentWorkExpData.stuWorkExperience.length
      let editing = this.editList.length;
      let proccess = 0;
      if(lng){
        proccess = Math.floor((lng - editing) / lng * 100);
      }
      this.$emit('on-porccess-changed', proccess)
    }
  },
};
</script>
<style lang="scss">
.custom-label{
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 24px);
  .label{
    font-size: 12px;
  }
  .tip{
    color: red;
  }
  .tip-dse {
    display: inline-block;
    margin-left: 2em;
    color: blue;
    cursor: pointer;
  }
}
</style>
