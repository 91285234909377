import Vue from "vue";
import {createWrapDom} from '@/utils/tool'
import './style.scss'
const messageVm = new Vue({
  data:{
    list: [], // {message: string, type:string, key:number, time}[]
  },
  methods: {
    addMsg({message, type, onClose, duration = 3000, key}){
      key = key || Date.now()
      const item = this.list.find(item=>item.key == key)
      if(item){
        item.time && clearTimeout(item.time)
        item.message = message;
        item.type = type;
        item.key = key;
        item.close = () => {
          item.time && clearTimeout(item.time);
          this.rmMsg(key)
        };
        item.onClose = onClose;
        item.time = type != 'loading'? setTimeout(()=>this.rmMsg(key),duration): null;
        return item;
      }
      const msg = {
        message, 
        type,
        key,
        time: null,
        close: () => {
          msg.time && clearTimeout(msg.time);
          this.rmMsg(key)
        },
        onClose,
      };
      type != 'loading' && (msg.time = setTimeout(()=>this.rmMsg(key),duration))
      this.list.push(msg);
      return msg;
    },
    rmMsg(key){
      const index = this.list.findIndex(item=>item.key == key)
      this.list[index].onClose && this.list[index].onClose()
      index >= 0 && this.list.splice(index, 1);
    }
  },
  render(h){
    function getIcon(type){
      switch (type){
        case 'success': return 'el-icon-success';
        case 'warning': return 'el-icon-warning';
        case 'error': return 'el-icon-error';
        case 'info': return 'el-icon-info';
        case 'loading': return 'el-icon-loading'
      }
    }
    return h('transition-group',{
      class: 'owl-msg',
      name: 'msgbox',
      attrs:{
        name: 'msgbox',
      }
    },this.list.map(item=>(
      h('div',{
        key: item.key,
        class: ['itm', item.type]
      }, [
        h('i', {class: getIcon(item.type)}),
        h('span', item.message)
      ])
    )))
  }
})

export default function Message({message, type, onClose, duration, key}){
  if(!messageVm.$el){
    createWrapDom('owlMessage')
    messageVm.$mount('#owlMessage')
  }
  if(!/success|error|warning|info|loading/.test(type)) type = 'success'
  return messageVm.addMsg({message, type, onClose, duration, key})
}
['success', 'error', 'warning', 'info', 'loading'].map(type=>{
  Message[type] = (message,key)=>Message({message,type,key})
})