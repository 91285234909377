<template>
  <div class="owl-mobile-picker-wrap">
    <div :class="['owl-npt mobile-input', !_value && 'empty']">
      <span class="mobile-input-content" @click.stop="handleOpen">{{showTxt || placeholder}}</span>
      <span v-if="showTxt" @click="handleClearValue" class="mobile-clear-icon">
        <i class="el-icon-circle-close"></i>
      </span>
    </div>
    <el-drawer
      :title="placeholder"
      :visible.sync="drawer"
      direction="btt"
      size="80%"
      :wrapperClosable="false"
      :append-to-body="true"
      @close="handleClose"
      >
      <div class="select-page-input-wrap">
        <el-input 
          class="select-page-input"
          v-model="keyword"
          placeholder="输入关键字搜索"
          prefix-icon="el-icon-search"
          size="small"
          clearable
          @input="handleInput"> 
        </el-input>
      </div>
      <div class="select-page-list" ref="selectPageList" @scroll="handleScroll">
        <el-radio-group v-if="showList.length" v-model="currentData">
          <div class="select-page-item" v-for="(item, index) in showList" :key="index">
            <el-radio
              class="item-radio"
              :label="item"
              >
              <span class="item-name">{{item[optionConfig.label]}}</span>
            </el-radio>
          </div>
          <div v-if="page.currentPage === page.totalPage && options.length > 20" class="select-page-empty">已加载全部数据</div>
        </el-radio-group>
        <template v-else>
          <div v-if="showList.length === 0" class="select-page-empty">暂无数据</div>
        </template> 
      </div>
      <el-button class="select-page-add" type="primary" size="small" @click="handleAddValue">添加</el-button>
    </el-drawer>
  </div>

</template>
<script>
import i18n from "@/locale/index";
export default {
  mixins: [i18n],
  model:{
    prop: "value",
    event: "change"
  },
  props: {
    value: [String, Number],
    // 标题 文本框提示
    placeholder: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
    },
     optionConfig: {
      type: Object,
      require: false,
      defalut: {
        label: "label",
        value: "value"
      }
    },
  },
  watch: {
    drawer(bol) {
      if (bol) {
        this.showList = this.options.slice(0, this.page.sizePage);
        this.page = {
          currentPage: 1,
          totalPage: Math.ceil(this.options.length / this.page.sizePage),
          sizePage: this.page.sizePage,
        }
      }else {
        this.keyword = '';
      }
    },
  },
  data(){
    return {
      drawer: false,
      keyword: '', // 搜索关键字
      showList: [],
      currentData: {}, // 当前项
      page: {
        totalPage: 0,
        currentPage: 1,
        sizePage: 100,
      },
      lastScrollTop: 0,
    }
  },
  computed:{
    _value:{
      get(){
        return typeof this.value === "number" ? String(this.value) : this.value
      },
      set(val){
        this.$emit('change',val)
      }
    },
    showTxt(){
      const item = this.options.find(item=>item[this.optionConfig.value] == this.value)
      return item ? item[this.optionConfig.label] : this.value;
    }
  },
  mounted(){},
  methods: {
    // 打开
    handleOpen() {
      this.drawer = true;
    },
    handleInput(val) {
      this.keyword = val;
      if (val.length) {
        this.page.currentPage = 1;
        let list = [];
        list = this.options.filter( item => {
          return item[this.optionConfig.label].toLowerCase().indexOf(val.toLowerCase()) > -1
        })
        this.showList = list.slice(0, this.page.sizePage);
        this.page.totalPage = Math.ceil(list.length / this.page.sizePage)
      }else {
        this.showList = this.options.slice(0, this.page.sizePage);
        this.page.totalPage = Math.ceil(this.options.length / this.page.sizePage)
      }
    },
    // 添加
    handleAddValue() {
      this.drawer = false;
      if(JSON.stringify(this.currentData) ==='{}') {
        return this.$msge({
          message: '请先选择',
          type: 'success',
          duration: 2000
        })
      }else {
        let value = this.currentData[this.optionConfig.value];
        this.$emit('change', value)
      }
      
    },
    // 滚动 检测是否滚动到底部
    handleScroll() {
      const el = this.$refs.selectPageList;
      const scrollTop = el.scrollTop;
      // 确定是竖向滚动
      if(scrollTop - this.lastScrollTop !== 0) {
        if(el.scrollHeight - scrollTop === el.clientHeight) {
          if(this.page.currentPage >= this.page.totalPage) return;
          this.page.currentPage++;
          let list = this.options.slice((this.page.currentPage - 1) * this.page.sizePage, this.page.currentPage * this.page.sizePage)
          this.showList = this.showList.concat(list)
          this.lastScrollTop = scrollTop;
        }
      }
      
    },
    // 关闭
    handleClose() {
      this.drawer = false;
      if(!this.value) {
        this.currentData = {};
      }
    },
    // 清空选中得
    handleClearValue() {
      this.$emit('change','')
      this.currentData = {};
    }
  }
}
</script>
<style lang="scss" scoped>
.mobile-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  .mobile-input-content {
    flex: 1;
  }
  .mobile-clear-icon {
    padding: 4px;
    width: 20px;
  }
}

/deep/ .el-drawer{
  padding-bottom: env(safe-area-inset-bottom);
	padding-bottom: constant(safe-area-inset-bottom);
  .el-drawer__header {
    margin-bottom: 0;
  }
  .el-drawer__body {
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  .select-page-input-wrap {
    background-color: #f2f2f2;
    padding-bottom: 10px;
    .select-page-input {
      padding: 20px;
      background-color: #fff;
      .el-icon-circle-close {
        display: none;
      }
    }
    .el-input__prefix {
      padding-left: 20px;
    }
  }
  
  .select-page-list {
    background-color: #fff;
    overflow-y: auto;
    padding-bottom: 14px;
    margin-bottom: 40px;
    .el-radio-group {
      width: 100%;
    }
    .select-page-item {
      padding-left: 20px;
      padding-right: 20px;
      border-bottom: 1px solid #f2f2f2;    
      .item-radio {
        display: flex;
        padding: 10px 0;
        .item-name {
          padding-right: 10px;
        }
      }
    }
    .select-page-empty {
      font-size: 12px;
      color: #999;
      padding-top: 14px;
      text-align: center;
    }
 
  }
  .select-page-add {
    width: 100%;
    font-size: 14px;
    padding: 12px;
    position: absolute;
    bottom: env(safe-area-inset-bottom);
    bottom: constant(safe-area-inset-bottom);
    left: 0;
    z-index: 10;
    border-color: var(--form-color-primary);
    background: var(--form-color-primary);
    color: #fff;
    margin: 0;
  }
}
</style>