<template>
  <div class="stu-file-upload">
    <el-form
      class="stu-file-upload-box no-border"
      :model="fileFormData"
      :rules="fileRules"
      size="small"
      ref="fileForm"
    >
      <el-button type="text" id="all-download" @click="allFileDownLoad" 
        v-if="myUploadList && myUploadList.length && !isWexinMiniprogram">
        {{ t("stuManagement.downloadAllFile") }}
      </el-button>
      <div class="upload-desc">
        <span >（{{ t("stuManagement.uploadDesc") + t("stuManagement.uploadTip") }}）</span>
        <help-tip @click="handleHelpTip" ref="helpTipRef" keyCode="courseApplicationUpload" ></help-tip>
      </div>
      <div
        class="upload-box-content"
        v-for="(item, index) in fileFormData.fileListData"
        :key="index"
      >
        <div class="file-sub-title">{{item.name}}</div>
        <el-form-item
          v-for="(jtem, jndex) in item.list" :key="jndex"
          :class="['file-upload-item',isActive(jtem.studentCourseId, jtem.typeKey, index) && 'active']"
          :prop="`fileListData.${index}.list.${jndex}`"
          :rules="[
            {
              trigger: 'change',
              validator: cusValidateFile
            },
          ]"
        >
          <div class="file-upload-content">
            <div class="file-name-wrap">
              <file-icon class="file-icon"></file-icon>
              <span class="file-name" v-if="jtem.courseName">{{ jtem.courseName }}{{jtem.isAddApp? `(${t('stuManagement.addCourseAgain')})`: ''}}：</span>
              <span v-if="jtem.isRequired" class="is-required">*</span>
              <span class="file-name mr">{{ jtem.name }}</span>
            </div>
            <span class="btn-wrap need-judge" @click="handleDownload(item, jtem)">
              <download-icon class="icon"></download-icon>
              {{ t("stuManagement.download") }}
            </span>
            <span v-if="jtem.attachmentTypeTemplate" class="btn-wrap" @click="handleDownloadTemplate(jtem.attachmentTypeTemplate)" >
              <download-icon class="icon"></download-icon>
              {{ t("stuManagement.downloadTemplate") }}
            </span>
            <el-upload
              v-if="(jtem.isAddApp && formStatus() == 3) || formStatus() == 3 || !hideSubmitBtn() || isNewAppStatus()"
              :multiple="false"
              action=""
              :show-file-list="false"
              name="files"
              :on-change="changeFile"
              :before-upload="(file) => beforeUpload(file, jtem)"
              :http-request="(data)=>uploadFile(data, index, jndex)"
            >
              <div class="btn-wrap">
                <upload-icon class="icon"></upload-icon>
                {{ isActive(jtem.studentCourseId, jtem.typeKey, index)? t("stuManagement.clickUploadAgain") : t("stuManagement.clickUpload") }}
              </div>
            </el-upload>
            <span
              v-if="(jtem.isAddApp && formStatus() == 3) || formStatus() == 3 || !hideSubmitBtn() || isNewAppStatus()"
              @click="handleRemove(index, jndex)"
              class="btn-wrap need-judge"
            >
              <del-icon class="icon"> </del-icon>
              {{ t("common.del") }}
            </span>
          </div>
        </el-form-item>
      </div>
    </el-form>
    <div class="stu-file-upload-box" v-if="getCourseWithLetter && getCourseWithLetter.length">
      <div class="main-file-title">{{t('stuManagement.recommendationLetter')}}</div>
      <div class="letter-box-content" v-for="school of getCourseWithLetter" :key="school.id">
        <div class="file-sub-title">{{school.name}}</div>
        <div class="file-upload-item letter" v-for="course of school.list" :key="course.studentCourseId">
          <file-icon class="file-icon"></file-icon>
          <span class="file-name">{{ course.courseName + t('stuManagement.ofLetter')}}：{{course.lettersName}}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import i18n from "@/locale/index";
import mixin from "@/utils/mixin";
import FileIcon from "../../components/svg-icon/FileIcon";
import UploadIcon from "/packages/components/svg-icon/UpIcon.vue";
import DelIcon from "/packages/components/svg-icon/DelIcon.vue";
import DownloadIcon from "/packages/components/svg-icon/DownloadIcon.vue";
import HelpTip from "/packages/components/HelpTip.vue";
import wx from 'weixin-js-sdk'
import { getIsWexinMiniprogram } from '@/utils/tool'
import {
  updateStudentFileApi,
  saveStudentFileApi,
  deleteFileApi,
  downLoadStuFileApi,
  getStudentFileDataApi,
  uploadFileApi,
  allDownLoadFileApi,
} from "@/api/common.js";

export default {
  name: "StudentFileUpload",
  components: {
    FileIcon,
    UploadIcon,
    DelIcon,
    DownloadIcon,
    HelpTip,
  },
  computed: {
    type() {
      return "ISSUE-CENTER"; //
    },
    isActive(){
      return (studentCourseId, typeKey, index) => {
        const item = this.myUploadList.find(item => (
          ( index == 0  || studentCourseId == item.fkStudentInstitutionCourseIdOne ) && 
          typeKey == item.typeKey
        ))
        return Boolean(item)
      }
    },
    getLettersNoCourse(){
      const lastLetterNoCourse = [];
      for(let item of this.letters){
        if(lastLetterNoCourse.length == 2){
          break;
        }
        if(!item.fkStudentInstitutionCourseId || !item.fkStudentInstitutionCourseId.length){
          lastLetterNoCourse.push(item.fileNameOrc)
        }
      }
      return lastLetterNoCourse;
    },
    getCourseLetter(){
      return (studentCourseId)=>{
        if (!this.letters || !this.letters.length) return null;
        const letterName = []
        for (let letter of this.letters){
          if(
            letter.fkStudentInstitutionCourseId && 
            letter.fkStudentInstitutionCourseId.indexOf(studentCourseId) >= 0
          ) {
            letterName.push(letter.fileNameOrc)
          }
        }
        const lng = letterName.length;
        
        if(lng == 0){
          return this.getLettersNoCourse.join('、');
        } else if (lng == 1) {
          return letterName.concat(this.getLettersNoCourse).splice(0,2).join('、');
        } else {
          return letterName.join('、');
        } 
      }
    },
    getCourseWithLetter(){
      const schools = this.fileFormData.fileListData.slice(1)
      if(!schools || !schools.length) return [];
      const list = [];
      for (let school of schools){
        const courseList = [];
        for (let course of school.list){
          const hasExited = courseList.findIndex(item=>item && item.studentCourseId == course.studentCourseId);
          if(hasExited >= 0 ) continue;
          const lettersName = this.getCourseLetter(course.studentCourseId)
          lettersName && courseList.push({
            lettersName,
            studentCourseId: course.studentCourseId,
            courseName: course.courseName
          })
        }
        if(courseList.length){
          list.push({
            name: school.name,
            id: school.id,
            list: courseList
          })
        }
      }
      return list;
    },
    isWexinMiniprogram() {
      return getIsWexinMiniprogram();
    }
},
  inject: ["stuid", "hideSubmitBtn", "formStatus","platformType", "isNewAppStatus"],
  mixins: [mixin, i18n],
  props: {
    // toFileCompObj: {
    //   type: Object,
    // },
    studentFileUploadList: {
      type: Array,
      defalut: () => []
    },
    required: {
      type: Boolean,
      default: true,
    },
    letters: {
      type: Array,
      defalut: () => []
    },
  },
  data() {
    return {
      fkPlatformType:this.platformType(),
      loading: false,
      fileFormData: {
        fileListData: [], // 需要上传的文件 [i][j] i:公共学校分类 j需要上传的文件不分课程
      },
      // 是否有文件
      isHasFile: [],
      isShowFile: true,
      fileRules: {},
      myUploadList: [ 
        // {fileGuid:
        // fileName: "e0313091-0139-4a42-ace0-5ccf73bdaf4f.pdf"
        // fileNameOrc: "pra1.pdf"
        // filePath: string  路径
        // fileTypeOrc: string 类型
        // fkStudentInstitutionCourseId: 344  绑定课程id
        // id: 351
        // typeKey}
      ],
    };
  },
  watch:{
    studentFileUploadList:{
      async handler(newVal){
        this.fileFormData.fileListData = newVal; // 在await前注意顺序问题，不然小程序ios系统识别数组有问题
        this.myUploadList.length || await this.getStudentFileData()
        this.onFormStatusChanged();
      },
      immediate: true,
      deep: true,
    }
  },
  methods: {
    handleHelpTip() {
      this.$refs.helpTipRef.showTips = true;
    },
    // 下载全部文件
    allFileDownLoad() {
      allDownLoadFileApi(this.stuid()).then((res) => {
        if (res) {
          const blob = new Blob([res.data], { type: "application/zip" });
          const elink = document.createElement("a");
          elink.download = this.t("stuManagement.studentInformation");
          elink.style.display = "none";
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href); // 释放URL 对象
          document.body.removeChild(elink);
        }
      });
    },
    // 文件手动上传
    async uploadFile(data, index, jndex) {
      if(this.loading) return;
      try {
        this.loading = true;
        const schoolId = this.fileFormData.fileListData[index].id;
        const schoolName = this.fileFormData.fileListData[index].name;
        const schoolShortName = this.fileFormData.fileListData[index].shortName;
        const fileItem = this.fileFormData.fileListData[index].list[jndex];
        const myUploadItem = this.myUploadList.find(item=> (
          (!schoolId || fileItem.studentCourseId == item.fkStudentInstitutionCourseIdOne) && 
          item.typeKey == fileItem.typeKey
        ))  // 检测是更新或是保存
        const typeKey = fileItem.typeKey;
        const courseName = fileItem.courseName;
        const formdata = new FormData();  
        formdata.append(data.filename, data.file);
        formdata.append("type", this.type);
        formdata.append("stuId", this.stuid());
        formdata.append('typeKey', typeKey);
        if (typeKey === 'PS') {
          formdata.append("fkInstitutionCourseName", courseName);
          formdata.append("fkInstitutionName", schoolName);
          formdata.append("fkInstitutionShortName", schoolShortName || '');
          formdata.append("fkCourseId", fileItem.studentCourseId);
        }
        formdata.append("oldFkGuId", myUploadItem && myUploadItem.fkFileGuid || '')
        const res = await uploadFileApi(formdata);    // 上传文件
        this.loading = false;
        if (!res.data || res.data.code != '1003') {
          const message = res.data.message || this.t("common.uploadFail");
          this.$msge({
            message,
            type: "error",
          });
          return;
        }
        // 文件绑定课程
        // const newFkGuId = res.data.datas[0].fileGuid;
        // if (myUploadItem) {
        //   // 更新
        //   await updateStudentFileApi(this.stuid(), fileItem.typeKey, newFkGuId, myUploadItem.fkFileGuid)
        //   this.$msge({
        //     message: this.t("common.updateSucc"),
        //     type: "success",
        //   });
        // } else {
        //   // 首次上传
        //   await saveStudentFileApi(fileItem.typeKey, newFkGuId, this.stuid(), schoolId? fileItem.studentCourseId: '')
        //   this.$msge({
        //     message: this.t("common.saveSucc"),
        //     type: "success",
        //   });
        //   this.$root.toolMutations.addUploadedFile(this.$root.toolState, {files: res.data.datas});
        // }
        await this.getStudentFileData()
        this.onFormStatusChanged()
      } catch {
        this.loading = false;
        this.$msge({
          message: this.t("common.updateFail"),
          type: "error",
        });
      }
    },
    // 下载模板
    handleDownloadTemplate(attachmentTypeTemplate) {
      if (attachmentTypeTemplate) {
        this.downloadFile({
          fileKey: attachmentTypeTemplate.typeKey,
          fileNameOrc: attachmentTypeTemplate.fileNameOrc,
          filePath: attachmentTypeTemplate.filePath,
        });
      }
    },
    // 单个文件下载
    handleDownload(item, {typeKey, studentCourseId}) {
      const schoolId = item.id
      const data = this.myUploadList.find(item => (
        (schoolId == 0 || item.fkStudentInstitutionCourseIdOne == studentCourseId ) &&
        typeKey == item.typeKey
      ))
      console.log(data, 'data')
      let downLoadData = {};
      if (data && data.filePath) {
        downLoadData = {
          fileKey: data.typeKey,
          fileNameOrc: data.fileNameOrc,
          filePath: data.filePath.substring(1),
        };
        this.downloadFile(downLoadData);
      }
    },
    // 文件下载
    downloadFile(downLoadData) {
      if(this.isWexinMiniprogram) {
        wx.miniProgram.navigateTo({
          url: '/packageA/pages/apply/loadFile?downloadData=' + encodeURIComponent(JSON.stringify(downLoadData))
        })
      }else {
        this.handlePCdownload(downLoadData)
      }
    },
    // h5页面下载
    handlePCdownload(downLoadData) {
      if (JSON.stringify(downLoadData) !== "{}" && !this.loading) {
        this.loading = true;
        downLoadStuFileApi(downLoadData).then((res) => {
          this.loading = false;
          if (res) {
            const blob = new Blob([res.data]);
            const elink = document.createElement("a");
            elink.download = downLoadData.fileNameOrc;
            elink.style.display = "none";
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink);
          }
        }).catch(()=>{
          this.loading = false
        });
      }
    },
    // 获取文件
    async getStudentFileData() {
      const { data } = await getStudentFileDataApi(this.stuid());
      if (data.datas && data.datas.length!==0){
        this.myUploadList = data.datas;
      }else {
        this.myUploadList = [];
      }
      this.$emit('upload-files-count', this.myUploadList.length);
    },
    // 保持只上传一个
    changeFile(fileList) {
      if (fileList && fileList.length > 1) {
        fileList.splice(0, 1);
      }
    },
    
    // 文件上传格式限制
    beforeUpload(file, jtem) {
      const typeKey = jtem.typeKey;
      const type = file.type.toLowerCase();
      if ((!(typeKey === 'PASSPORT') && type.indexOf("pdf") < 0) || (typeKey === 'PASSPORT' && (type.indexOf("image") < 0 && type.indexOf("pdf") < 0))) {
        this.$msge.error(this.t("stuManagement.uploadLimit"));
        return false;
      }
      const sizeLimit = jtem.sizeLimit || 2;
      if ((file.size / 1024 / 1024) > sizeLimit) {
        this.$msge.error(this.t("stuManagement.uploadSize",{limit: sizeLimit}));
        return false;
      }
    },
    // 删除
    async handleRemove(index, jndex) {
      if(this.loading) return;
      const schoolId = this.fileFormData.fileListData[index].id;
      const fileItem = this.fileFormData.fileListData[index].list[jndex];
      const myUploadItem = this.myUploadList.find(item=> (
        (!schoolId || fileItem.studentCourseId == item.fkStudentInstitutionCourseIdOne)
        && item.typeKey == fileItem.typeKey
      )) 
      if(!myUploadItem) return;
      const fkGuId = myUploadItem.fkFileGuid;
      try {
        await this.$confIssue(this.t("validate.delMsgFile"), "", {
          confirmButtonText: this.t("common.confirm"),
          cancelButtonText: this.t("common.cancel"),
          type: "warning",
        })
        this.loading = true;
        await deleteFileApi(fkGuId)
        await this.getStudentFileData();
        if (!this.myUploadList.length&&this.fkPlatformType==='get_issue') {
          this.$root.toolMutations.deleteUploadedFile(this.$root.toolState, fkGuId);
        }
        this.loading = false;
        this.onFormStatusChanged();
        this.$msge({
          message: this.t("common.deleteSucc"),
          type: "success",
        });
      } catch (e) {
        this.loading = false;
        if(e == 'cancel') return;
        this.$msge({
          message: this.t("common.deleteFail"),
          type: "error",
        });
      }
    },
    
    // 检验
    cusValidateFile(rule, value, callback){
      if(value.isRequired) {
        const uploadItem = this.myUploadList.find(item => (
          item.typeKey == value.typeKey && 
          (value.studentCourseId == item.fkStudentInstitutionCourseIdOne || !item.fkStudentInstitutionCourseIdOne)
        ))
        if(!uploadItem) callback(new Error(this.t('stuManagement.plsUploadFile')+''))
      }
      return callback()
    },
    // 给父级校验
    async checkFormStatus() {
      if(!this.required) return {isEdit: true, validate: true, error: this.t('validate.fileData')};
      let validate = true;
      try {
        await this.$refs.fileForm.validate()
      } catch {
        validate = false;
      }
      return {
        isEdit: true,
        validate,
        error: this.t('validate.fileData')
      }
    },
    // 表单状态有所改变
    onFormStatusChanged(){
      let isMultiple = true;  // 第一个是公共上传的
      let mustUploadLng = 0;  // 计算可以上传文件的个数
      let hasUploadLng = 0; // 实际上传文件的个数
      // 需要上传的文件
      for(let item of this.fileFormData.fileListData) {
        for (let jtem of item.list) {
          mustUploadLng++;
          const isUploaded = this.myUploadList.find(my=>{
            return (
              (isMultiple || jtem.studentCourseId.toString().split(',').indexOf(my.fkStudentInstitutionCourseId+'') >=0) 
              && my.typeKey == jtem.typeKey
            )
          })
          if(isUploaded){
            hasUploadLng++;
          }
        }
        isMultiple = false;
      }
      let proccess = mustUploadLng? Math.floor((hasUploadLng / mustUploadLng) * 100) : 0;
      this.$emit('on-porccess-changed', proccess)
    },
    
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-form-item {
  margin-bottom: 10px;
}
/deep/ .el-form-item__content {
  line-height: 0;
  width: 100%;
}
.main-file-title{
  margin-bottom: 10px;
  padding-top: 26px;
  font-weight: bold;
  margin-top: 16px;
  font-size: 16px;
  text-align: left;
  color: #ff9700;
  border-top: 1px solid #e1e1e1;
  color: var(--form-color-primary);
}
.letter-box-content{
  padding: 0 12px;
}
.file-sub-title{
  margin-bottom: 10px;
  margin-top: 26px;
  font-weight: bold;
  font-size: 14px;
  color: #333;
  text-align: left;
}
.stu-file-upload {
  width: 100%;
  .upload-desc {
    text-align: left;
    color: #999999;
    font-size: 12px;
    margin-bottom: 10px;
  }
  #all-download {
    color:#00B696;
    color: var(--form-color-success);
    border-color: transparent;
    display: block;
    margin-left: auto;
  }
  .stu-file-upload-box {
    .file-upload-item {
      width: 100%;
      position: relative;
      display: flex;
      justify-content: space-around;
      align-items: center;
      border: 1px solid #e7e7e7;
      border-radius: 8px;
      padding:10px 12px;
      font-size: 14px;
      line-height: 16px;
      .file-icon{
        height: 32px;
        fill: #333;
        vertical-align: middle;
        margin-right:12px;
      }
      .btn-wrap {
        background: #f6f6f6;
        color: #666;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding:8px 10px;
        text-align: center;
        margin-left: 12px;
        cursor: pointer;
        transition: all .25s ease;
        .icon{
          fill: #666;
          height: 16px;
          transition: fill .25s ease;
          padding-right: 4px;
        }
        &:hover{
          color: #ff9700;
          color: var(--form-color-primary);
          .icon{
            fill:#ff9700;
            fill: var(--form-color-primary);
          }
        }
      }
      .file-name {
        vertical-align: middle;
        font-size: 14px;
        color: #333;
        line-height: 18px;
        word-break: break-all;
      }
      .need-judge{
        display: none;
      }
      &.active {
        border: 1px solid #ff9700;
        border: 1px solid var(--form-color-primary);
        .file-name{
          color: #ff9700;
          color: var(--form-color-primary);
        }
        .file-icon{
          fill: #ff9700;
          fill: var(--form-color-primary);
        }
        .btn-wrap{
          color: #fff;
          background: #ff9700;
          background: var(--form-color-primary);
          .icon{
            fill: #fff;
          }
          &:hover{
            opacity: .8;
            color: #fff;
            .icon{
              fill: #fff;
            }
          }
        }
        .need-judge{
          display: inline-flex;
        }
      }
      &.letter{
        border: 1px solid #00B696;
        border: 1px solid var(--form-color-success);
        text-align: left;
        margin-bottom: 10px;
        .file-name{
          color: #00B696;
          color: var(--form-color-success);
          flex: 1;
          text-align: left;
        }
        .file-icon{
          fill: #00B696;
          fill: var(--form-color-success);
        }
      }
    }
    .file-upload-content {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      line-height: 0;
      .file-name-wrap{
        flex: 1;
      }
      .file-type,
      .file-name {
        color: #666666;
      }
      .download-icon {
        width: 23px;
        fill: #ff9700;
        fill: var(--form-color-primary);
      }
    }
    .is-required {
      color: red;
      margin-right: 6px;
    }
  }
}
@media screen and(max-width: 768px) {
  .stu-file-upload {
    padding-bottom: 3rem;
    .upload-desc {
      text-align: center;
    }
    .all-download {
      margin: auto;
    }
    .stu-file-upload-box {
      .file-upload-item {
        font-size: 0.28rem;
        line-height: 0.32rem;
        padding-left: 6px;
        padding-top: 0;
        padding-bottom: 0;
        justify-content: flex-end;
        .upload-icon,
        .download-icon {
          width: 24px;
          height: 18px;
        }
        .del-icon {
          width: 17px;
          height: 17px;
        }
        .file-icon {
          height: 20px;
          margin-right: 4px;
        }
        .btn-wrap{
          padding:3px;
          margin-bottom: 2px;
          margin-top: 2px;
          margin-left: 6px;
        }
        
        .file-name-wrap{
          width: auto;
          word-break: break-all;
        }
      }
      .file-upload-content {
        padding: 6px 0;
        .file-upload-text {
          padding: 0 0.1rem;
          .file-type {
            padding-right: 0.1rem;
          }
        }
        .file-upload-opera {
          padding: 0 .1rem;
        }
      }
    }
  }
}
</style>
