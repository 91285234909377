<template>
  <div class="stu-infomation">
    <el-form
      :class="['stu-info-form diver no-border', isDisabled && 'disabled']"
      ref="stuInfoform"
      :model="stuInfoFormData"
      label-position="top"
      :rules="stuInfoFormRules"
      size="small"
    >
      <div class="sub-title">
        <owl-btn-set
          :updateShow="isDisabled"
          @update="getUpdate"
          v-if="issueInnerAccount() || !hideSubmitBtn()"
        />
      </div>
      <el-row :gutter="10">
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('stuManagement.nameZh')"
            prop="nameZh"
            :rules="rules.needFiledRule"
          >
            <owl-input
              :englishOnly="false"
              :disabled="isDisabled"
              v-model="stuInfoFormData.nameZh"
            ></owl-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('stuManagement.lastName')"
            prop="lastName"
            :rules="rules.needFiledRule"
          >
            <owl-input
              :disabled="isDisabled"
              v-model="stuInfoFormData.lastName"
            ></owl-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('stuManagement.firstName')"
            prop="firstName"
            :rules="rules.needFiledRule"
          >
            <owl-input
              :disabled="isDisabled"
              v-model="stuInfoFormData.firstName"
            ></owl-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('stuManagement.gender')"
            prop="gender"
            :rules="rules.needFieldRadio"
          >
            <owl-radio
              @change="changeGender"
              :disabled="isDisabled"
              v-model="stuInfoFormData.gender"
              :options="[
                { label: t('stuManagement.female'), value: 0 },
                { label: t('stuManagement.male'), value: 1 },
              ]"
              :option-config="{ label: 'label', value: 'value' }"
            ></owl-radio>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('personInfo.title')"
            prop="title"
            :rules="rules.needFiledRule"
          >
            <owl-select
              :disabled="isDisabled"
              v-model="stuInfoFormData.title"
              :options="getTitleOptions"
              :option-config="{ label: 'label', value: 'value' }"
            ></owl-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('stuManagement.dateBirth')"
            prop="birthday"
            :rules="[
              rules.needFiledRule,
              { validator: checkBirthDay, triggle: 'change' },
            ]"
          >
            <owl-input
              :disabled="isDisabled"
              v-model="stuInfoFormData.birthday"
              type="date"
            />
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('personInfo.stuPhoneNumber')"
            prop="phoneNumber"
            :rules="[
              rules.needFiledRule,
              { validator: checkRegionForPhoneR, trigger: 'change' },
              checkAreaCode(stuInfoFormData.phoneRegion),
            ]"
          >
            <owl-input
              :disabled="isDisabled"
              v-model="stuInfoFormData.phoneNumber"
              :placeholder="t('personInfo.stuPhoneNumber')"
              :characterOnly="true"
            >
              <owl-select
                class="stu-info-areacode-select"
                :disabled="isDisabled"
                style="min-width: 200px"
                slot="prepend"
                :placeholder="t('stuManagement.phoneRegion')"
                v-model="stuInfoFormData.phoneRegion"
                :options="regionNumOptions"
                :option-config="{ label: 'fullName', value: 'phoneRegion' }"
              />
            </owl-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('stuManagement.email')"
            prop="email"
          >
            <owl-input
              :disabled="isDisabled"
              v-model="stuInfoFormData.email"
            />
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24">
          <el-form-item :label="t('stuManagement.emailPassword')">
            <owl-input
              :disabled="isDisabled"
              v-model="stuInfoFormData.emailPassword"
            />
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('personInfo.fkStudentAppCountryIdLive')"
            prop="fkStudentAppCountryIdFrom"
            :rules="rules.needFiledRule"
          >
            <owl-select
              :disabled="isDisabled"
              v-model="stuInfoFormData.fkStudentAppCountryIdFrom"
              @change="handleSelectCountry"
              :options="countryOptions"
              :option-config="{ label: 'name', value: 'id' }"
              :isMobileCanSearch="isMobileCanSearch"
            ></owl-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24" v-if="stuInfoFormData.fkStudentAppCountryIdFrom === 228">
          <el-form-item
            :label="t('stuManagement.fkAreaRegion')"
            prop="fkAreaRegionIdFrom"
            :rules="rules.needFiledRule"
          >
            <owl-select
              :disabled="isDisabled"
              v-model="stuInfoFormData.fkAreaRegionIdFrom"
              :options="areaOption"
              :option-config="{ label: 'name', value: 'id' }"
              :isMobileCanSearch="isMobileCanSearch"
            ></owl-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24">
          <el-form-item
            prop="fkAreaStateIdFrom"
            :rules="rules.needFiledRule"
          >
            <div slot="label" class="custom-label">
              <div class="label">{{t('stuManagement.state')}}<span v-show="!isDisabled" class="tip">&nbsp;({{t('common.noSelectTips')}})</span></div>
            </div>
            <owl-select
              allow-create
              :disabled="isDisabled"
              v-model="stuInfoFormData.fkAreaStateIdFrom"
              @change="handleSelectState"
              :options="stateOptions"
              :option-config="{ label: 'fullName', value: 'id' }"
              :isMobileCanSearch="isMobileCanSearch"
            ></owl-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24">
          <el-form-item
            prop="fkAreaCityIdFrom"
            :rules="rules.needFiledRule"
          >
            <div slot="label" class="custom-label">
              <div class="label">{{t('stuManagement.city')}}<span v-show="!isDisabled" class="tip">&nbsp;({{t('common.noSelectTips')}})</span></div>
            </div>
            <owl-select
              allow-create
              :disabled="isDisabled"
              v-model="stuInfoFormData.fkAreaCityIdFrom"
              @change="handleSelectCity"
              :options="cityOptions"
              :option-config="{ label: 'name', value: 'id' }"
            ></owl-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24" v-if="regionOptions.length">
          <el-form-item
            :label="t('stuManagement.region')"
            prop="fkAreaCityDivisionIdFrom"
          >
            <owl-select
              allow-create
              :disabled="isDisabled"
              v-model="stuInfoFormData.fkAreaCityDivisionIdFrom"
              :options="regionOptions"
              :option-config="{ label: 'name', value: 'id' }"
            ></owl-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24" v-if="shareParthRequired">
          <el-form-item
            :label="t('stuManagement.shareLink')"
            prop="sharedPath"
            :rules="rules.needFiledRule"
          >
            <owl-input
              :englishOnly="false"
              :disabled="isDisabled"
              v-model="stuInfoFormData.sharedPath"
            />
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('stuManagement.postcode')"
            prop="contactPostcode"
            :rules="[rules.needFiledRule, checkPostCard(stuInfoFormData.fkStudentAppCountryIdFrom)]"
          >
            <owl-input
              :disabled="isDisabled"
              v-model="stuInfoFormData.contactPostcode"
              maxlength="20"
            />
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('stuManagement.address')"
            prop="contactAddress1"
            :rules="[
              rules.needFiledRule,
              { validator: checkAddrLength, trigger: 'blur' }
            ]"
          >
            <owl-input
              :disabled="isDisabled"
              v-model="stuInfoFormData.contactAddress1"
            ></owl-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24">
          <el-form-item
          :label="t('stuManagement.alternativeAddr')"
          prop="contactAddress2"
          :rules="[
            { validator: checkAddrLength, trigger: 'blur' }
          ]"
          >
            <owl-input
              :disabled="isDisabled"
              v-model="stuInfoFormData.contactAddress2"
            ></owl-input>
          </el-form-item>
        </el-col>
                <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('personInfo.isLivedSinceBirth')"
            prop="isLivedSinceBirth"
            :rules="rules.needFieldRadio"
          >
            <owl-radio
              :disabled="isDisabled"
              v-model="stuInfoFormData.isLivedSinceBirth"
              :options="[
                { label: t('common.no'), value: false },
                { label: t('common.yes'), value: true },
              ]"
              :option-config="{ label: 'label', value: 'value' }"
            ></owl-radio>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24" v-if="stuInfoFormData.isLivedSinceBirth === false" >
          <el-form-item
            :label="t('personInfo.migrationTime')"
            prop="migrationTime"
            :rules="rules.needFiledRule"
          >
            <owl-input
              v-model="stuInfoFormData.migrationTime"
              :disabled="isDisabled"
              type="date"
              :placeholder="t('personInfo.migrationTime')"
            ></owl-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24" v-if="stuInfoFormData.isLivedSinceBirth === false" >
          <el-form-item
            :label="t('personInfo.stayingRightTime')"
            prop="stayingRightTime"
            :rules="rules.needFiledRule"
          >
            <owl-input
              v-model="stuInfoFormData.stayingRightTime"
              :disabled="isDisabled"
              type="date"
              :placeholder="t('personInfo.stayingRightTime')"
            ></owl-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('personInfo.fkStudentAppCountryId')"
            prop="fkStudentAppCountryId"
            :rules="rules.needFiledRule"
          >
            <owl-select
              :disabled="isDisabled"
              v-model="stuInfoFormData.fkStudentAppCountryId"
              :options="countryOptions"
              :option-config="{ label: 'name', value: 'id' }"
              :isMobileCanSearch="isMobileCanSearch"
            ></owl-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('personInfo.fkStudentAppAncestryId')"
            prop="fkStudentAppAncestryId"
          >
            <owl-select
              :disabled="isDisabled"
              v-model="stuInfoFormData.fkStudentAppAncestryId"
              :options="ancestryOptions"
              :option-config="{ label: 'name', value: 'id' }"
            ></owl-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('personInfo.fkStudentAppMotherLanguageId')"
            prop="fkStudentAppMotherLanguageId"
          >
            <owl-select
              :disabled="isDisabled"
              v-model="stuInfoFormData.fkStudentAppMotherLanguageId"
              :options="languageOptions"
              :option-config="{ label: 'language', value: 'id' }"
              :isMobileCanSearch="isMobileCanSearch"
            ></owl-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('personInfo.fkStudentAppCountryIdBirth')"
            prop="fkStudentAppCountryIdBirth"
            :rules="rules.needFiledRule"
          >
            <owl-select
              :disabled="isDisabled"
              v-model="stuInfoFormData.fkStudentAppCountryIdBirth"
              :options="countryOptions"
              :option-config="{ label: 'name', value: 'id' }"
            ></owl-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('personInfo.cityBirth')"
            prop="cityBirth"
            :rules="rules.needFiledRuleInput"
          >
            <owl-input
              :disabled="isDisabled"
              v-model="stuInfoFormData.cityBirth"
            ></owl-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="sub-option-area">
        <h4 class="sub-title">{{ t("personInfo.passportInfo") }}</h4>
        <el-row :gutter="10">
          <el-col :span="24" :xs="24">
            <el-form-item
              :label="t('personInfo.isHavePassport')"
              prop="isHavePassport"
              :rules="rules.needFieldRadio"
            >
              <owl-radio
                @change="isHavePassportChange"
                :disabled="isDisabled"
                v-model="stuInfoFormData.isHavePassport"
                :options="[
                  { label: t('common.no'), value: false },
                  { label: t('common.yes'), value: true },
                ]"
                :option-config="{ label: 'label', value: 'value' }"
              ></owl-radio>
            </el-form-item>
          </el-col>
          <template v-if="stuInfoFormData.isHavePassport === true">
            <el-col :span="24" :xs="24">
              <el-form-item
                :label="t('personInfo.fkStudentAppCountryIdPassport')"
                prop="fkStudentAppCountryIdPassport"
                :rules="rules.needFiledRule"
              >
                <owl-select
                  :disabled="isDisabled"
                  v-model="stuInfoFormData.fkStudentAppCountryIdPassport"
                  :options="countryOptions"
                  :option-config="{ label: 'name', value: 'id' }"
                ></owl-select>
              </el-form-item>
            </el-col>
            <el-col :span="24" :xs="24">
              <el-form-item
                :label="t('personInfo.passportIssueDate')"
                prop="passportIssueDate"
                :rules="rules.needFiledRule"
              >
                <owl-input
                  @change="handleIssueDate"
                  v-model="stuInfoFormData.passportIssueDate"
                  :disabled="isDisabled"
                  type="date"
                  :placeholder="t('personInfo.passportIssueDate')"
                ></owl-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" :xs="24">
              <el-form-item
                :label="t('personInfo.passportIssueExpried')"
                prop="passportIssueExpried"
                :rules="rules.needFiledRule"
              >
                <owl-input
                  @change="handleIssueExpried"
                  v-model="stuInfoFormData.passportIssueExpried"
                  :disabled="isDisabled"
                  type="date"
                  :placeholder="t('personInfo.passportIssueExpried')"
                ></owl-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" :xs="24">
              <el-form-item
                :label="t('personInfo.passportNumber')"
                prop="passportNumber"
                :rules="rules.needFiledRule"
              >
                <owl-input
                  v-model="stuInfoFormData.passportNumber"
                  :disabled="isDisabled"
                ></owl-input>
              </el-form-item>
            </el-col>
          </template>
        </el-row>
      </div>
      <!-- 联系负责人 MSO不用显示  -->
      <div class="sub-option-area" v-if="platformType() != 'get_mso' && getAgentId() != -1">
        <h4 class="sub-title">
          <span>{{ t("personInfo.contactPerson") }}</span>
          <span class="text-notice-default">
            {{ t("personInfo.contactNotice") }}
          </span>
          <span class="text-notice-default text-notice-red">
            {{ t("personInfo.atLeastOne") }}
          </span>
        </h4>
        <el-row :gutter="10" v-for="index in [1, 2, 3]" :key="index">
          <el-col :span="16" :xs="24">
            <el-form-item
              :label="t(`personInfo.followUpPhoneNumber${index}`)"
              :prop="`followUpPhoneNumber${index}`"
              :rules="
                index === 1
                  ? [{ validator: checkRegion, trigger: 'change' }, { validator: checkContactPhoneNumber, trigger: 'blur' }, checkAreaCode(stuInfoFormData[`followUpPhoneCode${index}`])]
                  : [{ validator: checkContactPhoneNumber, trigger: 'blur' }, checkAreaCode(stuInfoFormData[`followUpPhoneCode${index}`])]
              "
            >
              <owl-input
                :disabled="isDisabled"
                :characterOnly="true"
                v-model="stuInfoFormData[`followUpPhoneNumber${index}`]"
                :placeholder="t(`personInfo.followUpPhoneNumber${index}`)"
              >
                <owl-select
                  class="stu-info-areacode-select"
                  :disabled="isDisabled"
                  style="min-width: 200px"
                  slot="prepend"
                  :placeholder="t(`personInfo.followUpPhoneCode${index}`)"
                  v-model="stuInfoFormData['followUpPhoneCode' + index]"
                  :options="regionNumOptions"
                  :option-config="{ label: 'fullName', value: 'phoneRegion' }"
                />
              </owl-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" :xs="24">
            <el-form-item
              :label="t(`personInfo.followUpEmail${index}`)"
              :prop="'followUpEmail' + index"
              :rules="index == 1 ? [
                rules.needFiledRule,
                { validator: checkContactEmail, trigger: 'blur' },
                {
                  validator:rulesEmail,
                  trigger: ['blur', 'change'],
                },
              ]: [{
                validator:rulesEmail,
                trigger: ['blur', 'change'],
              },{ validator: checkContactEmail, trigger: 'blur' }]"
            >
              <span slot="label">
                <span>{{t(`personInfo.followUpEmail${index}`)}}</span>
                <span class="moreEmailTips-class" v-show="index==3">{{t('stuManagement.moreEmailTips')}}</span>
                <span style="color:red;margin-left:5px;font-size:12px">{{t('stuManagement.notStudentEmail')}}</span>
              </span>
              <owl-input
                :disabled="isDisabled"
                v-model="stuInfoFormData['followUpEmail' + index]"
              ></owl-input>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <owl-btn-save
        @save="handleSave"
        v-if="!isDisabled"
        @delete="cancelEditing"
        :loading="saveLoading"
        show-save-temporal
        :show-delete="stuid() > 0"
        @saveTemporal="handleSaveTemporal"
      ></owl-btn-save>
    </el-form> 
  </div>
</template>
<script>
import i18n from "@/locale/index";
import mixin from "@/utils/mixin";
import OwlSelect from "/packages/components/OwlSelect.vue";
import OwlInput from "/packages/components/OwlInput.vue";
import OwlRadio from "/packages/components/OwlRadio.vue";
import OwlBtnSave from "/packages/components/OwlBtnSave.vue";
import OwlBtnSet from "/packages/components/OwlBtnSet.vue";
import {
  getStateOptions,
  getCityOptions,
  getRegionOptions,
  getPhoneRegions,
  getStudentAncestry,
  getModerLanguageData,
  getAreaRegionByCountryId,
  saveOrUpdateStudentInFoData,
  saveOrUpdateOnlineStudentInFoData,
  getIsUseSharedPathApi
} from "@/api/common.js";
import { EmailReg,checkEmailList,checkEmail2 } from '@/utils/tool'
export default {
  name: "StudentInfo",
  components: { OwlSelect, OwlInput, OwlRadio, OwlBtnSave, OwlBtnSet },
  inject: ["stuid", "ctryOptions", "getAgentId", "hideSubmitBtn", "platformType", "platformLimitList", "issueInnerAccount"],
  mixins: [mixin, i18n],
  props: {
    studentAppInFoDto: {
      type: Object,
      default: () => {
        return {};
      },
    },
    remark: {
      type: String,
      default: '',
    },
    saveApi: {
      type: String,
      default: 'saveOrUpdateStudentInFoData'
    },
    agentContactEmail: {
      type: String,
      default: ''
    },
    bmsLoginId: {
      type: String,
      default: ''
    }
  },
  watch: {
    bmsLoginId(newVal) {
      if(newVal){
        this.validateShareParthRequired();
      }
    },
  },
  data() {
    return {
      birthdayFlag:false,// 出生日期提示控制
      shareParthRequired: false, // 验证共享链接是否必填
      saveLoading: false,
      areaOption: [], // 英国区域
      stateOptions: [], // 省份下拉数据
      cityOptions: [], // 城市下拉数据
      regionOptions: [], // 区下拉数据
      regionNumOptions: [], // 区号下拉数据
      ancestryOptions: [], // 民族血统
      languageOptions: [], // 语言
      stuInfoFormRules: {},
      stuInfoFormData: {
        remark: "",
        nameZh: "",
        lastName: "", // 姓
        firstName: "", // 名
        gender: "", // 性别
        birthday: "", // 生日
        phoneRegion: "",
        phoneNumber: "",
        email: "",
        title: null, // 称谓
        emailPassword: "",
        fkStudentAppCountryIdFrom: null, // 目前所在国家/地区
        fkAreaRegionIdFrom: null,
        fkAreaStateIdFrom: null,
        fkAreaCityIdFrom: null,
        fkAreaCityDivisionIdFrom: null,
        isLivedSinceBirth: true, // 是否自出生便在此居住 // 0否1是
        fkStudentAppCountryIdLive: null, // 目前所在国家/地区
        migrationTime: "", // 移居时间
        stayingRightTime: "", // 取居留权时间
        contactPostcode: "",
        contactAddress1: "",
        contactAddress2: "",
        fkStudentAppCountryId: null, // 学生国籍
        fkStudentAppAncestryId: null, // 学生血统
        fkStudentAppMotherLanguageId: null, // 学生母语
        fkStudentAppCountryIdBirth: null, // 出生国家
        cityBirth: "", // 出生城市
        isHavePassport: false, // 是否有护照
        fkStudentAppCountryIdPassport: null, // 签发国家或地区
        passportIssueDate: "", // 签发日期
        passportIssueExpried: "", // 有效期至
        passportNumber: "", // 护照号码
        followUpPhoneCode1: "",
        followUpPhoneNumber1: "", // 跟进人电话号码1
        followUpEmail1: "", // 跟进人电邮地址1
        followUpPhoneCode2: "",
        followUpPhoneNumber2: "",
        followUpEmail2: "",
        followUpPhoneCode3: "",
        followUpPhoneNumber3: "",
        followUpEmail3: "",
        id: null,
        status: 0,
        fkAgentId: null,
        sharedPath:"",
      },
      birthDayPickOptions: {
        shortcuts: [
          {
            text: (()=>{
              const date = new Date()
              date.setFullYear(date.getFullYear() - 18)
              return date.getFullYear()+'-01-01'
            })(),
            onClick(picker){
              const date = new Date()
              date.setFullYear(date.getFullYear() - 18)
              date.setMonth(0)
              date.setDate(1)
              picker.$emit('pick', date)
            }
          },
          {
            text: (()=>{
              const date = new Date()
              date.setFullYear(date.getFullYear() - 22)
              return date.getFullYear()+'-01-01'
            })(),
            onClick(picker){
              const date = new Date()
              date.setFullYear(date.getFullYear() - 22)
              date.setMonth(0)
              date.setDate(1)
              picker.$emit('pick', date)
            }
          },
        ]
      },
      fkPlatformType:this.platformType(),
    };
  },
  computed: {
    getTitleOptions() {
      const femaleTitleOptions = [{ value: 1, label: "Mr. 先生" }];
      const maleTitleOptions = [
        { value: 0, label: "Ms 女士" },
        { value: 2, label: "Miss 小姐" },
        { value: 3, label: "Mrs 夫人" },
      ];
      let titleOptions = [...femaleTitleOptions, ...maleTitleOptions];
      switch (this.stuInfoFormData.gender) {
        case 0:
          titleOptions = maleTitleOptions;
          break;
        case 1:
          titleOptions = femaleTitleOptions;
          break;
        default:
      }
      return titleOptions || [];
    },
    countryOptions() {
      return this.ctryOptions();
    },
    saveOrUpdateApi(){
      switch (this.saveApi){
        case 'saveOrUpdateStudentInFoData': return saveOrUpdateStudentInFoData;
        case 'saveOrUpdateOnlineStudentInFoData': return saveOrUpdateOnlineStudentInFoData;
        default: return saveOrUpdateStudentInFoData;
      }
    },
    isDisabled(){
      return !this.isEditing('studentInfo');
    },
    // 手机端显示可搜索下拉
    isMobileCanSearch() {
      return this.platformLimitList().includes('get_scholarship');
    },
  },
  mounted() {
    !this.stuid() && this.switchEditStatus('studentInfo');
    this.stuInfoFormRules = {
      followUpEmail1: [
        {
          required: true,
          message: this.t("validate.requiredField"),
          trigger: "blur",
        },
        {
          validator:this.rulesEmail,
          trigger: ['blur', 'change'],
        },
      ],
      email: [
        { required: true, message: this.t('validate.requiredField'), trigger: 'blur' },
        // { validator: this.checkStudentEmail, trigger: 'blur' },
        {
          type: 'email',
          message: this.t('validate.emailValid'),
          trigger: ['blur', 'change'],
        },
      ],
    };
    this.$nextTick(() => {
      if (this.$refs.stuInfoform) {
        this.$refs.stuInfoform.clearValidate();
      }
    });
    this.getPhoneRegions();
    this.getStudentAncestry();
    this.getModerLanguageData();
    this.validateShareParthRequired();
  },
  methods: {
    // 验证共享链接是否必填
    async validateShareParthRequired(){
      this.shareParthRequired=false;
      console.log(this.bmsLoginId)
      if(this.bmsLoginId){
        const {data}=await getIsUseSharedPathApi(this.bmsLoginId);
        this.shareParthRequired=data.data;
      }
    },
    checkContactPhoneNumber(rule, value, callback) {
      const stuPhoneNumber = this.stuInfoFormData.phoneNumber;
      if (stuPhoneNumber && stuPhoneNumber === value) {
        return callback(new Error(this.t('validate.stuPhoneNumberValid')))
      } else {
        callback()
      }
    },
    checkStudentEmail(rule, value, callback) {
      const stuFormData = this.stuInfoFormData;
      const followUpEmail1 = stuFormData.followUpEmail1;
      const followUpEmail2 = stuFormData.followUpEmail2;
      const followUpEmail3 = stuFormData.followUpEmail3;
      if (value && (followUpEmail1 === value || followUpEmail2 === value || followUpEmail3 === value)) {
        return callback(new Error(this.t('validate.stuEmailValid')));
      }
    },
    checkContactEmail(rule, value, callback) {
      const email = this.stuInfoFormData.email;
      if (email && email === value) {
        return callback(new Error(this.t('validate.stuEmailValid')))
      } else {
        callback();
      }
    },
    checkAddrLength(rule, value, callback) {
      if (value && value.length > 45) {
        return callback(new Error(this.t('validate.checkRgstAddress')))
      }
      callback();
    },
    // 验证邮箱（多个邮箱）
    rulesEmail(rule, value, callback) {
      const emailArr = checkEmailList(value);
      let isEmail = true;
      if(rule.fullField==='followUpEmail3'){
        if (emailArr && emailArr.length) {
          for (const key of emailArr) {
            if (!key||!checkEmail2(key)) {
              isEmail = false;
                break;
            }
          }
        } else {
          if (!checkEmail2(value)) {
            isEmail = false;
          }
        }
      }else{
        if (value&&!checkEmail2(value)) {
          isEmail = false;
        }
      }
      if(!value&&rule.fullField!=='followUpEmail1'){
        isEmail=true;
      }
      if (isEmail) {
        callback();
      } else {
        callback(new Error(this.t('validate.emailValid')));
      }
    },
    getUpdate() {
      this.switchEditStatus('studentInfo', this.stuInfoFormData);
      this.onFormStatusChanged()
    },
    async getAreaData(countryId){
      if(countryId != 228) return this.stuInfoFormData.fkAreaRegionIdFrom = null;
      const area = this.countryOptions.find(item=>item.id == countryId)
      if(!area || !area.fkAreaCountryId){
        return;
      }
      const res = await getAreaRegionByCountryId(area.fkAreaCountryId);
      this.areaOption = res.data.datas;
    },
    async getStateOptions(country) {
      const { data } = await getStateOptions(country);
      this.stateOptions = data.datas;
    },
    async getCityOptions(state) {
      if (!state || isNaN(state)) return;
      const { data } = await getCityOptions(state);
      this.cityOptions = data.datas;
    },
    async getRegionOptions(city) {
      const { data } = await getRegionOptions(city);
      this.regionOptions = data.datas;
    },
    async getPhoneRegions() {
      const { data } = await getPhoneRegions();
      this.regionNumOptions = data.datas;
    },
    changeGender(gender) {
      this.stuInfoFormData.title = gender === 1 ? 1 : 2;
    },
    // 国家change事件
    handleSelectCountry(country) {
      this.stuInfoFormData.fkAreaStateIdFrom = '';
      this.stuInfoFormData.fkAreaCityIdFrom = '';
      this.stuInfoFormData.fkAreaCityDivisionIdFrom = '';
      this.stuInfoFormData.fkAreaRegionIdFrom = '';
      this.stateOptions = [];
      this.cityOptions = [];
      this.regionOptions = [];
      if (country) {
        this.getAreaData(country);
        this.getStateOptions(country);
        if (country === 44) {
          this.selectChinese();
        } else if (country === 92) {
          this.selectHongKong();
        }
      }
    },
    // 国家或地区为中国，则某些字段默认中国
    selectChinese() {
      const stuInfoFormData = this.stuInfoFormData;
      if (!stuInfoFormData.fkStudentAppCountryId) {
        stuInfoFormData.fkStudentAppCountryId = 44;
      }
      if (!stuInfoFormData.fkStudentAppAncestryId) {
        stuInfoFormData.fkStudentAppAncestryId = 7;
      }
      if (!stuInfoFormData.fkStudentAppMotherLanguageId) {
        stuInfoFormData.fkStudentAppMotherLanguageId = 1;
      }
      if (!stuInfoFormData.fkStudentAppCountryIdBirth) {
        stuInfoFormData.fkStudentAppCountryIdBirth = 44;
      }
    },
    selectHongKong() {
      const stuFormData = this.stuInfoFormData;
      if (!stuFormData.phoneRegion) {
        stuFormData.phoneRegion = '852';
      }
      if (!stuFormData.contactPostcode) {
        stuFormData.contactPostcode = '000000'
      }
      if (!stuFormData.fkStudentAppCountryId) {
        stuFormData.fkStudentAppCountryId = 44;
      }
      if (!stuFormData.fkStudentAppAncestryId) {
        stuFormData.fkStudentAppAncestryId = 7;
      }
      if (!stuFormData.fkStudentAppMotherLanguageId) {
        stuFormData.fkStudentAppMotherLanguageId = 3;
      }
      if (!stuFormData.fkStudentAppCountryIdBirth) {
        stuFormData.fkStudentAppCountryIdBirth = 92;
      }
      if (!stuFormData.cityBirth) {
        stuFormData.cityBirth = 'Hong Kong';
      }
    },
    handleSelectState(state) {
      this.stuInfoFormData.fkAreaCityIdFrom = null;
      this.stuInfoFormData.fkAreaCityDivisionIdFrom = null;
      this.cityOptions = [];
      this.regionOptions = [];
      if (!state || isNaN(state)) {
        return;
      }
      if (this.stateOptions && this.stateOptions.length !== 0) {
        this.getCityOptions(state);
      }
    },
    handleSelectCity(city) {
      this.stuInfoFormData.fkAreaCityDivisionIdFrom = "";
      this.regionOptions = [];
      if (!city || isNaN(city)) {
        return;
      }
      if (this.cityOptions && this.cityOptions.length !== 0) {
        this.getRegionOptions(city);
      }
    },
    // 是否有护照改变
    isHavePassportChange(val) {
      if (!val) {
        this.stuInfoFormData.passportNumber = "";
        this.stuInfoFormData.passportIssueExpried = "";
        this.stuInfoFormData.passportIssueDate = "";
      }
    },
    setInitData(newVal) {
      if (!newVal || !newVal.id) {
        this.switchEditStatus('studentInfo', this.stuInfoFormData);
        return;
      }
      // 过滤一遍数据
      for (let key in this.stuInfoFormData) {
        switch (key) {
          case "birthday":
            this.stuInfoFormData.birthday = newVal.birthday && newVal.birthday.split(" ")[0];
            break;
          case "fkStudentAppCountryIdLive":{
            let fkStudentAppCountryIdLive = Number(this.stuInfoFormData.fkStudentAppCountryIdLive)
            if (!isNaN(fkStudentAppCountryIdLive)) {
              this.stuInfoFormData.fkStudentAppCountryIdLive = fkStudentAppCountryIdLive;
            }
            break;
          }
          case "isHavePassport":{
            this.stuInfoFormData.isHavePassport = Boolean(newVal.isHavePassport);
            break;
          }
          case "fkStudentAppCountryIdFrom":
          case "fkAreaStateIdFrom": 
          case "fkAreaCityIdFrom":
          case "fkAreaRegionIdFrom":
          case "fkAreaCityDivisionIdFrom": {
            const val = Number(newVal[key]);
            if (!newVal[key] || (typeof newVal[key] === 'string' && !newVal[key].trim()) || isNaN(val)) {
              this.stuInfoFormData[key] = newVal[key];
            } else {
              this.stuInfoFormData[key] = val;
            }
            break;
          }
          default:{
            this.stuInfoFormData[key] = newVal[key];
          }
        }
      }
      const ctryData = this.stuInfoFormData.fkStudentAppCountryIdFrom;
      const stateData = this.stuInfoFormData.fkAreaStateIdFrom;
      const cityData = this.stuInfoFormData.fkAreaCityIdFrom;
      // 加载 省市区下拉数据
      ctryData && !isNaN(Number(ctryData)) && this.getAreaData(ctryData);
      ctryData && !isNaN(Number(ctryData)) && this.getStateOptions(ctryData)
      stateData && !isNaN(Number(stateData)) && this.getCityOptions(stateData);
      cityData && !isNaN(Number(cityData)) && this.getRegionOptions(cityData);
    },
    // 验证生日不得小于12岁(紧提示)
    checkBirthDay(rule, date, callback) {
      const nowDateY = new Date().getFullYear();
      const selDateY = new Date(date).getFullYear();
      if (nowDateY - selDateY < 12) {
          if(!this.birthdayFlag){
          this.birthdayFlag=true;
          setTimeout(()=>{
            this.$msge({
              message: this.t("validate.birthdayValid"),
              type: "warning",
            });
            this.birthdayFlag=false;
          },200)
        }
        callback();
      } else {
        callback();
      }
    },
    async getStudentAncestry() {
      const { data } = await getStudentAncestry();
      this.ancestryOptions = data.datas;
    },
    async getModerLanguageData() {
      const { data } = await getModerLanguageData();
      this.languageOptions = data.datas;
    },
    handleIssueDate(value) {
      const dateStr = this.stuInfoFormData.passportIssueExpried;
      const issueDate = new Date(value).getTime();
      const issueExpriedDate = new Date(dateStr).getTime();
      if (dateStr) {
        if (issueDate > issueExpriedDate) {
          this.$alrt(
            this.t("validate.issueDateValid", { issueDate: "(" + value + ")" }),
            "",
            {
              confirmButtonText: this.t("common.confirm"),
            }
          );
          this.stuInfoFormData.passportIssueDate = "";
        }
      }
    },
    handleIssueExpried(value) {
      if (value) {
        const dateStr = this.stuInfoFormData.passportIssueDate;
        const issueDate = new Date(dateStr).getTime();
        let date = new Date(dateStr);
        // date.setDate(date.getDate() + 1);
        date.setMonth(date.getMonth() + 6);
        const leastDateStamp = date.getTime();
        const issueExpriedDate = new Date(value).getTime();
        if (dateStr) {
          if (issueDate > issueExpriedDate) {
            // 签证日期不能在有效日期后
            this.$alrt(
              this.t("validate.issueDateValid", {expriDate: "(" + value + ")",}),
              "",
              {confirmButtonText: this.t("common.confirm"),}
            );
            this.stuInfoFormData.passportIssueExpried = "";
            // 在有效期要在半年后
          } else if (issueExpriedDate < leastDateStamp) {
            this.$alrt(this.t("validate.checkPasswordTime"), "", {
              confirmButtonText: this.t("common.confirm"),
            });
            this.stuInfoFormData.passportIssueExpried = "";
          }
        }
      }
    },
    async checkFormStatus() {
      const validate = await new Promise((res)=>{
        this.$refs.stuInfoform.validate((val)=>{
          res(val)
        })
      })
      const isEdit = this.stuid() && this.editList.length === 0;
      return {
        isEdit,
        validate: validate,
        error: this.t('validate.personalInfo')
      }
    },
    async handleSaveTemporal(){
      // 这些字段必填才可以保存
      let error = false;
      for await (let item of ['nameZh', 'lastName', 'firstName']){
        const valid = await new Promise(res=>{
          this.$refs.stuInfoform.validateField(item,(error)=>{
            res(error)
          })
        })
        if(valid) error = true;
      }
      if(error) return;
      if (this.saveApi === 'saveOrUpdateOnlineStudentInFoData' && !this.agentContactEmail) {
        await this.$alrt(this.t("validate.agentEmailRequire"), "", {
          distinguishCancelAndClose: true,
          confirmButtonText: this.t("common.confirm"),
          cancelButtonText: '',
        });
        return;
      }
      await this.$alrt(this.t("applyCourse.saveTemporalTips1"), "", {
          distinguishCancelAndClose: true,
          confirmButtonText: this.t("common.confirm"),
          cancelButtonText: '',
        })
      try {
        this.stuInfoFormData.id = this.stuid() || ''; // 空字符为新增
        this.stuInfoFormData.fkAgentId = this.stuInfoFormData.fkAgentId || this.getAgentId();
        this.saveLoading = true;
        const { data } = await this.saveOrUpdateApi(Object.assign({agentContactEmail:this.agentContactEmail}, this.stuInfoFormData))
        this.$msge({
          message: this.t("common.saveSucc"),
          type: "success",
        });
        this.stuInfoFormData.id = this.stuid() || data.data.id;
        this.switchEditStatus('studentInfo')
        this.onFormStatusChanged()
        const stuName = {
          lastName: this.stuInfoFormData.lastName,
          firstName: this.stuInfoFormData.firstName,
        }
        this.$emit('on-student-update', {stuName, birthday: this.stuInfoFormData.birthday, ret: data.data})
        // 增加个人信息完成状态和课程申请未完成状态
        if(data.data) {
          this.addCompletedStatus();
          this.addUncompletedStatus('applicationSch');
        }
      } catch(e) {
        e.message && this.$msge({
          message: e.message,
          type: "error",
        });
      }
      this.saveLoading = false;
    },
    async handleSave() {
      if(!this.stuInfoFormData.fkAreaCityDivisionIdFrom) {
        this.stuInfoFormData.fkAreaCityDivisionIdFrom = '';
      }
      // 如果是iae表单
      if(this.saveApi === 'saveOrUpdateOnlineStudentInFoData'){
        if(!this.agentContactEmail) {
          this.$msge.error(this.t("validate.agentEmailRequire"))
          return;
        }
        if(!EmailReg.test(this.agentContactEmail)) {
          this.$msge.error(this.t("validate.agentEmailValid"))
          return;
        }
      }
      // 这些字段必填才可以保存
      let error = false;
      // for await (let item of ['nameZh', 'lastName', 'firstName', 'phoneNumber', 'gender', 'title', 'followUpPhoneNumber1']){
      //   const valid = await new Promise(res=>{
      //     this.$refs.stuInfoform.validateField(item,(error)=>{
      //       res(error)
      //     })
      //   })
      //   if(valid) error = true;
      // }
      this.$refs.stuInfoform.validate((valid) => {
        if (!valid) {
          error = true;
        }
      });
      if(error) return;
      try {
        this.stuInfoFormData.id = this.stuid() || ''; // 空字符为新增
        this.stuInfoFormData.fkAgentId = this.stuInfoFormData.fkAgentId || this.getAgentId();
        this.saveLoading = true;
        const res = await this.saveOrUpdateApi(Object.assign({agentContactEmail:this.agentContactEmail}, this.stuInfoFormData))
        this.$msge({
          message: this.t("common.saveSucc"),
          type: "success",
        });
        this.switchEditStatus('studentInfo')
        this.onFormStatusChanged()
        const stuName = {
          lastName: this.stuInfoFormData.lastName,
          firstName: this.stuInfoFormData.firstName,
        }
        this.$emit('on-student-update', {stuName, birthday: this.stuInfoFormData.birthday, ret: res.data.data})
        // 增加个人信息完成状态和课程申请未完成状态
        if(res.data.data) {
          this.addCompletedStatus();
          this.addUncompletedStatus('applicationSch');
        }
      } catch(e) {
        e.message && this.$msge({
          message: e.message,
          type: "error",
        });
      }
      this.saveLoading = false;
      
    },
    async autoSave(){
      if(this.isDisabled) return;
      await this.handleSave()
    },
    checkRegion(rule, value, callback) {
      if (this.stuInfoFormData.followUpPhoneCode1 == "") {
        return callback(
          new Error(
            this.t("validate.requiredField") +
              this.t("personInfo.followUpPhoneCode")
          )
        );
      }
      if (this.stuInfoFormData.followUpPhoneNumber1 == ""){
        return callback(
          new Error(
            this.t("validate.requiredField") +
              this.t("stuManagement.phoneNumber")
          )
        );
      }
      callback()
    },
    checkRegionForPhoneR(rule, value, callback) {
      // const stuFormData = this.stuInfoFormData;
      // const contactsNumber = [stuFormData.followUpPhoneNumber1, stuFormData.followUpPhoneNumber2, stuFormData.followUpPhoneNumber3];
      // if (value && contactsNumber.includes(value)) {
      //   callback(new Error(this.t('validate.stuPhoneNumberValid')));
      // } 
      if (this.stuInfoFormData.phoneRegion == "") {
        callback(
          new Error(
            this.t("validate.requiredField") +
              this.t("stuManagement.phoneRegion")
          )
        );
      } else {
        callback();
      }
    },
    // 取消编辑状态
    cancelEditing(){
      this.$confIssue(this.t("validate.cancelEdit"), "", {
        distinguishCancelAndClose: true,
        confirmButtonText: this.t("common.confirm"),
        cancelButtonText: this.t("common.cancel"),
      }).then(()=>{
        const backup = this.getEditBackupByKey('studentInfo')
        this.$refs.stuInfoform.clearValidate();
        backup && this.setInitData(backup)
        this.switchEditStatus('studentInfo')
        this.onFormStatusChanged()
      })
    },
    onFormStatusChanged(){
      this.$emit('on-porccess-changed', this.editList.length ? 0 : 100)
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-label{
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 24px);
  .label{
    font-size: 12px;
  }
  .tip{
    color: red;
  }
}
.moreEmailTips-class{
  color: #909999;
  font-size:10px;
}
@media screen  and (max-width: 768px) {
  .stu-info-areacode-select {
    min-width: 4rem !important;
  }
}
</style>
