<template>
  <svg
    t="1623382533675"
    v-show="loading"
    class="icon"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="1627"
    width="200"
    height="200"
  >
    <path
      d="M307.146667 821.333333a39.253333 39.253333 0 1 0 53.333333 14.346667 39.253333 39.253333 0 0 0-53.333333-14.346667zM496 874.666667a34.88 34.88 0 1 0 47.68 12.746666A34.826667 34.826667 0 0 0 496 874.666667zM685.12 828.586667a30.346667 30.346667 0 1 0 41.386667 11.093333 30.346667 30.346667 0 0 0-41.386667-11.093333zM824.906667 697.333333a24.106667 24.106667 0 1 0 32.906666 8.853334 24.16 24.16 0 0 0-32.906666-8.853334zM879.573333 518.666667a14.826667 14.826667 0 1 0 20.32 5.333333 14.88 14.88 0 0 0-20.32-5.333333zM168.693333 680.746667a43.2 43.2 0 1 0 59.04 16 43.253333 43.253333 0 0 0-59.04-16zM161.28 294.613333a58.026667 58.026667 0 1 0 79.306667 21.333334 58.08 58.08 0 0 0-79.306667-21.333334zM182.773333 506.986667a49.12 49.12 0 1 0-17.973333 67.093333 49.173333 49.173333 0 0 0 17.973333-67.093333zM476.586667 94.08a74.026667 74.026667 0 1 0 101.333333 27.093333 74.026667 74.026667 0 0 0-101.333333-27.093333zM294.24 151.573333a65.386667 65.386667 0 1 0 89.333333 23.946667 65.386667 65.386667 0 0 0-89.333333-23.946667zM742.72 282.186667a85.333333 85.333333 0 1 0-116.693333-31.253334 85.333333 85.333333 0 0 0 116.693333 31.253334zM920.053333 296.853333a96 96 0 1 0-35.146666 131.146667 96 96 0 0 0 35.146666-131.146667z"
      p-id="1628"
    ></path>
  </svg>
</template>
<script>
export default {
  name: "LoadingIcon",
  props: {
    fill: String,
    loading: Boolean,
  },
};
</script>
