import request from "@/utils/request";
import axios from "axios";
const CancelToken = axios.CancelToken;
// 国家
export const getAppCountry = () =>
  request({
    url: "/issue/student/getAppCountry",
    method: "get",
  });
// 省份
export const getStateOptions = (id) =>{
  return request({
    url: `/appInstitution/areaState/getAreaIssue/${id}`,
    method: "post",
  });
}

// 城市
export const getCityOptions = (id) =>
  request({
    url: `/appInstitution/areaCity/getByFkAreaStateId/${id}`,
    method: "get",
  });
// 区
export const getRegionOptions = (id) =>
  request({
    url: `/appInstitution/areaCity/getDivisionByFkAreaCityId/${id}`,
    method: "get",
  });

// 电话区号
export const getPhoneRegions = () =>
  request({
    url: "/issue/studentCountry/getPhoneRegion",
    method: "get",
  });

// 民族血统
export const getStudentAncestry = () =>
  request({
    url: "/issue/student/getStudentAncestry",
    method: "get",
  });

// 母语下拉
export const getModerLanguageData = () =>
  request({
    url: "/issue/student/getModerLanguageData",
    method: "get",
  });

// 根据国家查找学校
export const getInstitutionByCountry = (fkAreaCountryId) =>
  request({
    url: "/appInstitution/institution/getInstitutionByCountry",
    method: "post",
    data: { fkAreaCountryId },
  });

// 课程专业等级
export const getMajorLevelSelect = async () => {
  const ret = localStorage.getItem('majorLevelDatas')
  if(ret){
    let data = JSON.parse(ret);
    if(data && data.length) {
      return {
        data: {
          code: '1003',
          datas: data 
        }
      }
    }
  } 
  const {data} = await request({
    url: "/appInstitution/majorLevel/getMajorLevelSelect",
    method: "post",
  });
  const ret2 = data.datas.map(item=>({
    id: item.id,
    levelName: item.levelName
  }))
  localStorage.setItem('majorLevelDatas', JSON.stringify(ret2))
  return {
    data:{
      code: '1003',
      datas: ret2
    }
  }
}
// 获取课程专业等级（新）
export const getIssueMajorLevelListApi = () => 
  request({
    url: "/appInstitution/majorLevel/getIssueMajorLevelList ",
    method: "get"
  });

// 课程
export const getCourseToIssue = (fk_institution_id, fkMajorLevelId) =>
  request({
    url: "/appInstitution/getCourses/getCourseToIssue",
    method: "get",
    params: { fk_institution_id, fkMajorLevelId },
  });
/**
 * 教育背景
 * 获取国内外学校
 */
export const getStuEduSchoolData = (countryId, stateId, cityId, selectStatus) =>
  request({
    url: "/appInstitution/institution/getInstitutionDataToIssue",
    method: "get",
    params: { countryId, stateId, cityId, selectStatus, issueSelectStatus: 0 },
  });

// 教育背景的保存和更新
export const saveStuEduSchoolData = (data) =>
  request({
    url: "/issue/studentEducatio/getSaveOrUpdatemStudentEducation",
    method: "post",
    data,
  });

// 返回教育背景数据
export const getStuEduBackgroundDataApi = (fkStudentId) =>
  request({
    url: "/issue/studentEducatio/getEduData",
    method: "get",
    params: { fkStudentId }
  })
/**
 * 工作经验
 */
// 工作经验的保存和更新
export const saveOrUpdateStudentWork = (data) =>
  request({
    url: "/issue/studentWork/saveOrUpdateStudentWork",
    method: "post",
    data,
  });
/**
 * 附件上传
 */
// 获取信息收集内容配置
export const getFromConfig = (countryId, majorLevelId, institutionId) =>
  request({
    url: "/issue/studentInstitution/getFromConfig",
    method: "get",
    params: { countryId, majorLevelId, institutionId },
  });
// 材料上传中的更新文件
export const updateStudentFileApi = (fkStudentId, fileType, newFkGuId, oldFkGuId) =>
  request({
    url: "/issue/student/updateStudentFile",
    method: "post",
    params: { fkStudentId, fileType, newFkGuId, oldFkGuId },
  });
// 材料上传中的文件保存
// StudentInstitutionCourseId 不是必传
export const saveStudentFileApi = (fileType, fkGuId, fkStudentId, StudentInstitutionCourseId) =>
  request({
    url: "/issue/student/saveStudentFile",
    method: "post",
    params: { fileType, fkGuId, fkStudentId, StudentInstitutionCourseId },
  });

// 材料删除
export const deleteFileApi = (fkGuId) =>
  request({
    url: "/issue/student/deleteFile",
    method: "post",
    params: { fkGuId },
  });
// 材料上传文件回显
export const getStudentFileDataApi = (fkStudentId) =>
  request({
    url: "/issue/student/getStudentFileData",
    method: "get",
    params: { fkStudentId },
  });
// 推荐信或教育背景资料预览
export const getIssueFilePreviewApi = (fileKey) =>
  request({
    url: "/appFile/getIssueFilePreview",
    method: "get",
    params: {fileKey},
  })
/**
 * 学生父母信息保存/更新
 */
export const saveOrUpdateStudentParentData = (data) =>
  request({
    url: "/issue/student/saveOrUpdateStudentParentData",
    method: "post",
    data,
  });

// 学生注册
export const saveOrUpdateStudentInFoData = (data) =>
  request({
    url: "/issue/student/saveOrUpdateStudentInFoData",
    method: "post",
    data,
  });
export const saveOrUpdateOnlineStudentInFoData = (data) =>
request({
  url: "/issue/student/saveOrUpdateOnlineStudentInFoData",
  method: "post",
  data,
});
// 个人信息
export const saveOrUpdateStudentData = (data) =>
  request({
    url: "/issue/student/saveOrUpdateStudentData",
    method: "post",
    data,
  });
// 背景资料
export const saveOrUpdateTagerCountry = (data) =>
  request({
    url: "/issue/studentCountry/saveOrUpdateTagerCountry",
    method: "post",
    data,
  });
/**
 * 课程申请
 */
// 院校申请
export const saveOrUpdateCourse = (data) =>
  request({
    url: "/issue/studentInstitutionCourse/saveOrUpdateCourse",
    method: "post",
    data,
  });

export const saveOrUpdateTempCourse = (data) =>
  request({
    url: "/issue/studentInstitutionCourse/saveOrUpdateTempCourse",
    method: "post",
    data,
  });
// 课程
export const getCourseSchoolDataApi = (countryId, stateId, cityId, selectStatus) =>
  request({
    url: "/appInstitution/institution/getInstitutionDataToIssue",
    method: "get",
    params: { countryId, stateId, cityId, selectStatus,  issueSelectStatus: 0 } //issueSelectStatus写死
  })
// 课程新的框架查询
export const newGetInstitutionDataToIssue = (countryId, selectStatus, issueSelectStatus = 0) => request({
    url: '/appInstitution/institution/getInstitutionDataToIssue',
    method: "get",
    params: { countryId, selectStatus, issueSelectStatus }
  })
// 目标国家
export const getCountryToIssue = () =>
  request({
    url: "/appInstitution/areaCountry/getCountryToIssue",
    method: "get",
  });
// 删除目的国家
export const deleteCountryToIssue = (id, fkAreaCountryId, fkStudentId) =>
  request({
    url: "/issue/studentCountry/deleteTagerCountry",
    method: "post",
    params: { id, fkAreaCountryId, fkStudentId }
  })

// 课程申请用国家
export const getCountryListFkCountryIdData = (fkStudentId) =>
  request({
    url: "/issue/studentCountry/getCountryListFkCountryIdData",
    method: "get",
    params: { fkStudentId },
  });
// 获取信息收集内容配置
export const getInstitutionCharacterItem = (countryId, institutionId) =>
  request({
    url: "/issue/studentInstitution/getInstitutionCharacterItem",
    method: "get",
    params: { countryId, institutionId },
  });
// 考试成绩
export const saveOrUpdateStudentScore = (data) =>
  request({
    url: "/issue/studentScore/saveOrUpdateStudentScore",
    method: "post",
    data,
  });
// 专业资格
export const saveOrUpdateQualification = (data) =>
  request({
    url: "/issue/studentQualification/saveOrUpdateQualification",
    method: "post",
    data,
  });
// 推荐人
export const saveOrUpdateStudentRef = (data) =>
  request({
    url: "/issue/studentReference/saveOrUpdateStudentRef",
    method: "post",
    data,
  });
/**
 * 后续安排（学生寄宿）
 */
//  学生寄宿信息保存/更新
export const saveOrUpdateStudentAccommData = (data) =>
  request({
    url: "/issue/student/saveOrUpdateStudentAccommData",
    method: "post",
    data,
  });

// 称谓
export const getIssueTitle = () =>
  request({
    url: "/issue/studentReference/getIssueTitle",
    method: "get",
  });

// 获取学生申请所有表单回显
export const getStudentApply = (fkStudentId) =>
  request({
    url: "/issue/student/getStudentApply",
    method: "get",
    params: { fkStudentId },
  });
// 公共表单
export const getStudentApplyOnlineForm = (onlineFormId) => 
  request({
    url: "/issue/student/getStudentApplyOnlineForm",
    method: "get",
    params: { onlineFormId },
  })
// 绑定代理的接口
export const getSetIssueUserAgent = (receiptCode) => 
  request({
    url: "/issue/student/getSetIssueUserAgent",
    method: 'get',
    params: {receiptCode}
  })
// bms调用学生入学申请页全部表单回显
export const onlyShowStudentApply = (fkStudentId, bmsInfo) => request({
  url: "/issue/student/getStudentApplyForBms",
  method: "get",
  params: { fkStudentId, bmsInfo }
})
// 删除教育背景
export const deleteStudentEducation = (eduId) =>
  request({
    url: "/issue/studentEducatio/deleteStudentEducation?eduId=" + eduId,
    method: "post",
  });
// 删除院校申请
export const deleteStudentCourse = (courseId) =>
  request({
    url: "/issue/studentInstitutionCourse/deleteStudentCourse?courseId=" + courseId,
    method: "post",
  });
// 删除工作校验
export const deleteSudentWord = (workId) =>
  request({
    url: "/issue/studentWork/deleteStudentWork?workId=" + workId,
    method: "post",
  });
// 删除资格证书
export const deleteQualification = (qualId) =>
  request({
    url: "/issue/studentQualification/deleteQualification",
    method: "post",
    params: { qualId },
  });
// 删除语言成绩
export const deleteStudentScore = (scoreId) =>
  request({
    url: "/issue/studentScore/deleteStudentScore",
    method: "post",
    params: { scoreId },
  });
// 删除推荐人
export const deleteStudentRef = (id) =>
  request({
    url: "/issue/studentReference/deleteStudentRef",
    method: "post",
    params: { id },
  });
// 申请页最后一步的提交接口
export const getissueSubmit = (id) =>
  request({
    url: "/issue/student/getissueSubmit",
    method: "post",
    params: { id },
  });

export const getSubmitOnlineForm = (data) => request({
  url: "/issue/student/getSubmitOnlineForm",
  method: 'post',
  data,
  headers: {"content-type": "multipart/form-data"}
})
/**
 * 文件中心
 */
// 单个文件下载
export const downLoadStuFileApi = (data) =>
  request({
    url: "/appFile/downloadIssue",
    // url1: "/appFile/download",
    method: "post",
    data,
    responseType: "blob"
  })

// 文件上传
export const uploadFileApi = (data) => request({
  url: "/appFile/uploadStudent",
  method: "post",
  data: data,
})
// 文件全部打包下载
export const allDownLoadFileApi = (fkStudentId) => request({
  url: "/appFile/issueDownloadList",
  method: "post",
  params: { fkStudentId },
  responseType: "blob"
})
// 获取课程动态表单配置
export const getNavigationList = (params) => request({
  url: "/issue/studentInstitution/getInstitutionCharacterItem",
  method: "GET",
  params: params,
})
/**
 * 帮助中心
 */
// 根据KeyCode获取帮助信息
export const getHelpInfoApi = (keyCode) => request({
  url: `/appHelp/help/getHelpByKeyCode/${keyCode}`,
  method: "get",
  data: { keyCode }
})

// 教育背景成绩类别下拉
export const getScoreTypeApi = async () => request({
  url: "/issue/studentEducatio/getScoreTypeEum",
  method: "get",
})
// {
//   const ret = localStorage.getItem('scoreTypeDatas')
//   if(ret){

//     let data = JSON.parse(ret);
//     return {
//       data: {
//         code: '1003',
//         datas: data.length ? data : [],
//         message: 'storageData',
//       }
//     }
//   } else {
//     const {data} = await request({
//       url: "/issue/studentEducatio/getScoreTypeEum",
//       method: "get",
//     });
//     localStorage.setItem('scoreTypeDatas', JSON.stringify(data.datas))
//     return {
//       data:{
//         code: '1003',
//         datas: data.datas
//       }
//     }
//   }
// }

// 教育背景学历下拉
export const getMajorLevelListApi = () => request({
  url: '/issue/studentEducatio/getEdulevelList',
  method: "get"
})
// 根据教育类型获取学历
export const getEdulevelListByGradeApi = (grade) => request({
  url: '/issue/studentEducatio/getEdulevelListByGrade',
  method: "get",
  params: { grade }
})
// 根据教育类型获取成绩类别
export const getScoreTypeEumByTypeApi = (type) => request({
  url: '/issue/studentEducatio/getScoreTypeEumByType',
  method: "get",
  params: { type }
})
// 判断是否有分科成绩数据(如有则展示分科成绩录入表单)
export const getIsEducationSubjectApi = (scoreType) => request({
  url: '/issue/studentEducatio/getIsEducationSubject',
  method: "get",
  params: { scoreType }
})
// 分数下拉（非分科）
export const selectByConditionTypeApi = (conditionType) => request({
  url: `/appInstitution/academicScoreConfig/selectByConditionType/${conditionType}`,
  method: "get",
})
// 分数下拉（分科）
export const selectSubByConditionTypeApi = (conditionType) => request({
  url: `/appInstitution/academicScoreConfig/selectSubByConditionType/${conditionType}`,
  method: "get",
})
// 科目下拉
export const getScoreSubjectTypeListApi = (scoreType) => request({
  url: `/appInstitution/scoreSubjectType/getScoreSubjectTypeListByScoreType`,
  method: "get",
  params: { scoreType }
})
// 分科数据详情
export const getEducationSubjectInfoApi = (eduId) => request({
  url: `/issue/studentEducatio/getEducationSubjectInfo`,
  method: "get",
  params: { eduId }
})
// 删除文件
export const deleteFileByIdApi = (id) => request({
  url: '/issue/student/deleteFileById?id='+id,
  method: 'post'
})

// 根据课程查出相关链路
export const getMatchCourseNameApi = ({ schId, courseName, stepName, courseId, fkMajorLevelId, cancelBack,facultyId }) => {
  return request({
    url: `/appInstitution/getCourses/getMatchCourseName?schId=${schId}&courseName=${courseName}&stepName=${stepName}&courseId=${courseId}&fkMajorLevelId=${fkMajorLevelId}&facultyId=${facultyId}`,
    cancelToken: new CancelToken(cancelBack)
  })
}
// 单独匹配链路
export const getCourseOnlyMatchApi = ({schId, courseName, stepName, courseId,facultyId}) => {
  return request({
    url: `/appInstitution/getCourses/getMatchCourse?schId=${schId}&courseName=${courseName}&stepName=${stepName}&courseId=${courseId}&facultyId=${facultyId}`
  })
}
// 查看学校是否支持一键，参数是bms学校
export const getIsStartRobotApi = ({fkInstitutionId, fkInstitutionFacultyId = '', fkInstitutionZoneId = ''}) => request({
  url: `/appInstitution/getCourses/getIsStartRobot?fkInstitutionId=${fkInstitutionId}&fkInstitutionFacultyId=${fkInstitutionFacultyId}&fkInstitutionZoneId=${fkInstitutionZoneId}`
})

// 学校学院下拉
export const getInstitutionFaculty = (schId) => request({
  url: '/appInstitution/institution/getInstitutionFaculty?fkInstitutionId='+schId
})

// 学校校区下拉
export const getInstitutionZone = (schId) => request({
  url: '/appInstitution/institution/getInstitutionZone?fkInstitutionId='+schId
})

// 英国的下一级
export const getAreaRegionByCountryId = (id) => request({
  url: '/appInstitution/areaState/getAreaRegionByCountryId?fkCountryId='+id,
  method: 'post'
})

// 推荐信文件上传
export const uploadIssueStudentRefeApi = ({files, stuId, fkStudentReferenceId, fileNames}) => {
  const formData = new FormData()
  let i = 0;
  for(let file of files){
    formData.append('files',file, fileNames[i]) // file对象
    i++;
  }
  formData.append('stuId', stuId)
  formData.append('fkStudentReferenceId', fkStudentReferenceId)
  formData.append('type','ISSUE-CENTER')
  return request({
    url: `/appFile/uploadIssueStudentRefe`,
    data: formData,
    headers:{
      'content-type':'multipart/form-data'
    },
    method: 'post'
  })
}
// 教育背景文件上传
export const uploadIssueStudentEduApi = ({files, stuId, fkStudentEduId, typeKey, oldFkGuId}) => {
  const formData = new FormData()
  for(let file of files){
    formData.append('files',file) // file对象
  }
  formData.append('stuId', stuId)
  formData.append('typeKey', typeKey)
  formData.append('type', 'ISSUE-CENTER')
  if (fkStudentEduId) {
    formData.append('fkStudentEduId', fkStudentEduId)
  }
  if (oldFkGuId) {
    formData.append('oldFkGuId', oldFkGuId)
  }
  return request({
    url: `/appFile/uploadIssueStudentRefe`,
    data: formData,
    headers:{
      'content-type':'multipart/form-data'
    },
    method: 'post'
  })
}
export const updateRefeFileToCourseRelationApi = ({fkFileGuid, fkStudentInstitutionCourseId, fkStudentId})=> request({
  url: '/issue/studentReference/updateRefeFileToCourseRelation?fkFileGuid='+fkFileGuid,
  method: 'post',
  data: {
    fkStudentInstitutionCourseId,
    fkStudentId
  }
})

export const deleteStudentRefeFileApi = (id)=>request({
  url: '/issue/studentReference/deleteStudentRefeFile?id='+id,
  method: 'get',
})

// 修改推荐信名称
export const updateFileNameByIssueApi = ({fkGuId, newFileName}) => request({
  url: `/appFile/updateFileNameByIssue?fkGuId=${fkGuId}&newFileName=${newFileName}`,
  method: 'get'
})

export const getDeleteStudentEduFile = (fkMediaAndAttachedId) => request({
  url: `/issue/studentEducatio/getDeleteStudentEduFile?fkMediaAndAttachedId=${fkMediaAndAttachedId}`,
  method: 'get'
})

export const loginByPwd = (data) => request({
  url: '/appUser/loginByPs',
  method: 'post',
  data,
})

// 切换语言传递给后端
export const getSetLanguageCodeToCacheApi = (languageCode) => request({
  url: `/issue/student/getSetLanguageCodeToCache/?languageCode=${languageCode}`,
  method: 'get',
});
// 新架构登录接口
export const loginApi = (params) => request({
  url: '/authentication/oauth/token',
  method: 'post',
  params,
  // 定义对于给定的HTTP 响应状态码是 resolve 或 reject  promise 。
  // 如果 `validateStatus` 返回 `true` (或者设置为 `null` 或 `undefined`)，promise 将被 resolve; 否则，promise 将被 rejecte
  // validateStatus(status) {
  //   return status >= 200 && status < 500
  // }
});
// 新架构刷新token
export const refreshTokenApi = (refreshToken) => request({
  url: '/authentication/oauth/token',
  method: 'post',
  data: {
    grant_type: 'refresh_token',
    refresh_token: refreshToken,
  },
  transformRequest
});
export const transformRequest = [(data) => {
  let ret = '';
  for (const it in data) {
    if (it) {
      ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&';
    }
  }
  return ret;
}];

// 教育背景 根据学校筛国家--获取学校
export const getInstitutionDataToIssueEduApi = () => request({
  url: '/appInstitution/institution/getInstitutionDataToIssueEdu',
  method: 'get',
})
// 教育背景 根据学校筛国家--获取学校
export const getInstitutionDataToIssueEdu2Api = () => request({
  url: '/appInstitution/institution/getInstitutionDataToIssueEdu2',
  method: 'get',
})
export const enCvParseApi = (data) =>
  request({
    url: '/issue/student/getBackgroundInfoParse',
    method: 'post',
    data
});

// 通用remark更新接口
export const saveOrUpdateStudentRemarkApi = (id, remark) => request({
  url: '/issue/student/saveOrUpdateStudentRemark',
  method: 'post',
  data: { id, remark }
});
// 查看备注信息
export const getRegisterDataApi = (fkStudentId) =>
  request({
    url: `/issue/student/getRegisterData?fkStudentId=${fkStudentId}`,
    method: 'get',
  });
// 是否使用共享链接字段
export const getIsUseSharedPathApi = (bmsLoginId) =>
  request({
    url: '/issue/student/getIsUseSharedPath',
    method: 'get',
    params:{bmsLoginId},
  }
);