import StudentProfessionalQualification from "./src/main";
/* istanbul ignore next */
StudentProfessionalQualification.install = function (Vue) {
  Vue.component(
    StudentProfessionalQualification.name,
    StudentProfessionalQualification
  );
};

export default StudentProfessionalQualification;
