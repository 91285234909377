<template>
  <svg
    t="1625559752721"
    class="icon"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="3054"
  >
    <path
      d="M764.45 830.15a54.9 54.9 0 0 1-54.9 54.9H314.45a55.05 55.05 0 0 1-54.9-54.9V248h504.9zM369.2 149.6a11.1 11.1 0 0 1 11.25-11.25h263.7a11.1 11.1 0 0 1 11.25 11.25V182H369.2zM929 182H720.8V149.6a76.65 76.65 0 0 0-76.65-76.65H380.45a76.95 76.95 0 0 0-76.65 76.65V182H95a33.15 33.15 0 0 0 0 66.15h98.55v582a120.9 120.9 0 0 0 120.9 120.9h395.1a120.9 120.9 0 0 0 120.9-120.9V248h98.55a33 33 0 0 0 33-33 32.4 32.4 0 0 0-33-33zM512 785.9a33 33 0 0 0 33-33.15V401.3a33 33 0 1 0-66 0v351.45a33 33 0 0 0 33 33.15m-154.05 0a33.15 33.15 0 0 0 33.15-33.15V401.3a33.15 33.15 0 1 0-66.15 0v351.45a34.05 34.05 0 0 0 33 33.15m308.1 0a33.15 33.15 0 0 0 33-33.15V401.3a33.15 33.15 0 1 0-66.15 0v351.45a33.15 33.15 0 0 0 33.15 33.15"
      p-id="3055"
    ></path>
  </svg>
</template>
<script>
export default {
  name: "FileIcon",
  props: {
    fill: String,
  },
};
</script>
