import Vue from 'vue';
import './style.scss';
import { createWrapDom } from '@/utils/tool'

const createMessageBoxVm = (el) => new Vue({
  el: el,
  data: {
    cnt: '',
    tit: '',
    show: true,
    type: '',
    showConfirmBtn: true,
    showCancelBtn: true,
    confirmButtonText: 'Confirm',
    cancelButtonText: 'Cancel',
    resolve: null,
    reject: null,
    visible: false,
    callback: null,
    dangerouslyUseHTMLString: false,

    showInputErrorMessage: false,
    nptValue: '',
    inputErrorMessage: '',
    inputPattern: '',
  },
  methods: {
    showConfirm(cnt, tit, {type = 'question', confirmButtonText, cancelButtonText, distinguishCancelAndClose = true, callback}){
      this.cnt = cnt;
      this.tit = tit;
      this.type = type;
      this.confirmButtonText = confirmButtonText;
      this.cancelButtonText = cancelButtonText;
      if(distinguishCancelAndClose){
        this.showConfirmBtn = true;
        this.showCancelBtn = true;
      }
      this.callback = callback;
      setTimeout(()=>this.visible = true)
      this.modelType = 'confirm'
      return callback? null: new Promise((resolve, reject)=>{
        this.reject = reject;
        this.resolve = resolve;
      })
    },
    showAlert(cnt, tit, {confirmButtonText, dangerouslyUseHTMLString, callback}){
      this.cnt = cnt;
      this.tit = tit;
      this.type = 'warning'
      this.showConfirmBtn = true;
      this.showCancelBtn = false;
      this.confirmButtonText = confirmButtonText;
      this.dangerouslyUseHTMLString = dangerouslyUseHTMLString;
      this.callback = callback;
      this.modelType = 'alert'
      setTimeout(()=>this.visible = true)
      return callback ? null : new Promise((resolve, reject)=>{
        this.reject = reject;
        this.resolve = resolve;
      })
    },
    showPrompt(cnt, tit, {confirmButtonText, cancelButtonText, inputPattern, inputErrorMessage}){
      this.cnt = cnt;
      this.tit = tit;
      this.confirmButtonText = confirmButtonText;
      this.cancelButtonText = cancelButtonText;
      this.inputPattern = inputPattern;
      this.inputErrorMessage = inputErrorMessage;
      this.modelType = 'prompt'
      setTimeout(()=>this.visible = true)
      return new Promise((resolve, reject)=>{
        this.reject = reject;
        this.resolve = resolve;
      })
    },
    close(){
      this.reject && this.reject('cancel');
      this.finished('cancel')
    },
    confirm(){
      if(this.modelType == 'prompt'){
        if (!this.inputPattern.test(this.nptValue)){
          this.showInputErrorMessage = true;
          return;
        }
      }
      this.resolve && this.resolve(
        this.modelType == 'prompt'? 
          {value: this.nptValue, type: 'confirm'}: 
          'confirm'
      );
      this.finished('confirm')
    },
    finished(type){
      setTimeout(()=>{
        this.show = false;
        setTimeout(()=>this.$destroy())
      },300)
      this.visible = false;
      this.callback && this.callback(type)
    }
  },
  render(h){
    function getIcon(type){
      switch (type){
        case 'success': return 'el-icon-success';
        case 'warning': return 'el-icon-warning';
        case 'error': return 'el-icon-error';
        case 'info': return 'el-icon-info';
        case 'question': return 'el-icon-question';
      }
    }
    return this.show ? h('div', {
      class: ['owl-msg-box', this.visible && 'active'],
      attrs:{
        tabindex: -1,
        role: "dialog",
        'aria-modal': true,
        'aria-label': 'dialog'
      }
    }, [
      h('div', { class: 'msg-box' }, [
        h('div', {
          class: 'msg-header'
        },[
          h('div', {class: 'msg-box-tit'}, this.tit),
          h('button', {class: 'msg-cls-btn', on: {click: this.close}}, [h('i', {class: 'el-icon-close'})]),
        ]),
        this.dangerouslyUseHTMLString ? 
          h('div', {class: 'msg-box-cnt', domProps: { innerHTML: this.cnt }}):
          h('div', {class: 'msg-box-cnt'}, [
            this.type && h('i', {class: ['icon', getIcon(this.type)]}),
            h('span',this.cnt),
          ]),
        this.modelType == 'prompt'?
          h('div', {class: 'msg-box-cnt'}, [
            h('input', {
              class: 'npt',
              attrs: {type: 'text', value: this.nptValue}, 
              on: {input: (e)=>this.nptValue = e.target.value}
            }),
            h('div', {class: 'npt-tip', style: {visibility:this.showInputErrorMessage? 'visible' : 'hidden'}}, this.inputErrorMessage)
          ]): 
          null,
        h('div', {
          class: 'msg-box-btns'
        }, [
          this.showCancelBtn && h('button', {
            class: 'el-button el-button--small',
            on: {click: this.close},
          }, this.cancelButtonText),
          this.showConfirmBtn && h('button', {
            class: 'el-button el-button--primary el-button--small',
            on: {click: this.confirm}
          }, this.confirmButtonText)
        ])
      ])
    ]): null;
  }
})
export default {
  confirm: async (cnt, tit, {type, confirmButtonText, cancelButtonText, distinguishCancelAndClose = true, callback})=>{
    createWrapDom('owlMessageBox')
    const vm = createMessageBoxVm('#owlMessageBox')
    await vm.showConfirm(cnt, tit, {type, confirmButtonText, cancelButtonText, distinguishCancelAndClose, callback})
    
  },
  alert:async (cnt, tit, {confirmButtonText, dangerouslyUseHTMLString, callback}) => {
    createWrapDom('owlMessageBox')
    const vm = createMessageBoxVm('#owlMessageBox')
    await vm.showAlert(cnt, tit, {confirmButtonText, dangerouslyUseHTMLString, callback})
  },
  prompt:async (cnt, tit, {confirmButtonText, cancelButtonText, inputPattern, inputErrorMessage}) => {
    createWrapDom('owlMessageBox')
    const vm = createMessageBoxVm('#owlMessageBox')
    return await vm.showPrompt(cnt, tit, {confirmButtonText, cancelButtonText, inputPattern, inputErrorMessage})
  }
}