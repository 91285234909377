<template>
  <div class="nav-comp">
    <ul class="nav-comp-ul" ref="listWrap"  @click="handleClickLi">
      <template 
        v-for="(item, index) in navList"
      >
        <li
          v-if="activeList[index] && activeList[index].length"
          :data-name="'nav'+index"
          :key="index"
          :class="[value == 'nav'+index && 'active']"
        >{{item}}</li>
      </template>
    </ul>
    <el-button type="primary" @click="$emit('handle-apply-info')" v-if="!hideMainSubmitBtn">
      {{ t("stuManagement.commitApp") }}
    </el-button>
  </div>
</template>
<script>
import i18n from "@/locale/index";
export default {
  name: 'slider',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: String,
    showBackgroundInfo: Boolean,
    activeList: {
      default: ()=>{return new Array(6).fill(false)},
      require: false
    },
    navList: Array,
    hideMainSubmitBtn: Boolean
  },
  mixins: [i18n],
  data(){
    return {
    }
  },
  watch: {
    value(newVal){
      if(window.innerWidth > 750) return;
      const dom = this.$refs.listWrap.querySelector(`[data-name="${newVal}"]`);
      if(dom) {
        const left = 
          dom.offsetLeft -
          (this.$refs.listWrap.clientWidth - dom.clientWidth) / 2;
        this.$refs.listWrap.scrollTo({ left: left, behavior: "smooth" });
      }
    }
  },
  methods: {
    handleClickLi(e) {
      const dom = e.target;
      const index = dom.dataset.name || 'nav0';
      if (index === '') return;
      this.$emit("change", index);
    },
  }
}
</script>