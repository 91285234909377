<template>
  <router-view
    id="app"
    :student-id.sync="studentId"
    :public-level="publicLevel"
    :agent-id="agentId"
    :fk-platform-type="fkPlatformType"
    :lang="lang"
    :interceptors-request="interceptorsRequest"
    :at="at"
    :isBmsLogin="isBmsLogin"
    :companyId="companyId"
    :issueInnerAccount="issueInnerAccount"
  />
</template>

<script>
import { loginByPwd } from "@/api/common.js";
export default {
  data() {
    return {
      studentId: process.env.NODE_ENV == "development" ? 18523 : null,
      publicLevel: "5",
      agentId: 3,
      fkPlatformType: "get_issue",
      lang: "zh",
      interceptorsRequest: null,
      at: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJnZW5kZXIiOm51bGwsInVzZXJfbmFtZSI6ImFwcGx5XzJlZWY4ZV8zMDI5MCIsImZrQXJlYUNvdW50cnlJZE5hdGlvbmFsaXR5IjpudWxsLCJjb21wYW55QWNjb3VudFR5cGUiOm51bGwsIm1zb1VzZXJUeXBlIjpudWxsLCJjbGllbnRfaWQiOiJpc3N1ZUNlbnRlciIsImlzX2FkbWluIjoiZmFsc2UiLCJvc3NGaWxlc1VybCI6bnVsbCwic2NvcGUiOlsiYWxsIl0sInN0YWZmX2lkIjoiMjAyODUiLCJ1c2VySGVhZEljb24iOm51bGwsIm9hdXRoX2lkIjoiIiwiZmtfY29tcGFueV9pZCI6IjIiLCJleHAiOjE3MzcwMDE5OTMsImp0aSI6IjY3MWZiZjkxLTU1NTEtNGU0MC05MjU5LWU2NmI3ZjMxMGY1YyIsImxvZ2luX2lkIjoiYXBwbHlfMmVlZjhlXzMwMjkwIiwib3NzSW1hZ2VzVXJsIjpudWxsLCJpc19hY3RpdmUiOiJ0cnVlIiwib3BlbmlkIjpudWxsLCJzZXNzaW9uX2lkIjoiMjQ1MDQxYTA3MTM0NDg1NmI4NDVjNjdiYzE5ZThhODciLCJpc3N1ZVNvdXJjZSI6IkdFQSIsImF1dGhvcml0aWVzIjpbIjIwMjg1Il0sIndlY2hhdF9vcGVuaWQiOm51bGwsImxpY2Vuc2UiOiJHRVQiLCJsb2dpbl90aW1lIjoxNzI2MjAxOTkzNjA2LCJuYW1lIjoi77yI5YaF6YOo55Sz6K-36LSm5Y-377yJ5LyN5YCp5oCh5rWL6K-VIiwiZmtfcGxhdGZvcm1fdHlwZSI6ImdldF9pc3N1ZSJ9.tYSlhHMQ2bA7L6RGOjujO_fDZIZ8Q6VTdx2J8bTRVHE",
      authorization: "",
      loginPs: "",
      loginId: "",
      isAndroid: true,
      isBmsLogin: true,
      companyId: 2,
      issueInnerAccount: true,
    };
  },
  created() {
    window.RNLogin = (data)=>{
      this.receiveMessage(data)
    }
  },
  methods: {
    async receiveMessage(data) {
      let {
        studentId,
        publicLevel,
        agentId,
        fkPlatformType,
        lang,
        loginPs,
        loginId,
      } = data;
      if (data.type !== "msoApp") return;
      lang = lang || "zh";
      fkPlatformType = fkPlatformType || "get_mso";
      try {
        const ret = await loginByPwd({
          loginId,
          loginPs,
          fkPlatformType: "get_mso",
        });
        if (ret.data.code !== "1003") {
          this.$msge.error('login fail')
          window.ReactNativeWebView &&
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                type: "error",
                message: "login fail",
              })
            );
          return;
        }
        this.$msge.success('login success')
        this.studentId = studentId;
        this.publicLevel = publicLevel;
        this.agentId = agentId;
        this.fkPlatformType = fkPlatformType;
        this.lang = lang;
        window.ReactNativeWebView &&
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              type: "connected",
              studentId: studentId,
            })
          );
      } catch {
        this.$msge.error('login fail')
        window.ReactNativeWebView &&
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              type: "error",
              message: "login fail",
            })
          );
      }
    },
  },
};

</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  
}
body{
  background-color: #f1f1f1;
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
@media screen and(max-width: 768px) {
  .stu-all-form-info {
    .forms-box {
      padding: 0 12px;
    }
  }
}
</style>